export default {
  REQUEST_DEALS: (state) => {
    state.status = "fetching";
  },
  SUCCESS_REQUEST_DEALS: (state, value) => {
    state.status = "successs";
    state.items = value;
  },
  FAILURE_REQUEST_DEALS: (state) => {
    state.status = "failure";
    state.items = [];
  },
};
