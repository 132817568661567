export default () => ({
  navigation: [
    { title: "profile", icon: "profile.svg", userRequired: true },
    { title: "activedeals", icon: "deals.svg" },
    { title: "favorites", icon: "favorites.svg", userRequired: true },
    {
      title: "rating",
      icon: "feedback.svg",
      params: { detailId: 0 },
      userRequired: true,
    },
    { title: "settings", icon: "settings.svg" },
    { title: "info", icon: "info.svg" },
    { title: "privacyterms", icon: "privacy.svg" },
  ],
  myPoco: [
    {
      name: "preferences",
      icon: "mypreferences.svg",
    },
    {
      name: "tickets",
      icon: "mytickets.svg",
    },
    {
      name: "bookings",
      icon: "mybookings.svg",
    },
    {
      name: "favorites",
      icon: "favorites.svg",
    },
    {
      name: "activityhistory",
      icon: "myactivities.svg",
    },
  ],
  widgetnavigation: [
    { name: "bookings", icon: "mybookings.svg" },
    { name: "tickets", icon: "mytickets.svg" },
    { name: "info", icon: "info.svg" },
    { name: "privacyterms", icon: "privacy.svg" },
  ],
});
