var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.name === 'home')?_c('div',[(!_vm.isMiKY)?_c('v-app-bar',{staticStyle:{"z-index":"99 !important","height":"auto","padding-top":"env(safe-area-inset-top)"},attrs:{"app":"","height":"64px","clipped-left":"","elevation":"0"}},[(!_vm.$vuetify.breakpoint.lgAndUp)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$store.commit('SET_MENU', true)}}},[_c('img',{attrs:{"src":require('@/assets/navigation/burger_icon.png'),"width":"30","height":"30"}})]):_vm._e(),_c('v-spacer'),_c('router-link',{staticStyle:{"pointer-events":"none"},attrs:{"to":"/"}},[_c('img',{staticStyle:{"background-size":"contain !important"},style:(_vm.$store.state.settings.dark
            ? 'margin-left: -30px; margin-top: 10px;'
            : 'margin-top: 10px;'),attrs:{"width":"90","height":"52","src":_vm.$store.state.settings.dark
            ? require('@/assets/img/poco-01-2.svg')
            : require('@/assets/img/poco-01-2.svg')}})]),_c('v-spacer'),_c('profile-picture')],1):_vm._e(),(
      _vm.isMiKY &&
      _vm.$store.state.partners &&
      _vm.$store.state.partners.config &&
      _vm.$store.state.partners.config.design_options &&
      _vm.$store.state.partners.config.design_options.show_logo_title
    )?_c('v-app-bar',{staticStyle:{"z-index":"1","padding-top":"env(safe-area-inset-top)","background":"none"},attrs:{"height":"64px","clipped-left":"","elevation":"0"}},[_c('v-spacer'),_c('div',{staticClass:"HeadingDarkH3-22Center pt-5",staticStyle:{"max-width":"calc(100% - 150px)"},style:(_vm.isMiKY ? 'color: var(--v-onprimary-base) !important;' : '')},[_vm._v(" "+_vm._s(_vm.$store.state.partners.config.design_options.location_title)+" ")]),_c('v-spacer'),(_vm.$store.state.partners.config.design_options.logo)?_c('v-img',{staticClass:"d-block mx-auto mt-0",staticStyle:{"position":"absolute","right":"8px","top":"8px"},attrs:{"src":_vm.$store.state.partners.config.design_options.logo.url,"width":"64px","height":"64px","contain":""}}):_vm._e()],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }