<template>
  <v-dialog v-model="showAd" fullscreen transition="dialog-bottom-transition">
    <v-card v-if="currentAd">
      <v-toolbar class="transparent elevation-0">
        <v-toolbar-title>{{ currentAd.title }}</v-toolbar-title
        ><v-spacer></v-spacer>
        <v-btn icon @click="dismissAd()">
          <v-icon v-if="dismissable">mdi-close</v-icon>
          <v-avatar size="36" v-else> {{ countdown }}</v-avatar>
        </v-btn>
      </v-toolbar>
      <v-img
        :style="`position: absolute; width: 100%; height: ${imageHeight}px;`"
        :src="currentAd.imagePathw400"
        :lazy-src="currentAd.imagePathw400"
      >
        <v-overlay
          absolute
          :opacity="currentAd.opacity"
          class="white--text align-end"
        >
          <v-card-text>{{ currentAd.text }}</v-card-text>
          <v-card-actions class="mb-0">
            <v-btn height="56" block color="primary" @click="goToPage()">{{
              currentAd.buttonText
            }}</v-btn>
          </v-card-actions>
        </v-overlay>
      </v-img>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      dismissable: false,
      countInterval: void 0,
      countdown: 5,
    };
  },
  computed: {
    ...mapState("advertisements", {
      currentAd: (state) => state.currentAd,
    }),
    showAd: {
      get() {
        return this.$store.state.advertisements.showDialog;
      },
      set(val) {
        this.$store.commit("advertisements/SET_DIALOG", val);
      },
    },
    imageHeight() {
      return this.$vuetify.breakpoint.height - 56;
    },
  },
  methods: {
    goToPage() {
      this.countdown = 5;
      this.showAd = false;
      clearInterval(this.countInterval);
      this.$store.commit("advertisements/SET_ALREADY_SHOWN", true);
      firebase.analytics().logEvent("ad_clicked", {
        ad_id: this.currentAd.id,
      });
      let link = this.currentAd.webpage;
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
    },
    dismissAd() {
      this.countdown = 5;
      this.showAd = false;
      clearInterval(this.countInterval);
      this.$store.commit("advertisements/SET_ALREADY_SHOWN", true);
      firebase.analytics().logEvent("ad_dismissed", {
        ad_id: this.currentAd.id,
      });
    },
  },
  watch: {
    "$store.state.advertisements.showDialog"(val) {
      if (val === true) {
        this.countInterval = setInterval(() => {
          this.countdown--;
          if (this.countdown < 1) {
            this.dismissable = true;
            clearInterval(this.countInterval);
          }
        }, 1000);
      } else {
        this.dismissable = false;
      }
    },
  },
};
</script>

<style lang="scss">
.v-card__actions {
  margin-bottom: constant(
    safe-area-inset-bottom
  ); /* move footer up on iPhone X - iOS 11.0 */
  margin-bottom: env(
    safe-area-inset-bottom
  ); /* move footer up on iPhone X - iOS 11.2 */
}
</style>
