export default {
  FETCH_CSV: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_CSV: (state, value) => {
    state.status = "success";
    state.file = value;
  },
  FAILURE_FETCH_CSV: (state, error) => {
    state.status = "failure";
    state.error = error;
  },
};
