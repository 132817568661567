//this is used for loading images on ios native build
import "intersection-observer";
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "@/i18n";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import firebase from "firebase/app";
import "firebase/auth";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

import VueSimpleSVG from "vue-simple-svg";
Vue.use(VueSimpleSVG);

import "vue-smooth-picker/dist/css/style.css";
import SmoothPicker from "vue-smooth-picker";
Vue.use(SmoothPicker);

import lineClamp from "vue-line-clamp";
Vue.use(lineClamp);

Vue.config.productionTip = false;

let firebaseConfig = {};

switch (process.env.NODE_ENV) {
  case "demo":
    firebaseConfig = {
      apiKey: "AIzaSyAG2IImFKYQXfzzGfXVeL24eEuC0iwcCJ4",
      authDomain: "fir-demo-wow.firebaseapp.com",
      databaseURL: "https://fir-demo-wow.firebaseio.com",
      projectId: "firebase-demo-wow",
      storageBucket: "firebase-demo-wow.appspot.com",
      messagingSenderId: "83013032751",
      appId: "1:83013032751:web:0cb2dd1a269b74237e9fda",
    };
    break;
  /*
  case "staging":
    firebaseConfig = {
      apiKey: "AIzaSyBWpVKz54s9vvh3TzA5O4HSSQtOQnWwlvw",
      authDomain: "fir-hosting-wow.firebaseapp.com",
      databaseURL: "https://fir-hosting-wow.firebaseio.com",
      projectId: "firebase-hosting-wow",
      storageBucket: "firebase-hosting-wow.appspot.com",
      messagingSenderId: "344775623103",
      appId: "1:344775623103:web:4ce6adf529a806ac16a10a",
    };
    break;
  */
  default:
    firebaseConfig = {
      apiKey: "AIzaSyCGmW4valNqEUFe8dZ9wno7tZFAwWgnzew",
      authDomain: "wowapp-b59fa.firebaseapp.com",
      databaseURL: "https://wowapp-b59fa.firebaseio.com",
      projectId: "wowapp-b59fa",
      storageBucket: "wowapp-b59fa.appspot.com",
      messagingSenderId: "541781514170",
      appId: "1:541781514170:web:4674b63a016aff5c776f83",
      measurementId: "G-DE2RG9D0D4",
    };
    break;
}

firebase.initializeApp(firebaseConfig);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: "AIzaSyDg1Dztn_OVruFwNPvUPWG2p3OGBBVRp2M",
  libraries: "places",
  v: 3.38,
});
/*
import PullToRefresh from "pulltorefreshjs";
// assure scrolling in the overlay is working when notification feed is open
if (!store.state.notificationOverlay || router.currentRoute.name !== "map") {
  const ptr = PullToRefresh.init({
    mainElement: "body",
    onRefresh() {
      if (
        router.currentRoute.name === "home" &&
        store.state.dashboard.openAdresspicker === false
      ) {
        window.location.reload();
      }
    },
  });
}
*/
import VueGlide from "vue-glide-js";
import "vue-glide-js/dist/vue-glide.css";
Vue.use(VueGlide);

import VCalendar from "v-calendar";
Vue.use(VCalendar);

/*
const onDeviceReady = () => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
};*/

document.addEventListener("deviceready", onDeviceReady, false);
function onDeviceReady() {
  window.open = cordova.InAppBrowser.open;
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
