import remove from "lodash/remove";
import find from "lodash/find";
import filter from "lodash/filter";
export default {
  SUCCESS_USER_EXISTS: (state) => {
    state.userExists = true;
  },
  FAILURE_USER_EXISTS: (state) => {
    state.userExists = false;
  },
  REQUEST_PROFILE: (state) => {
    state.profileStatus = "fetching";
  },
  SUCCESS_REQUEST_PROFILE: (state, value) => {
    state.profileStatus = "success";
    state.data = value;
  },
  FAILURE_REQUEST_PROFILE: (state) => {
    state.profileStatus = "failure";
  },
  REQUEST_UPDATE_PROFILE: (state) => {
    state.updateStatus = "fetching";
  },
  SUCCESS_UPDATE_PROFILE: (state, value) => {
    state.updateStatus = "success";
    state.updateProfile = value;
  },
  FAILURE_UPDATE_PROFILE: (state) => {
    state.updateStatus = "failure";
  },
  SET_PROFILE_PICTURE: (state, value) => {
    state.data.profilePicture = value;
  },
  SET_PROFILE_BACKGROUND: (state, value) => {
    state.data.profileBackground = value;
  },
  REQUEST_DELETE_QUESTION: (state) => {
    state.questionStatus = "fetching";
  },
  SUCCESS_DELETE_QUESTION: (state) => {
    state.questionStatus = "success";
  },
  FAILURE_DELETE_QUESTION: (state) => {
    state.questionStatus = "failure";
  },
  REQUEST_UPDATE_QUESTION: (state) => {
    state.questionStatus = "fetching";
  },
  SUCCESS_UPDATE_QUESTION: (state) => {
    state.questionStatus = "success";
  },
  FAILURE_UPDATE_QUESTION: (state) => {
    state.questionStatus = "failure";
  },
  SHOW_INTEREST_PRESELECTION: (state, value) => {
    state.showInterestPreselection = value;
  },
  SET_POCO_RATING: (state, value) => {
    state.data.rating = value.rating;
    state.data.comment = value.comment;
  },
  SET_LIKES: (state, value) => {
    state.data.likes = value;
  },
  SET_DISLIKES: (state, value) => {
    state.data.dislikes = value;
  },
  SET_DIALOG: (state, value) => {
    state.dialog = value;
  },
  ADD_TICKET: (state, value) => {
    if (!state.data.tickets) {
      state.data.tickets = [];
    }
    state.data.tickets.push(value);
  },
  REMOVE_TICKET: (state, value) => {
    remove(state.data.tickets, { id: value });
  },
  ADD_RESERVATION: (state, value) => {
    if (!state.data.bookings) {
      state.data.bookings = [];
    }
    state.data.bookings.push(value);
  },
  // Remove it without adding it to history. (Modify booking)
  REMOVE_RESERVATION: (state, value) => {
    remove(state.data.bookings, { visitId: value });
  },
  CANCEL_RESERVATION: (state, value) => {
    if (!state.data.history) {
      state.data.history = [];
    }
    state.data.history.push(find(state.data.bookings, { visitId: value }));
    find(state.data.bookings, { visitId: value })["cancelled"] = true;
    remove(state.data.bookings, { visitId: value });
  },
  REMOVE_ACTIVITY_HISTORY_ITEM: (state, value) => {
    state.data.history = filter(state.data.history, (o) => {
      return o.visitId !== value;
    });
  },
  MOVE_TO_ACTIVITY_HISTORY: (state, value) => {
    state.data.history.push(value);
    remove(state.data.bookings, { visitId: value.visitId });
  },
};
