<template>
  <div v-if="$route.name === 'home'">
    <v-app-bar
      v-if="!isMiKY"
      app
      height="64px"
      clipped-left
      style="
        z-index: 99 !important;
        height: auto;
        padding-top: env(safe-area-inset-top);
      "
      elevation="0"
    >
      <v-btn
        v-if="!$vuetify.breakpoint.lgAndUp"
        @click="$store.commit('SET_MENU', true)"
        icon
      >
        <img
          :src="require('@/assets/navigation/burger_icon.png')"
          width="30"
          height="30"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <router-link to="/" style="pointer-events: none">
        <img
          :style="
            $store.state.settings.dark
              ? 'margin-left: -30px; margin-top: 10px;'
              : 'margin-top: 10px;'
          "
          width="90"
          style="background-size: contain !important"
          height="52"
          :src="
            $store.state.settings.dark
              ? require('@/assets/img/poco-01-2.svg')
              : require('@/assets/img/poco-01-2.svg')
          "
        />
      </router-link>
      <v-spacer></v-spacer>
      <profile-picture></profile-picture>
    </v-app-bar>
    <v-app-bar
      v-if="
        isMiKY &&
        $store.state.partners &&
        $store.state.partners.config &&
        $store.state.partners.config.design_options &&
        $store.state.partners.config.design_options.show_logo_title
      "
      height="64px"
      clipped-left
      style="
        z-index: 1
        height: auto;
        padding-top: env(safe-area-inset-top);
        background: none;
      "
      elevation="0"
    >
      <v-spacer></v-spacer>
      <div
        class="HeadingDarkH3-22Center pt-5"
        style="max-width: calc(100% - 150px)"
        :style="isMiKY ? 'color: var(--v-onprimary-base) !important;' : ''"
      >
        {{ $store.state.partners.config.design_options.location_title }}
      </div>
      <v-spacer></v-spacer>
      <v-img
        v-if="$store.state.partners.config.design_options.logo"
        :src="$store.state.partners.config.design_options.logo.url"
        width="64px"
        height="64px"
        style="position: absolute; right: 8px; top: 8px"
        class="d-block mx-auto mt-0"
        contain
      ></v-img>
    </v-app-bar>
  </div>
</template>

<script>
import ProfilePicture from "@/components/dashboard/ProfilePicture";

export default {
  name: "MainAppBar",
  data() {
    return {};
  },
  components: {
    ProfilePicture,
  },
  computed: {
    isMiKY() {
      return this.$store.state.isMiKY;
    },
  },
};
</script>
<style>
.v-btn::before {
  opacity: 0;
}
</style>
