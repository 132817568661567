import firebase from "firebase/app";
import "firebase/auth";
import router from "../../../router";
import geocoding from "reverse-geocoding";
import api from "@/api";
//api key for google maps geocoding
const options = { key: "AIzaSyDg1Dztn_OVruFwNPvUPWG2p3OGBBVRp2M" };

export default {
  createUser({ commit, dispatch }, user) {
    commit("REQUEST_CREATE_USER");
    return firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(async (newUser) => {
        dispatch("sendVerificationEmail");
        // Creating a user automatically signs in the new user.
        // This will trigger onAuthStateChanged() which calls getProfile().
        // getProfile() will automatically create a poco user,
        // if there is no poco user yet.
        commit("SUCCESS_REQUEST_CREATE_USER", {
          ...newUser.user,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
        });
      })
      .catch((error) => {
        // error.code = 'auth/email-already-in-use'
        console.error("create user failed with error code:", error.code);
        commit("FAILURE_REQUEST_CREATE_USER", error.message);
      });
  },
  reloadUser({ commit }) {
    firebase
      .auth()
      .currentUser.reload()
      .then(() => {
        commit("SET_USER", firebase.auth().currentUser);
      });
  },
  sendResetEmail({ commit }, email) {
    let auth = firebase.auth();
    commit("REQUEST_RESET");
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        commit("SUCCESS_REQUEST_RESET");
      })
      .catch((error) => {
        console.error(error);
        commit("FAILURE_REQUEST_RESET");
      });
  },
  sendVerificationEmail({ commit }) {
    commit("REQUEST_VERIFICATION");
    let user = firebase.auth().currentUser;
    user
      .sendEmailVerification()
      .then(() => {
        commit("SUCCESS_REQUEST_VERIFICATION");
      })
      .catch((error) => {
        console.error(error);
        commit("FAILURE_REQUEST_VERIFICATION");
      });
  },
  signIn({ commit }, { user, reload }) {
    commit("REQUEST_SIGN_IN", reload);
    return firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .catch((error) => {
        // error.code = auth/user-not-found || error.code = auth/wrong-password
        console.error("sign in failed with error code:", error.code);
        commit("FAILURE_REQUEST_SIGN_IN", error.message);
      });
  },
  changePassword({}, { newPassword }) {
    return firebase
      .auth()
      .currentUser.updatePassword(newPassword)
      .then(() => {
        // Successfully updated password
      });
  },
  firebaseLogin({ commit, dispatch, state }) {
    commit("REQUEST_SIGN_IN", false);
    firebase
      .auth()
      .signInWithRedirect(state.provider)
      /* DEAD CODE ...
      .then(() => {
        return firebase.auth().getRedirectResult();
      })
      .then((result) => {
        dispatch("firebaseRedirectResult");
      })
      */
      .catch((error) => {
        // Do not show this error.
        if (error.code === "auth/redirect-cancelled-by-user") {
          window.location.reload();
          return;
        }
        console.error(
          "firebase login failed with error:",
          error.code,
          error.message
        );
        commit("FAILURE_REQUEST_SIGN_IN", error.message);
      });
  },
  firebaseRedirectResult() {
    firebase
      .auth()
      .getRedirectResult()
      .then(function (result) {
        return result;
      })
      .catch(function (error) {
        console.error(error);
      });
  },
  googleLogin({ commit, dispatch }) {
    commit("SET_PROVIDER", new firebase.auth.GoogleAuthProvider());
    dispatch("firebaseLogin");
  },
  facebookLogin({ commit, dispatch }) {
    commit("SET_PROVIDER", new firebase.auth.FacebookAuthProvider());
    dispatch("firebaseLogin");
  },
  appleLogin({ commit, dispatch }) {
    commit("SET_PROVIDER", new firebase.auth.OAuthProvider("apple.com"));
    dispatch("firebaseLogin");
  },
  async initApp({ commit, dispatch, state, rootState }, query) {
    await dispatch("categories/getCategories", {}, { root: true });
    await dispatch("firebaseRedirectResult");
    commit("SET_SESSION_STATE", "init");
    await dispatch("checkAppVersion");
    var { latitude, longitude, partnerId } = query;

    // Loading and saving the partner id in session storage
    if (!partnerId) {
      partnerId = window.sessionStorage?.getItem("partnerId");
    } else {
      window.sessionStorage?.setItem("partnerId", partnerId);
    }

    commit(
      "languages/SET_CURRENT_LANG",
      rootState.languages.currentLang
        ? rootState.languages.currentLang.id
        : window.localStorage?.getItem("lang-id")
        ? window.localStorage?.getItem("lang-id")
        : "en-US",
      { root: true }
    );

    if (!partnerId) {
      /* Update current location constantly */
      navigator.geolocation.watchPosition(
        (position) => {
          const lat = latitude || position.coords.latitude;
          const lng = longitude || position.coords.longitude;
          dispatch("setOriginalPosition", { lat, lng });
        },
        () => {
          // if getting position failed
          if (state.status != "initialized") {
            commit("SET_LOCATION_ERROR", true);
          }
          commit("SET_SESSION_STATE", "initialized");
        },
        { timeout: 5000 }
      );

      //dispatch("tagskeywords/getTagsKeywords", {}, { root: true });
      commit(
        "settings/SET_DARK",
        window.localStorage?.getItem("dark") || false,
        {
          root: true,
        }
      );

      commit(
        "settings/SET_NOTIFICATIONS",
        window.localStorage?.getItem("notifications") || false,
        {
          root: true,
        }
      );

      dispatch("tickets/getItems", {}, { root: true });
    } else {
      commit("SET_IS_MIKY", true, { root: true });
      dispatch("partners/getPartnerConfig", { partnerId }, { root: true });
    }

    firebase.auth().onAuthStateChanged(async (user) => {
      // Save a flag that the user is logged in.
      window?.localStorage?.setItem("user", !!user);

      if (user) {
        // Extract first and last name from the display name.
        // This applies to appleID and facebook users.
        // displayName: "<firstname><whitespace><lastname>"
        if (user.displayName && !user.firstName && !user.lastName) {
          user.firstName = user.displayName.split(" ")[0];
          user.lastName = user.displayName.split(" ")[1];
        } else if (
          user.providerData &&
          user.providerData[0].displayName &&
          !user.firstName &&
          !user.lastName
        ) {
          user.firstname = user.providerData[0].displayName.split(" ")[0];
          user.lastName = user.providerData[0].displayName.split(" ")[1];
        }

        if (rootState.isMiKY) {
          await dispatch(
            "partners/savePartnerForUser",
            {
              userId: user.uid,
              partnerId: partnerId,
            },
            { root: true }
          );
        }

        await dispatch(
          "profile/getProfile",
          {
            userId: user.uid,
            userData: user,
            firstName: user.firstName
              ? user.firstName
              : rootState.session.user
              ? rootState.session.user.firstName
              : "",
            lastName: user.lastName
              ? user.lastName
              : rootState.session.user
              ? rootState.session.user.lastName
              : "",
            // Only when signing up before booking in iFrame:
            phone:
              rootState.session.user && rootState.session.user.phone
                ? rootState.session.user.phone
                : "",
          },
          { root: true }
        );
        commit("SET_USER", user);

        if (
          rootState.profile.data.introduced !== true &&
          router.currentRoute.name === "home" &&
          !rootState.isMiKY
        ) {
          commit("SET_SHOW_INTRODUCTION", true);
        }

        //after redirect from oauth service, we want to push home route
        if (
          router.currentRoute.name === "register" ||
          router.currentRoute.name === "loginEmail" ||
          router.currentRoute.name === "login"
        ) {
          if (rootState.isMiKY) {
            router.back();
          } else {
            router.safePush({ name: "home" });
          }
        }
      }

      if (rootState.firstVisit && rootState.isMiKY) {
        dispatch("recommendations/logPartnerView", {}, { root: true });
      }
      commit("SET_FIRST_VISIT", false, { root: true });

      commit("SUCCESS_REQUEST_SIGN_IN");
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (state.refreshing) {
          return;
        }
        commit("SET_REFRESHING", true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    }
  },
  async initHomeScreen({ dispatch }) {
    dispatch(
      "recommendations/getDashboardRecommendationsV4",
      {},
      { root: true }
    );
    dispatch(
      "recommendations/getByTagV4",
      { tags: ["go local"] },
      { root: true }
    );
    dispatch(
      "recommendations/getByTagV4",
      { tags: ["fine dining"] },
      { root: true }
    );
    dispatch(
      "recommendations/getByTagV4",
      { tags: ["hidden gems", "hidden gem"] },
      { root: true }
    );
    dispatch(
      "recommendations/getByTagV4",
      { tags: ["specialty coffee"] },
      { root: true }
    );
    dispatch(
      "recommendations/getByTagV4",
      { tags: ["shisha lounges"] },
      { root: true }
    );
    dispatch(
      "recommendations/getByTagV4",
      { tags: ["brunches"] },
      { root: true }
    );
    dispatch(
      "recommendations/getByTagV4",
      { tags: ["curated experiences", "curated experience"] },
      { root: true }
    );
  },
  signout({ commit }) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Setting it to 'signed out' before reload, so the app goes into 'loading' mode.
        commit("SET_USER", "signed out");
        commit("profile/SUCCESS_REQUEST_PROFILE", void 0, { root: true });
        if (router.currentRoute.name !== "home") {
          router.safePush({ name: "home" });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async languageChange({ dispatch, commit }, { lang }) {
    commit("SET_SESSION_STATE", "init");
    dispatch(
      "recommendations/getRecommendationsV2",
      { lang, all: false },
      { root: true }
    );

    commit("SET_SESSION_STATE", "initialized");
  },
  deleteAccount({ commit }) {
    commit("DELETE_ACCOUNT");
    let user = firebase.auth().currentUser;
    user
      .delete()
      .then(() => {
        commit("SUCCESS_DELETE_ACCOUNT");
        router.safePush({ name: "home" });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        console.error(error);
        commit("FAILURE_DELETE_ACCOUNT");
      });
  },
  async checkAppVersion({ commit, state }) {
    try {
      let version = await api.getAppStoreVersion();
      commit("SET_APP_VERSION", version.results[0].version);
      if (state.packageVersion < state.appVersion) {
        commit("SET_REFRESH_BAR", true, { root: true });
      }
    } catch (e) {
      console.error(e);
    }
  },
  async resetPositionToOriginalPosition({
    commit,
    dispatch,
    state,
    rootState,
  }) {
    commit("recommendations/SET_MANUAL_LOCATION", false, { root: true });
    commit("REQUEST_CURRENT_CITY");
    commit("SUCCESS_CURRENT_CITY", rootState.map.originalPosition.currentCity);
    commit("map/SET_USER_POSITION", rootState.map.originalPosition, {
      root: true,
    });
    commit("SET_SESSION_STATE", "init");
    dispatch("initHomeScreen");
    setTimeout(() => {
      commit("SET_SESSION_STATE", "initialized");
    }, 300);
  },
  async setOriginalPosition(
    { commit, dispatch, state, rootState },
    { lat, lng }
  ) {
    let currentCity;
    commit("REQUEST_CURRENT_CITY");
    geocoding(
      Object.assign({}, options, {
        latitude: lat,
        longitude: lng,
        language: "en",
      }),
      (error, data) => {
        if (data) {
          data.results.forEach(function (element) {
            element.address_components.forEach(function (element2) {
              element2.types.forEach(function (element3) {
                switch (element3) {
                  case "locality":
                    currentCity = element2.long_name;
                    break;
                }
              });
            });
          });
          // Re-initialize home, if the city changed
          if (currentCity && state.currentCity != currentCity) {
            // Keep the original position updated, but ...
            commit(
              "map/SET_ORIGINAL_POSITION",
              { currentCity, lat, lng },
              { root: true }
            );
            // don't override the manually set position.
            if (!rootState.recommendations.manualLocationChanged) {
              commit("SUCCESS_CURRENT_CITY", currentCity);
              commit(
                "map/SET_USER_POSITION",
                { currentCity, lat, lng },
                { root: true }
              );
              commit("SET_SESSION_STATE", "init");
              dispatch("initHomeScreen");
              setTimeout(() => {
                commit("SET_SESSION_STATE", "initialized");
              }, 300);
            }
          } else {
            commit("SUCCESS_CURRENT_CITY", state.currentCity);
          }
        } else {
          console.error(error);
          commit("FAILURE_CURRENT_CITY");
        }
      }
    );
  },
  async setUserPosition({ commit, dispatch, state }, { lat, lng }) {
    let currentCity;
    commit("REQUEST_CURRENT_CITY");
    geocoding(
      Object.assign({}, options, {
        latitude: lat,
        longitude: lng,
        language: "en",
      }),
      (error, data) => {
        if (data) {
          data.results.forEach(function (element) {
            element.address_components.forEach(function (element2) {
              element2.types.forEach(function (element3) {
                switch (element3) {
                  case "locality":
                    currentCity = element2.long_name;
                    break;
                }
              });
            });
          });
          // Re-initialize home, if the city changed
          if (currentCity && state.currentCity != currentCity) {
            commit("SUCCESS_CURRENT_CITY", currentCity);
            commit(
              "map/SET_USER_POSITION",
              { currentCity, lat, lng },
              { root: true }
            );
            if (router.currentRoute.name === "home") {
              commit("SET_SESSION_STATE", "init");
              dispatch("initHomeScreen");
              setTimeout(() => {
                commit("SET_SESSION_STATE", "initialized");
              }, 300);
            }
          } else {
            commit("SUCCESS_CURRENT_CITY", state.currentCity);
          }
        } else {
          console.error(error);
          commit("FAILURE_CURRENT_CITY");
        }
      }
    );
  },
};
