<template>
  <v-container class="pa-0 text-center">
    <v-toolbar
      v-if="errorDialog"
      dense
      flat
      class="login mt-2"
      style="background: transparent"
    >
      <v-spacer></v-spacer>
      <v-btn x-large icon @click="$router.back()" color="onbackground"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </v-toolbar>
    <v-img
      :height="height"
      contain
      :src="require(`@/assets/notfound/${imageName}`)"
    ></v-img>
    <v-card-text class="HeadingDarkH2-28Center pb-0" v-if="title">{{
      title
    }}</v-card-text>
    <v-card-text class="HeadingDarkH3-22Center" v-if="text">{{
      text
    }}</v-card-text>
    <!-- DEPRICATED (not in use) TODO: REMOVE
    <v-card-actions v-if="noLocationItemsFound">
      <v-btn
        class="Heading-Color-H4-18-Center d-block mx-auto"
        text
        style="color: #01a2e9"
      >
        <v-img
          style="align-self: start"
          :src="require('@/assets/search/addLocation.svg')"
          width="24"
          height="24"
          class="mr-1"
        ></v-img>
        {{ $t("search.addNewLocation") }}
      </v-btn>
    </v-card-actions>
    -->
  </v-container>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    imageName: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "450",
    },
    errorDialog: {
      type: Boolean,
      default: false,
    },
    // TODO: REMOVE THIS DEPRICATED CODE
    noLocationItemsFound: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
