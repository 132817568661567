<template>
  <v-snackbar v-model="snackbar" :timeout="-1">
    <v-row dense justify="center"> {{ $t("navigation.downloadApp") }}</v-row>
    <v-row dense justify="center">
      <v-btn text color="primary" @click="redirectApple()"
        ><v-icon>mdi-apple</v-icon></v-btn
      >
      <v-btn text color="primary" @click="redirectAndroid()"
        ><v-icon>mdi-android</v-icon></v-btn
      >
      <v-btn icon @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  data: () => {
    return {
      snackbar: true,
    };
  },
  methods: {
    redirectAndroid() {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.pocoapp.poco";
    },
    redirectApple() {
      window.location.href =
        "https://apps.apple.com/us/app/wow-world-of-wonders/id1489483538";
    },
  },
};
</script>
