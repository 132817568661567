export default () => ({
  items: [],
  status: "",
  filter: "",
  categoryList: [],
  catFilter: "",
  moreStatus: "",
  lastHash: undefined,
  radius: 20,
  capacityReached: false,
  selectedCategory: {},
  selectedMood: {},
  recommendations: [],
  highlights: [],
  justForYou: [],
  popular: [],
  recommendationsStatus: "",
  highlightsStatus: "",
  justForYouStatus: "",
  popularStatus: "",
  details: {},
  detailStatus: "",
  allStatus: "",
  allItems: [],
  updateStatus: "",
  byCategoryStatus: "",
  byCategoryMoreStatus: "",
  byCategory: [],
  byMood: [],
  byMoodStatus: "",
  byMoodMoreStatus: "",
  reviewStatus: "",
  subCategories: [],
  manualLocationChanged: false,
  tickets: [],
  ticketStatus: "",
  searchItems: [],
  searchStatus: "",

  // v4
  byCategoryV4Status: "",
  byCategoryV4: [],

  subCategoriesV4: [],

  dashboardRecommendationsV4Status: "",
  dashboardRecommendationsV4: [],

  byTagV4Status: [],
  byTagV4: [],

  // ----- V3 STATE -----
  //   H i g h l i g h t s
  highlightsV3Status: "",
  highlightsV3: [],

  //   P o p u l a r
  popularV3Status: "",
  popularV3: [],

  //   J u s t   f o r   y o u
  justForYouV3Status: "",
  justForYouV3: [],

  //   R e c o m m e n d a t i o n s
  recommendationsV3Status: "",
  moreRecommendationsV3Status: "",
  recommendationsV3: [],

  //   A t t r a c t i o n s
  attractionsV3Status: "",
  moreAttractionsV3Status: "",
  attractionsV3: [],

  //   B y   C a t e g o r y
  byCategoryV3Status: [],
  moreByCategoryV3Status: [],
  byCategoryV3: [],
  subCategoriesV3: {},

  //   B y   M o o d
  byMoodV3Status: "",
  moreByMoodV3Status: "",
  byMoodV3: {},

  //   B y   T a g
  byTagV3Status: [],
  moreByTagV3Status: [],
  byTagV3: [],

  //   S e a r c h   R e s u l t s
  searchStatus: "",
  searchResults: [],
  searchTimestamp: undefined,
});
