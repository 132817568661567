import find from "lodash/find";
import filter from "lodash/filter";

export default {
  filteredCategories: (state, getters, rootState) => {
    let categories = state.categories;
    let filteredCategories =
      rootState.partners?.config?.filter_settings?.categories;

    // Only show categories, that are in filter_settings.category
    // if it contains values.
    if (filteredCategories?.length) {
      categories = filter(categories, (item) => {
        const category = find(
          filteredCategories,
          (category) => category.id === item.id
        );
        return (
          !category ||
          !category.selected_all_categories ||
          category.recommendations?.length > 0
        );
      });
    }
    return categories;
  },
};
