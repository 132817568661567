import api from "@/api";

export default {
  async getTagsKeywords(
    { commit, rootState },
    { lang = rootState.languages.currentLang.id }
  ) {
    commit("FETCH_TAGS");
    try {
      commit("SUCCESS_FETCH_TAGS", await api.getTagsKeywords(lang));
    } catch (error) {
      commit("FAILURE_FETCH_TAGS");
    }
  },
};
