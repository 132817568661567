export default {
  SET_SEARCH_LOCATION: (state, value) => {
    state.searchLocation = value;
  },
  ADD_TO_SEARCH_HISTORY: (state, value) => {
    state.searchHistory.unshift(value);
  },
  CLEAR_SEARCH_HISTORY: (state) => {
    state.searchHistory = [];
  },
  REMOVE_SEARCH_ITEM: (state, value) => {
    state.searchHistory.splice(state.searchHistory.indexOf(value), 1);
  },
  ADD_TO_LOCATION_HISTORY: (state, value) => {
    state.locationHistory.unshift(value);
  },
  CLEAR_LOCATION_HISTORY: (state) => {
    state.locationHistory = [];
  },
  REMOVE_LOCATION_ITEM: (state, value) => {
    state.locationHistory.splice(state.locationHistory.indexOf(value), 1);
  },
  SET_SEARCH_RESULTS: (state, value) => {
    state.searchResults = value;
  },
  CLEAR_SEARCH_RESULTS: (state) => {
    state.searchResults = [];
  },
  SET_SEARCH_TEXT: (state, value) => {
    state.searchText = value;
  },
};
