export default () => ({
  items: [],
  status: "",
  filter: "",
  categoryList: [],
  catFilter: "",
  moreStatus: "",
  lastHash: undefined,
  radius: 20,
  capacityReached: false,
  selectedCategory: {},
  selectedMood: {},
  locations: [],
  highlights: [],
  justForYou: [],
  popular: [],
  locationsStatus: "",
  highlightsStatus: "",
  justForYouStatus: "",
  popularStatus: "",
  details: {},
  detailStatus: "",
  allStatus: "",
  allItems: [],
  updateStatus: "",
  byCategoryStatus: "",
  byCategory: [],
  byMood: [],
  byMoodStatus: "",
  reviewStatus: "",
  subCategories: [],
  manualLocationChanged: false,
});
