import find from "lodash/find";
import map from "lodash/map";
import filter from "lodash/filter";

export default {
  allList: (state, getters, rootState, rootGetters) => {
    let items = state.allItems;
    let filteredCategories = map(
      rootGetters["categories/filteredCategories"],
      "id"
    );

    items = filter(items, (o) => {
      try {
        return (
          o.category &&
          o.category.id &&
          find(filteredCategories, (filter) => {
            return filter === o.category.id;
          })
        );
      } catch (error) {
        console.error(error);
      }
    });
    return items;
  },
  allItems: (state, getters, rootState, rootGetters) => {
    let items = state.recommendations.concat(
      state.highlights,
      state.justForYou,
      state.popular,
      state.byCategory,
      state.allItems,
      rootState.map.marker
    );
    let filteredCategories = map(
      rootGetters["categories/filteredCategories"],
      "id"
    );

    items = filter(items, (o) => {
      try {
        return (
          o.category &&
          o.category.id &&
          find(filteredCategories, (filter) => {
            return filter === o.category.id;
          })
        );
      } catch (error) {
        console.error(error);
      }
    });
    return items;
  },
  filteredRecommendations: (state, getters, rootState, rootGetters) => {
    let items = state.recommendationsV3;
    let filteredCategories = map(
      rootGetters["categories/filteredCategories"],
      "id"
    );
    // As required per 16.11.2021: Removing Essentials and Mobility
    filteredCategories = filter(filteredCategories, (category) => {
      return (
        category !== "0FZ9vzGWwRSGQ6W34IKH" && // Mobility
        category !== "DeuNaJOooUNa7bcjAWFB" // Essentials
      );
    });
    items = filter(items, (o) => {
      return find(filteredCategories, (filter) => {
        return filter === o?.category?.id;
      });
    });

    return items;
  },
  dashboardRecommendations: (state, getters, rootState, rootGetters) => {
    return filter(state.dashboardRecommendationsV4, (o) => {
      return find(rootGetters["categories/filteredCategories"], (category) => {
        return category.id === o?.category?.id;
      });
    });
  },
  filteredHighlights: (state, getters, rootState, rootGetters) => {
    let items = state.highlightsV3;
    let filteredCategories = map(
      rootGetters["categories/filteredCategories"],
      "id"
    );
    items = filter(items, (o) => {
      try {
        return (
          o.category &&
          o.category.id &&
          find(filteredCategories, (filter) => {
            return filter === o.category.id;
          })
        );
      } catch (error) {
        console.error(error);
      }
    });

    return items;
  },
  filteredPopular: (state, getters, rootState, rootGetters) => {
    let items = state.popularV3;
    let filteredCategories = map(
      rootGetters["categories/filteredCategories"],
      "id"
    );
    items = filter(items, (o) => {
      try {
        return (
          o.category &&
          o.category.id &&
          find(filteredCategories, (filter) => {
            return filter === o.category.id;
          })
        );
      } catch (error) {
        console.error(error);
      }
    });

    return items;
  },
  filteredJustForYou: (state, getters, rootState, rootGetters) => {
    let items = state.justForYouV3;

    /* Depricated. Fetching items from liked categories.
    if (
      rootState.profile.data.likes &&
      rootState.profile.data.likes.length > 0
    ) {
      items = filter(items, (o) => {
        try {
          return (
            o.category &&
            o.category.id &&
            find(rootState.profile.data.likes, (filter) => {
              return filter.id === o.category.id;
            })
          );
        } catch (error) {
          console.error(error);
        }
      });
    } else {
      items = filter(items, (item) => {
        // Setting default category preference to Dining
        return item.category.id === "GiMr1Z6AA4DonrWzD0Nb";
      });
    }*/
    return items;
  },
  filteredAttractions: (state) => {
    return state.attractionsV3;
  },
};
