import findIndex from "lodash/findIndex";
import filter from "lodash/filter";

export default {
  taxi: (state, getters, rootState) => {
    var item = state.taxi;

    return item;
  },
};
