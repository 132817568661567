import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: "#01A2E9",
        primary_gradient_middle: "#56c0df",
        primary_gradient_end: "#86d0da",
        onprimary: "#FFFFFF",
        background: "#FFFFFF",
        onbackground: "#272727",
        onnavigation: "#616161",
        backgroundoverlay: "#09212b",
        shadowonbackground: "#d4dbe4",
        lightpurple: "#e7dcff",
      },
      dark: {
        primary: "#01A2E9",
        primary_gradient_middle: "#56c0df",
        primary_gradient_end: "#86d0da",
        onprimary: "#FFFFFF",
        background: "#272727",
        onbackground: "#FFFFFF",
        onnavigation: "#a9a9a9",
        backgroundoverlay: "#09212b",
        shadowonbackground: "#1e1e1e",
        lightpurple: "#0b0025",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
