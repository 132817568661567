import api from "@/api";

export default {
  async getDeals({ commit }) {
    commit("REQUEST_DEALS");
    try {
      commit("SUCCESS_REQUEST_DEALS", await api.getDeals());
    } catch (e) {
      commit("FAILURE_REQUEST_DEALS");
    }
  },
};
