import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    drawer: false,
    mediaHeight: 0,
    breakpoint: void 0,
    loading: false,
    initWowBot: false,
    redirectUrl: void 0,
    refreshBar: false,
    registration: null,
    currentWindow: 1,
    navigation: false,
    notificationOverlay: false,
    isMiKY: false,
    firstVisit: true,
  },
  mutations: {
    SET_IS_MIKY(state, value) {
      state.isMiKY = value;
    },
    SET_FIRST_VISIT(state, value) {
      state.firstVisit = value;
    },
    SET_CURRENT_WINDOW(state, value) {
      state.currentWindow = value;
    },
    SET_DRAWER(state, value) {
      state.drawer = value;
    },
    SET_MEDIA_HEIGHT(state, value) {
      state.mediaHeight = value;
    },
    SET_BREAKPOINT(state, value) {
      // the framework property of vuetify's breakpoint is circular, so we need to remove it from the copy
      value.framework = void 0;
      state.breakpoint = JSON.parse(JSON.stringify(value));
    },
    REDIRECT_TO(state, value) {
      state.redirectUrl = value;
    },
    SET_REFRESH_BAR: (state, value) => {
      state.refreshBar = value;
    },
    SHOW_REFRESH_BAR: (state, { registration }) => {
      state.registration = registration;
      state.refreshBar = true;
    },
    SET_CHATBOT_INIT: (state, value) => {
      state.initWowBot = value;
    },
    SET_LOADING: (state, value) => {
      state.loading = value;
    },
    SET_MENU: (state, value) => {
      state.navigation = value;
    },
    SET_NOTIFICATION_OVERLAY: (state, value) => {
      state.notificationOverlay = value;
    },
  },
  actions: {
    redirectTo({ commit }, url) {
      commit("REDIRECT_TO", url);
      window.location = url;
    },
    calculateMediaHeight({ commit }, { breakpoint }) {
      // mobile phone landscape or ipad portrait
      commit("SET_BREAKPOINT", breakpoint);
      if ((breakpoint.smAndDown && breakpoint.height < 450) || breakpoint.xs) {
        commit("SET_MEDIA_HEIGHT", 275);
      } else if (breakpoint.sm || breakpoint.md || breakpoint.lg) {
        commit("SET_MEDIA_HEIGHT", 425);
      } else {
        commit("SET_MEDIA_HEIGHT", 500);
      }
    },
  },
});
