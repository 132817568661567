export default () => ({
  status: "",
  moods: [
    {
      _fl_meta_: {
        fl_id: "OodEJ0QCpRTfnOfKGO91",
      },
      id: "OodEJ0QCpRTfnOfKGO91",
      title: "Romance",
      image:
        "https://firebasestorage.googleapis.com/v0/b/wowapp-b59fa.appspot.com/o/flamelink%2Fmedia%2FRomance_200x200.png?alt=media",
      selected: false,
    },
    {
      _fl_meta_: {
        fl_id: "WrZiwjKjtrrDzZdp46hI",
      },
      id: "WrZiwjKjtrrDzZdp46hI",
      title: "Fun",
      image:
        "https://firebasestorage.googleapis.com/v0/b/wowapp-b59fa.appspot.com/o/flamelink%2Fmedia%2FFun_200x200.png?alt=media",
      selected: false,
    },
    {
      _fl_meta_: {
        fl_id: "cIJyHLGS6vF0nb5SzCff",
      },
      id: "cIJyHLGS6vF0nb5SzCff",
      title: "Adventure",
      image:
        "https://firebasestorage.googleapis.com/v0/b/wowapp-b59fa.appspot.com/o/flamelink%2Fmedia%2FAdventure_200x200.png?alt=media",
      selected: false,
    },
    {
      _fl_meta_: {
        fl_id: "jNiO9QaGtW4APMp7MWvN",
      },
      id: "jNiO9QaGtW4APMp7MWvN",
      title: "Discovery",
      image:
        "https://firebasestorage.googleapis.com/v0/b/wowapp-b59fa.appspot.com/o/flamelink%2Fmedia%2FDiscovery_200x200.png?alt=media",
      selected: false,
    },
    {
      _fl_meta_: {
        fl_id: "lICbXQYKAV6XMfBu33k4",
      },
      id: "lICbXQYKAV6XMfBu33k4",
      title: "Thrilling",
      image:
        "https://firebasestorage.googleapis.com/v0/b/wowapp-b59fa.appspot.com/o/flamelink%2Fmedia%2FThrilling_200x200.png?alt=media",
      selected: false,
    },
    {
      _fl_meta_: {
        fl_id: "rXqYdWNlJfqZnlD6DD7X",
      },
      id: "rXqYdWNlJfqZnlD6DD7X",
      title: "Relaxing",
      image:
        "https://firebasestorage.googleapis.com/v0/b/wowapp-b59fa.appspot.com/o/flamelink%2Fmedia%2FRelaxing_200x200.png?alt=media",
      selected: false,
    },
  ],
});
