import api from "@/api";

export default {
  async getAvailableLanguages({ commit }) {
    commit("FETCH_LANGUAGES");
    try {
      commit("SUCCESS_FETCH_LANGUAGES", await api.getAvailableLanguages());
    } catch (e) {
      commit("FAILURE_FETCH_LANGUAGES");
    }
  },
};
