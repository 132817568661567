export default {
  CLEAR_AUTH_ERROR: (state) => {
    state.error = undefined;
  },
  SET_ERROR: (state, value) => {
    state.error = value;
  },
  SET_USER: (state, value) => {
    // Force update on user change.
    state.user = undefined;
    state.user = value;
  },
  REQUEST_CREATE_USER: (state) => {
    state.createStatus = "fetching";
  },
  SUCCESS_REQUEST_CREATE_USER: (state, value) => {
    state.createStatus = "success";
    state.user = value;
  },
  FAILURE_REQUEST_CREATE_USER: (state, value) => {
    state.createStatus = "failure";
    state.error = value;
  },
  REQUEST_SIGN_IN: (state, value) => {
    state.signinStatus = "fetching";
    state.reloadAfterSignIn = value;
  },
  SUCCESS_REQUEST_SIGN_IN: (state) => {
    state.signinStatus = "success";
    state.reloadAfterSignIn = false;
  },
  FAILURE_REQUEST_SIGN_IN: (state, value) => {
    state.signinStatus = "failure";
    state.error = value;
    state.reloadAfterSignIn = false;
  },
  SET_PROVIDER: (state, value) => {
    state.provider = value;
  },
  SET_SESSION_STATE: (state, value) => {
    state.status = value;
  },
  SET_REFRESHING: (state, value) => {
    state.refreshing = value;
  },
  REQUEST_RESET: (state) => {
    state.resetStatus = "fetching";
  },
  SUCCESS_REQUEST_RESET: (state) => {
    state.resetStatus = "success";
  },
  FAILURE_REQUEST_RESET: (state) => {
    state.resetStatus = "failure";
  },
  REQUEST_VERIFICATION: (state) => {
    state.verifyStatus = "fetching";
  },
  SUCCESS_REQUEST_VERIFICATION: (state) => {
    state.verifyStatus = "success";
  },
  FAILURE_REQUEST_VERIFICATION: (state) => {
    state.verifyStatus = "failure";
  },
  REQUEST_CURRENT_CITY: (state) => {
    state.cityStatus = "fetching";
  },
  SUCCESS_CURRENT_CITY: (state, value) => {
    state.currentCity = value;
    state.cityStatus = "success";
  },
  FAILURE_CURRENT_CITY: (state) => {
    state.cityStatus = "failure";
  },
  SET_QUESTIONS_ASKED: (state, value) => {
    state.askingDone = value;
  },
  SET_LAST_LOGIN: (state, value) => {
    state.lastLogin = value;
  },
  SET_SHOW_INTRODUCTION: (state, value) => {
    state.showIntroduction = value;
  },
  DELETE_ACCOUNT: (state) => {
    state.deleteStatus = "fetching";
  },
  SUCCESS_DELETE_ACCOUNT: (state) => {
    state.user = void 0;
    state.deleteStatus = "success";
  },
  FAILURE_DELETE_ACCOUNT: (state) => {
    state.deleteStatus = "failure";
  },
  SET_ADDRESS: (state, value) => {
    state.address = value;
  },
  SET_LOCATION_ERROR: (state, value) => {
    state.locationError = value;
  },
  SET_APP_VERSION: (state, value) => {
    state.appVersion = value;
  },
  SET_PAGEYOFFSET: (state, value) => {
    state.pageYOffset = value;
  },
  SET_RENDERED: (state, value) => {
    state.rendered = value;
  },
  SET_SELECTED_SEGMENT: (state, value) => {
    state.selectedSegment = value;
  },
};
