import api from "@/api";
import each from "lodash/each";
import find from "lodash/find";

export default {
  async getAdvertisements(
    { commit, rootState, dispatch },
    lang = rootState.languages.currentLang.id
  ) {
    commit("FETCH_ADVERTISEMENTS");
    try {
      commit("SUCCESS_FETCH_ADVERTISEMENTS", await api.getAdvertisements(lang));
      dispatch("setAdTimeout");
    } catch (error) {
      commit("FAILURE_FETCH_ADVERTISEMENTS");
    }
  },
  setAdTimeout({ commit, state }) {
    each(state.items, (item) => {
      if (item.showAfterTime) {
        if (item.banner) {
          setTimeout(() => {
            commit("SET_CURRENT_AD", item);
            commit("SET_BANNER", true);
          }, parseInt(item.showAfterTimeSeconds) * 1000);
        } else {
          setTimeout(() => {
            commit("SET_CURRENT_AD", item);
            commit("SET_DIALOG", true);
          }, parseInt(item.showAfterTimeSeconds) * 1000);
        }
      }
    });
  },
  showAdDetail({ commit, state }, detail) {
    const ad = find(state.items, (item) => {
      return (
        item.alreadyShown === false &&
        item.category.title === detail.category.name &&
        item.showAtDetail === true
      );
    });

    if (ad) {
      commit("SET_CURRENT_AD", ad);
      if (ad.banner) {
        commit("SET_BANNER", true);
      } else {
        commit("SET_DIALOG", true);
      }
    }
  },
};
