import api from "@/api";
import each from "lodash/each";
import find from "lodash/find";
import moment from "moment";
import map from "lodash/map";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import intersectionWith from "lodash/intersectionWith";
export default {
  async getLocations(
    { commit, state, rootState, rootGetters },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = rootState.recommendations.radius,
      userId = rootState.profile.data.id,
      all = false,
      type = "location",
      filteredCategories = map(
        rootGetters["categories/filteredCategories"],
        "id"
      ),
    }
  ) {
    commit("FETCH_LOCATIONS");
    try {
      let items = await api.getLocations(
        lat,
        lng,
        lang,
        radius,
        type,
        all,
        userId,
        filteredCategories
      );

      items = filter(items, (item) => {
        return item.distance <= radius * 1000;
      });
      commit("recommendations/SUCCESS_FETCH_LOCATIONS", items, { root: true });
    } catch (error) {
      console.error(error);
      commit("FAILURE_FETCH_LOCATIONS");
    }
  },
  // Not in use.
  async getHighlights(
    { commit, rootState, state, rootGetters },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      all = false,
      type = "highlights",
      filteredCategories = map(
        rootGetters["categories/filteredCategories"],
        "id"
      ),
    }
  ) {
    commit("FETCH_HIGHLIGHTS");
    try {
      commit(
        "SUCCESS_FETCH_HIGHLIGHTS",
        await api.getHighlights(
          lat,
          lng,
          lang,
          radius,
          type,
          all,
          filteredCategories
        )
      );
    } catch (error) {
      commit("FAILURE_FETCH_HIGHLIGHTS");
    }
  },
  // Not in use.
  async getPopular(
    { commit, rootState, rootGetters, state },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      radius = state.radius,
      lang = rootState.languages.currentLang.id,
      all = false,
      type = "popular",
      filteredCategories = map(
        rootGetters["categories/filteredCategories"],
        "id"
      ),
    }
  ) {
    commit("FETCH_POPULAR");
    try {
      commit(
        "SUCCESS_FETCH_POPULAR",
        await api.getPopular(
          lat,
          lng,
          lang,
          radius,
          type,
          all,
          filteredCategories
        )
      );
    } catch (error) {
      commit("FAILURE_FETCH_POPULAR");
    }
  },
  // Not in use.
  async getJustForYou(
    { commit, state, rootState, rootGetters },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      userId = rootState.profile.data.id,
      all = false,
      type = "justForYou",
      filteredCategories = map(
        rootGetters["categories/filteredCategories"],
        "id"
      ),
    }
  ) {
    commit("FETCH_JUSTFORYOU");
    try {
      commit(
        "SUCCESS_FETCH_JUSTFORYOU",
        await api.getJustForYou(
          lat,
          lng,
          lang,
          radius,
          type,
          all,
          userId,
          filteredCategories
        )
      );
    } catch (error) {
      commit("FAILURE_FETCH_JUSTFORYOU");
    }
  },
  async getDetails(
    { commit, rootState, dispatch },
    { entryId, lang = rootState.languages.currentLang.id }
  ) {
    commit("FETCH_DETAILS");
    try {
      let details = await api.getDetails(entryId, lang, "locations");
      commit("SUCCESS_FETCH_DETAILS", details);
      dispatch("updateClicked", {
        id: details.id,
        clicked: details.clicked + 1,
      });
    } catch (error) {
      commit("FAILURE_FETCH_DETAILS");
    }
  },
  async getAllLocationss(
    { commit, dispatch, state, rootState, rootGetters },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      type,
      userId = rootState.profile.data.id,
      all = true,
      filteredCategories = map(
        rootGetters["categories/filteredCategories"],
        "id"
      ),
    }
  ) {
    commit("FETCH_ALL");
    try {
      let locations = await api.getAllItems(
        lat,
        lng,
        lang,
        radius,
        type,
        all,
        userId,
        filteredCategories
      );

      locations = filter(locations, (item) => {
        return item.distance <= radius * 1000;
      });

      switch (type) {
        case "location":
          locations = orderBy(locations, ["distance"], ["asc"]);
          break;
        case "popular":
          locations = orderBy(
            filter(locations, (o) => {
              return o.clicked > 0;
            }),
            ["clicked"],
            ["desc"]
          );
          break;
        case "justForYou":
          locations = orderBy(locations, ["distance"], ["asc"]);
          break;
        case "highlights":
          locations = orderBy(
            filter(locations, { highlight: true }),
            ["distance"],
            ["asc"]
          );
          break;
      }
      commit("SUCCESS_FETCH_ALL", locations);
      dispatch("setCategoryList");
    } catch (e) {
      commit("FAILURE_FETCH_ALL");
    }
  },
  async updateClicked({ commit }, { id, clicked }) {
    commit("UPDATE_DETAILS");
    try {
      commit(
        "SUCCESS_UPDATE_DETAILS",
        await api.updateClicked(id, clicked, "locations")
      );
    } catch (error) {
      console.error(error);
      commit("FAILURE_UPDATE_DETAILS");
    }
  },
  async getByCategory(
    { commit, rootState, state },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      all = true,
      category = "",
    }
  ) {
    commit("FETCH_BYCATEGORY");
    try {
      commit(
        "SUCCESS_FETCH_BYCATEGORY",
        await api.getByCategory(lat, lng, lang, radius, all, category)
      );
    } catch (error) {
      commit("FAILURE_FETCH_BYCATEGORY");
    }
  },
  async getByMood(
    { commit, rootState, state },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      all = true,
      mood = "",
    }
  ) {
    commit("FETCH_BYMOOD");
    try {
      commit(
        "SUCCESS_FETCH_BYMOOD",
        await api.getByMood(lat, lng, lang, radius, all, mood)
      );
    } catch (error) {
      commit("FAILURE_FETCH_BYMOOD");
    }
  },
  async addUserRating(
    { commit, dispatch, state, rootState },
    { id = state.details.id, rating, comment }
  ) {
    commit("FETCH_REVIEW");
    const newRating = {
      rating,
      comment,
      alias:
        rootState.profile.data.alias ||
        rootState.profile.data.firstName +
          " " +
          rootState.profile.data.lastName,
      img: rootState.profile.data.profilePicture || "",
      postDate: moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY"),
    };
    try {
      await api.addUserRating(id, newRating, "locations");
      commit("SUCCESS_FETCH_REVIEW");
      await dispatch("getDetails", { entryId: id });
    } catch (error) {
      commit("FAILURE_FETCH_REVIEW");
    }
  },
  async changeLocation({ commit, dispatch }, city) {
    commit("SET_LOADING", true, { root: true });
    commit("SET_MANUAL_LOCATION", true);
    commit("session/SUCCESS_CURRENT_CITY", city.name.split(",")[0], {
      root: true,
    });
    commit(
      "map/SET_ORIGINAL_POSITION",
      {
        lat: city.lat,
        lng: city.lng,
      },
      { root: true }
    );
    commit(
      "map/SET_USER_POSITION",
      {
        lat: city.lat,
        lng: city.lng,
      },
      { root: true }
    );
    commit(
      "map/SET_CENTER",
      {
        lat: city.lat,
        lng: city.lng,
      },
      { root: true }
    );
    await dispatch("getLocations", { all: false });
    commit("SET_LOADING", false, { root: true });
  },
};
