import api from "../../../api";

export default {
  async getDetails({ commit }, { id }) {
    commit("FETCH_DETAILS");
    try {
      commit("SUCCESS_FETCH_DETAILS", await api.getTicketDetails(id));
    } catch (e) {
      commit("FAILURE_FETCH_DETAILS", e);
    }
  },
  async getItems(
    { commit, rootState },
    { lang = rootState.languages.currentLang.id }
  ) {
    commit("FETCH_ITEMS");
    try {
      commit("SUCCESS_FETCH_ITEMS", await api.getItems(lang));
    } catch (e) {
      commit("FAILURE_FETCH_ITEMS", e);
    }
  },
  async purchase(
    { commit, rootState, dispatch },
    { ticket, profile = rootState.profile.data }
  ) {
    commit("PURCHASE_TICKETS");
    try {
      let url = await api.startPayment(
        ticket.totalPrice,
        "AT",
        ticket.currency.code,
        ticket.header,
        profile.lastName || "JOST",
        "5c09448ff558e6000123424f",
        ticket.id,
        ticket.id
      );

      const addTicket = {
        id: ticket.id,
        header: ticket.header,
        price: ticket.price,
        totalPrice: ticket.totalPrice,
        longDescription: ticket.longDescription,
        shortDescription: ticket.shortDescription,
        imageGallery: ticket.imageGallery,
      };

      if (ticket.totalPrice > 0) {
        if (
          this.$store.state.isMiKY &&
          (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
        ) {
          window.location = url.redirectUrl;
        } else {
          window.open(url.redirectUrl, "_system");
        }
      }

      commit("SUCCESS_PURCHASE_TICKETS", ticket);
      dispatch("profile/addTicket", { ticket: addTicket }, { root: true });
    } catch (e) {
      commit("FAILURE_PURCHASE_TICKETS", e);
    }
  },
};
