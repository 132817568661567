import api from "@/api";
export default {
  async getPartnerConfig(
    { commit, rootState },
    { partnerId, lang = rootState.languages.currentLang.id }
  ) {
    commit("FETCH_PARTNER_CONFIG");
    try {
      const config = await api.getPartnerConfig(partnerId, lang);
      commit("SUCCESS_FETCH_PARTNER_CONFIG", config);
    } catch (e) {
      commit("FAILURE_FETCH_PARTNER_CONFIG");
    }
  },
  async savePartnerForUser({}, { userId, partnerId }) {
    try {
      await api.savePartnerForUser(userId, partnerId);
    } catch (e) {}
  },
};
