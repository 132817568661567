import uniqBy from "lodash/uniqBy";
import shuffle from "lodash/shuffle";
import remove from "lodash/remove";
import find from "lodash/find";

export default {
  REQUEST_RECOMMENDATIONS: (state) => {
    state.status = "fetching";
  },
  SUCCESS_REQUEST_RECOMMENDATIONS: (state, value) => {
    state.status = "success";
    state.items = value;
  },
  FAILURE_REQUEST_RECOMMENDATIONS: (state) => {
    state.status = "failure";
    state.items = [];
  },
  REQUEST_MORE_RECOMMENDATIONS: (state) => {
    state.moreStatus = "fetching";
  },
  SUCCESS_REQUEST_MORE_RECOMMENDATIONS: (state, value) => {
    state.moreStatus = "success";
    state.items = state.items.concat(value);
  },
  FAILURE_REQUEST_MORE_RECOMMENDATIONS: (state) => {
    state.moreStatus = "failure";
  },
  SET_ITEM_CATEGORY: (state, value) => {
    state.items[value.index].category = value.category;
  },
  SET_FILTER: (state, value = "") => {
    value = value || "";
    state.filter = value.toLowerCase();
  },
  SET_CAT_FILTER: (state, value) => {
    state.catFilter = value;
  },
  SET_CATEGORY_LIST: (state, value) => {
    state.categoryList = value;
  },
  SET_RADIUS: (state, value) => {
    state.radius = value;
  },
  SET_CAPACITY_REACHED: (state, value) => {
    state.capacityReached = value;
  },
  SET_SELECTED_CATEGORY: (state, value) => {
    state.selectedCategory = value;
  },
  SET_SELECTED_MOOD: (state, value) => {
    state.selectedMood = value;
  },
  FETCH_RECOMMENDATIONS_V2: (state) => {
    state.recommendationsStatus = "fetching";
  },
  SUCCESS_FETCH_RECOMMENDATIONS_V2: (state, value) => {
    state.recommendationsStatus = "success";
    state.recommendations = value;
  },
  FAILURE_FETCH_RECOMMENDATIONS_V2: (state) => {
    state.recommendationsStatus = "failure";
  },

  // v4
  FETCH_BY_CATEGORY_V4: (state) => {
    state.byCategoryV4Status = "fetching";
  },
  SUCCESS_FETCH_BY_CATEGORY_V4: (state, items) => {
    state.byCategoryV4Status = "success";
    state.byCategoryV4 = items;
    // Extracting subcategories and saving them seperately
    let t = [];
    items.forEach((item) => {
      item.subCategory.forEach((s) => {
        t.push(s.title ? s : { title: s });
      });
    });
    state.subCategoriesV4 = t;
  },
  FAILURE_FETCH_BY_CATEGORY_V4: (state) => {
    state.byCategoryV4Status = "failure";
  },

  FETCH_DASHBOARD_RECOMMENDATIONS_V4: (state) => {
    state.dashboardRecommendationsV4Status = "fetching";
  },
  SUCCESS_FETCH_DASHBOARD_RECOMMENDATIONS_V4: (state, items) => {
    state.dashboardRecommendationsV4Status = "success";
    state.dashboardRecommendationsV4 = items;
  },
  FAILURE_FETCH_DASHBOARD_RECOMMENDATIONS_V4: (state) => {
    state.dashboardRecommendationsV4Status = "failure";
  },

  FETCH_BY_TAG_V4: (state, { tags }) => {
    remove(state.byTagV4Status, ["tags", tags]);
    state.byTagV4Status.push({ tags, status: "fetching" });
  },
  SUCCESS_FETCH_BY_TAG_V4: (state, { tags, items }) => {
    remove(state.byTagV4Status, ["tags", tags]);
    state.byTagV4Status.push({ tags, status: "success" });
    remove(state.byTagV4, ["tags", tags]);
    state.byTagV4.push({
      tags,
      items,
    });
  },
  FAILURE_FETCH_BY_TAG_V4: (state, { tags }) => {
    remove(state.byTagV4Status, ["tags", tags]);
    state.byTagV4Status.push({ tags, status: "failure" });
  },
  RESET_BY_TAG_V4: (state) => {
    state.byTagV4Status = [];
    state.byTagV4 = [];
  },

  // ---------- V3 MUTATIONS ----------
  //   H i g h l i g h t s
  FETCH_HIGHLIGHTS_V3: (state) => {
    state.highlightsV3Status = "fetching";
  },
  SUCCESS_FETCH_HIGHLIGHTS_V3: (state, value) => {
    state.highlightsV3Status = "success";
    state.highlightsV3 = value;
  },
  FAILURE_FETCH_HIGHLIGHTS_V3: (state) => {
    state.highlightsV3Status = "failure";
  },
  RESET_HIGHLIGHTS_V3: (state) => {
    state.highlightsV3Status = "";
    state.highlightsV3 = [];
  },

  //   P o p u l a r
  FETCH_POPULAR_V3: (state) => {
    state.popularV3Status = "fetching";
  },
  SUCCESS_FETCH_POPULAR_V3: (state, value) => {
    state.popularV3Status = "success";
    state.popularV3 = value;
  },
  FAILURE_FETCH_POPULAR_V3: (state) => {
    state.popularV3Status = "failure";
  },
  RESET_POPULAR_V3: (state) => {
    state.popularV3Status = "";
    state.popularV3 = [];
  },

  //   J u s t   f o r   y o u
  FETCH_JUSTFORYOU_V3: (state) => {
    state.justForYouV3Status = "fetching";
  },
  SUCCESS_FETCH_JUSTFORYOU_V3: (state, value) => {
    state.justForYouV3Status = "success";
    state.justForYouV3 = value;
  },
  FAILURE_FETCH_JUSTFORYOU_V3: (state) => {
    state.justForYouV3Status = "failure";
  },
  RESET_JUSTFORYOU_V3: (state) => {
    state.justforyouV3Status = "";
    state.justforyouV3 = [];
  },

  //   M o r e   J u s t   f o r   y o u
  FETCH_MORE_JUSTFORYOU_V3: (state) => {
    state.moreJustForYouV3Status = "fetching";
  },
  SUCCESS_FETCH_MORE_JUSTFORYOU_V3: (state, value) => {
    state.moreJustForYouV3Status = "success";
    state.justForYouV3 = state.justForYouV3.concat(value);
  },
  FAILURE_FETCH_MORE_JUSTFORYOU_V3: (state) => {
    state.moreJustForYouV3Status = "failure";
  },

  //   R e c o m m e n d a t i o n s
  FETCH_RECOMMENDATIONS_V3: (state) => {
    state.recommendationsV3Status = "fetching";
  },
  SUCCESS_FETCH_RECOMMENDATIONS_V3: (state, value) => {
    state.recommendationsV3Status = "success";
    state.recommendationsV3 = value;
  },
  FAILURE_FETCH_RECOMMENDATIONS_V3: (state) => {
    state.recommendationsV3Status = "failure";
  },
  RESET_RECOMMENDATIONS_V3: (state) => {
    state.recommendationsV3Status = "";
    state.recommendationsV3 = [];
  },

  //   M o r e   R e c o m m e n d a t i o n s
  FETCH_MORE_RECOMMENDATIONS_V3: (state) => {
    state.moreRecommendationsV3Status = "fetching";
  },
  SUCCESS_FETCH_MORE_RECOMMENDATIONS_V3: (state, value) => {
    state.moreRecommendationsV3Status = "success";
    state.recommendationsV3 = state.recommendationsV3.concat(value);
  },
  FAILURE_FETCH_MORE_RECOMMENDATIONS_V3: (state) => {
    state.moreRecommendationsV3Status = "failure";
  },
  RESET_MORE_RECOMMENDATIONS_V3: (state) => {
    state.morerecommendationsV3Status = "";
  },

  //   A t t r a c t i o n s
  FETCH_ATTRACTIONS_V3: (state) => {
    state.attractionsV3Status = "fetching";
  },
  SUCCESS_FETCH_ATTRACTIONS_V3: (state, value) => {
    state.attractionsV3Status = "success";
    state.attractionsV3 = value;
  },
  FAILURE_FETCH_ATTRACTIONS_V3: (state) => {
    state.attractionsV3Status = "failure";
  },
  RESET_ATTRACTIONS_V3: (state) => {
    state.attractionsV3Status = "";
    state.attractionsV3 = [];
  },

  //   M o r e   A t t r a c t i o n s
  FETCH_MORE_ATTRACTIONS_V3: (state) => {
    state.moreAttractionsV3Status = "fetching";
  },
  SUCCESS_FETCH_MORE_ATTRACTIONS_V3: (state, value) => {
    state.moreAttractionsV3Status = "success";
    state.attractionsV3 = state.attractionsV3.concat(value);
  },
  FAILURE_FETCH_MORE_ATTRACTIONS_V3: (state) => {
    state.moreAttractionsV3Status = "failure";
  },
  RESET_MORE_ATTRACTIONS_V3: (state) => {
    state.moreattractionsV3Status = "";
  },

  //   B y   C a t e g o r y
  FETCH_BY_CATEGORY_V3: (state, { category }) => {
    remove(state.byCategoryV3Status, ["category", category]);
    state.byCategoryV3Status.push({ category, status: "fetching" });
  },
  SUCCESS_FETCH_BY_CATEGORY_V3: (state, { category, items }) => {
    remove(state.byCategoryV3Status, ["category", category]);
    state.byCategoryV3Status.push({ category, status: "success" });
    remove(state.byCategoryV3, ["category", category]);
    state.byCategoryV3.push({
      category,
      items,
    });
    // Extracting subcategories and saving them seperately
    let subCats = [];
    find(state.byCategoryV3, ["category", category])?.items?.forEach((item) => {
      subCats = subCats.concat(item.subCategory);
    });
    subCats = uniqBy(subCats, "title");
    state.subCategoriesV3[category] = subCats;
  },
  FAILURE_FETCH_BY_CATEGORY_V3: (state, { category }) => {
    remove(state.byCategoryV3Status, ["category", category]);
    state.byCategoryV3Status.push({ category, status: "failure" });
  },
  RESET_BY_CATEGORY_V3: (state) => {
    state.byCategoryV3Status = [];
    state.byCategoryV3 = [];
  },

  //   M o r e   B y   C a t e g o r y
  FETCH_MORE_BY_CATEGORY_V3: (state, { category }) => {
    remove(state.moreByCategoryV3Status, ["category", category]);
    state.moreByCategoryV3Status.push({ category, status: "fetching" });
  },
  SUCCESS_FETCH_MORE_BY_CATEGORY_V3: (state, { category, items }) => {
    remove(state.moreByCategoryV3Status, ["category", category]);
    state.moreByCategoryV3Status.push({ category, status: "success" });
    // Save a copy of the items in byCategoryV3.
    let byCategory = find(state.byCategoryV3, ["category", category])?.items;
    remove(state.byCategoryV3, ["category", category]);
    state.byCategoryV3.push({
      category,
      items: byCategory ? byCategory.concat(items) : items,
    });
    // Extracting subcategories and saving them seperately
    let subCats = [];
    find(state.byCategoryV3, ["category", category])?.items?.forEach((item) => {
      subCats = subCats.concat(item.subCategory);
    });
    subCats = uniqBy(subCats, "title");
    state.subCategoriesV3[category] = subCats;
  },
  FAILURE_FETCH_MORE_BY_CATEGORY_V3: (state, { category }) => {
    remove(state.moreByCategoryV3Status, ["category", category]);
    state.moreByCategoryV3Status.push({ category, status: "failure" });
  },
  RESET_MORE_BY_CATEGORY_V3: (state) => {
    state.moreByCategoryV3Status = [];
  },

  //   B y   M o o d
  FETCH_BY_MOOD_V3: (state) => {
    state.byMoodV3Status = "fetching";
  },
  SUCCESS_FETCH_BY_MOOD_V3: (state, { mood, items }) => {
    state.byMoodV3Status = "success";
    state.byMoodV3[mood] = items;
  },
  FAILURE_FETCH_BY_MOOD_V3: (state) => {
    state.byMoodV3Status = "failure";
  },
  RESET_BY_MOOD_V3: (state) => {
    state.byMoodV3Status = "";
    state.byMoodV3 = {};
  },

  //   M o r e   B y   M o o d
  FETCH_MORE_BY_MOOD_V3: (state) => {
    state.moreByMoodV3Status = "fetching";
  },
  SUCCESS_FETCH_MORE_BY_MOOD_V3: (state, { mood, items }) => {
    state.moreByMoodV3Status = "success";
    state.byMoodV3[mood] = state.byMoodV3[mood]
      ? state.byMoodV3[mood].concat(items)
      : items;
  },
  FAILURE_FETCH_MORE_BY_MOOD_V3: (state) => {
    state.moreByMoodV3Status = "failure";
  },
  RESET_MORE_BY_MOOD_V3: (state) => {
    state.moreByMoodV3Status = "";
  },

  //   B y   T a g
  FETCH_BY_TAG_V3: (state, { tags }) => {
    remove(state.byTagV3Status, ["tags", tags]);
    state.byTagV3Status.push({ tags, status: "fetching" });
  },
  SUCCESS_FETCH_BY_TAG_V3: (state, { tags, items }) => {
    remove(state.byTagV3Status, ["tags", tags]);
    state.byTagV3Status.push({ tags, status: "success" });
    remove(state.byTagV3, ["tags", tags]);
    state.byTagV3.push({
      tags,
      items,
    });
  },
  FAILURE_FETCH_BY_TAG_V3: (state, { tags }) => {
    remove(state.byTagV3Status, ["tags", tags]);
    state.byTagV3Status.push({ tags, status: "failure" });
  },
  RESET_BY_TAG_V3: (state) => {
    state.byTagV3Status = [];
    state.byTagV3 = [];
  },

  //   M o r e   B y   T a g
  FETCH_MORE_BY_TAG_V3: (state, { tags }) => {
    state.moreByTagV3Status = "fetching";
  },
  SUCCESS_FETCH_MORE_BY_TAG_V3: (state, { tags, items }) => {
    state.moreByTagV3Status = "success";
    // Save a copy of the items in byTagV3.
    let byTag = find(state.byTagV3, ["tags", tags])?.items;
    remove(state.byTagV3, ["tags", tags]);
    state.byTagV3.push({
      tags,
      items: byTag ? byTag.concat(items) : items,
    });
  },
  FAILURE_FETCH_MORE_BY_TAG_V3: (state, { tags }) => {
    state.moreByTagV3Status = "failure";
  },
  RESET_MORE_BY_TAG_V3: (state) => {
    state.moreByTagV3Status = "";
  },

  //   S e a r c h   R e s u l t s
  FETCH_SEARCH_RESULTS: (state, timestamp) => {
    state.searchStatus = "fetching";
    state.searchResults = undefined;
    state.searchTimestamp = timestamp;
  },
  SUCCESS_FETCH_SEARCH_RESULTS: (state, items) => {
    state.searchStatus = "success";
    state.searchResults = undefined;
    state.searchResults = items;
  },
  FAILURE_FETCH_SEARCH_RESULTS: (state) => {
    state.searchStatus = "failure";
  },
  RESET_SEARCH_RESULTS: (state) => {
    state.searchResults = undefined;
    state.searchStatus = "";
  },

  //   M o r e   S e a r c h   R e s u l t s
  FETCH_MORE_SEARCH_RESULTS: (state) => {
    state.searchStatus = "fetching";
  },
  SUCCESS_FETCH_MORE_SEARCH_RESULTS: (state, items) => {
    state.searchStatus = "success";
    state.searchResults = state.searchResults.concat(items);
  },
  FAILURE_FETCH_MORE_SEARCH_RESULTS: (state) => {
    state.searchStatus = "failure";
  },
  // -------------------------------------------
  FETCH_DETAILS: (state) => {
    state.detailStatus = "fetching";
  },
  SUCCESS_FETCH_DETAILS: (state, value) => {
    state.detailStatus = "success";
    state.details = value;
  },
  FAILURE_FETCH_DETAILS: (state) => {
    state.detailStatus = "failure";
  },
  FETCH_TICKETS: (state) => {
    state.ticketStatus = "fetching";
  },
  SUCCESS_FETCH_TICKETS: (state, value) => {
    state.ticketStatus = "success";
    state.tickets = value;
  },
  FAILURE_FETCH_TICKETS: (state) => {
    state.ticketStatus = "failure";
  },
  FETCH_ALL: (state) => {
    state.allStatus = "fetching";
  },
  SUCCESS_FETCH_ALL: (state, value) => {
    state.allStatus = "success";
    state.allItems = value;
  },
  FAILURE_FETCH_ALL: (state) => {
    state.allStatus = "failure";
  },
  UPDATE_DETAILS: (state) => {
    state.updateStatus = "fetching";
  },
  SUCCESS_UPDATE_DETAILS: (state) => {
    state.updateStatus = "success";
  },
  FAILURE_UPDATE_DETAILS: (state) => {
    state.updateStatus = "failure";
  },
  SUCCESS_LOG_VIEW: (state) => {
    state.logView = "success";
  },
  FAILURE_LOG_VIEW: (state) => {
    state.logView = "failure";
  },
  SUCCESS_LOG_PARTNER_VIEW: (state) => {
    state.logPartnerView = "success";
  },
  FAILURE_LOG_PARTNER_VIEW: (state) => {
    state.logPartnerView = "failure";
  },
  SUCCESS_LOG_RESERVATION: (state) => {
    state.logReservation = "success";
  },
  FAILURE_LOG_RESERVATION: (state) => {
    state.logReservation = "failure";
  },
  FETCH_BYCATEGORY: (state) => {
    state.byCategoryStatus = "fetching";
  },
  SUCCESS_FETCH_BYCATEGORY: (state, value) => {
    state.byCategoryStatus = "success";
    state.byCategory = value;
    let subCats = [];
    state.byCategory = state.byCategory.map((item) => {
      item.subCategory = item.subCategory.map((subcategory) => {
        return { title: subcategory };
      });
      if (item.subCategory?.length > 0) {
        subCats = subCats.concat(item.subCategory);
      }
      return item;
    });
    subCats = uniqBy(subCats, "title");
    state.subCategories = subCats;
  },
  FAILURE_FETCH_BYCATEGORY: (state) => {
    state.byCategoryStatus = "failure";
  },
  REMOVE_BYCATEGORY: (state) => {
    state.byCategory = [];
  },
  FETCH_MORE_BYCATEGORY: (state) => {
    state.byCategoryMoreStatus = "fetching";
  },
  SUCCESS_FETCH_MORE_BYCATEGORY: (state, value) => {
    state.byCategoryMoreStatus = "success";
    if (value.length === 0) {
      return;
    }
    state.byCategory = state.byCategory.concat(value);
    let subCats = [];
    state.byCategory.forEach((item) => {
      if (item.subCategory?.length > 0) {
        subCats = subCats.concat(item.subCategory);
      }
    });
    subCats = uniqBy(subCats, "title");
    state.subCategories = subCats;
  },
  FAILURE_FETCH_MORE_BYCATEGORY: (state) => {
    state.byCategoryMoreStatus = "failure";
  },
  REMOVE_BYMOOD: (state) => {
    state.byMood = [];
  },
  FETCH_BYMOOD: (state) => {
    state.byMoodStatus = "fetching";
  },
  SUCCESS_FETCH_BYMOOD: (state, value) => {
    state.byMoodStatus = "success";
    state.byMood = value;
  },
  FAILURE_FETCH_BYMOOD: (state) => {
    state.byMoodStatus = "failure";
  },
  FETCH_MORE_BYMOOD: (state) => {
    state.byMoodMoreStatus = "fetching";
  },
  SUCCESS_FETCH_MORE_BYMOOD: (state, value) => {
    if (value.length == 0) {
      state.byMoodMoreStatus = "failure";
      return;
    }
    state.byMoodMoreStatus = "success";
    state.byMood = state.byMood.concat(value);
  },
  FAILURE_FETCH_MORE_BYMOOD: (state) => {
    state.byMoodMoreStatus = "failure";
  },
  FETCH_REVIEW: (state) => {
    state.reviewStatus = "fetching";
  },
  SUCCESS_FETCH_REVIEW: (state) => {
    state.reviewStatus = "success";
  },
  FAILURE_FETCH_REVIEW: (state) => {
    state.reviewStatus = "failure";
  },
  CLEAR_SUB_FILTER: (state) => {
    state.subCategories.forEach((item) => {
      // POCO 185, category filters only work once bugfix
      if (item.hasOwnProperty("selected")) {
        item.selected = false;
      }
    });
  },
  SET_MANUAL_LOCATION: (state, value) => {
    state.manualLocationChanged = value;
  },
  SUCCESS_FETCH_LOCATIONS: (state, value) => {
    state.justForYou = shuffle(state.justForYou.concat(value));
    state.recommendations = shuffle(state.recommendations.concat(value));
    state.highlights = shuffle(state.highlights);
    state.popular = shuffle(state.popular);
  },
  SHOW_CURRENT_RECOMMENDATIONS: (state, value) => {
    state.currentLocationRecommendations = value;
  },
  TEXT_SEARCH: (state) => {
    state.searchStatus = "fetching";
  },
  SUCCESS_TEXT_SEARCH: (state, value) => {
    state.searchStatus = "success";
    state.searchItems = value;
  },
  FAILURE_TEXT_SEARCH: (state) => {
    state.searchStatus = "failure";
  },
};
