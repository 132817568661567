export default {
  SET_TICKET_OPTIONS: (state, value) => {
    state.options = value;
  },
  FETCH_ITEMS: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_ITEMS: (state, value) => {
    state.status = "success";
    state.items = value;
  },
  FAILURE_FETCH_ITEMS: (state, value) => {
    state.status = "failure";
    state.error = value;
  },
  PURCHASE_TICKETS: (state) => {
    state.purchaseStatus = "fetching";
  },
  SUCCESS_PURCHASE_TICKETS: (state, value) => {
    (state.purchaseStatus = "success"), (state.lastPurchased = value);
  },
  FAILURE_PURCHASE_TICKETS: (state) => {
    state.purchaseStatus = "failure";
  },
  FETCH_DETAILS: (state) => {
    state.detailStatus = "fetching";
  },
  SUCCESS_FETCH_DETAILS: (state, value) => {
    state.detailStatus = "success";
    state.details = value;
  },
  FAILURE_FETCH_DETAILS: (state, value) => {
    state.detailStatus = "failure";
    state.error = value;
  },
};
