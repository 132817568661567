<template>
  <v-bottom-sheet
    v-model="dateModal"
    overlay-color="backgroundoverlay"
    overlay-opacity="1"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        solo
        :class="!$store.state.session.showIntroduction ? 'noDialog' : 'px-4'"
        prepend-inner-icon="mdi-calendar"
        placeholder="DD/MM/YYYY"
        v-model="birthday"
        readonly
        hide-details
        v-on="on"
      ></v-text-field>
    </template>
    <v-sheet
      class="text-center"
      height="288"
      style="border-radius: 30px 30px 0px 0px"
    >
      <v-toolbar
        height="38px"
        flat
        style="border-radius: 10px; background: transparent"
        class="mb-4"
      >
        <v-spacer></v-spacer
        ><v-btn text @click="dateModal = false">{{
          $t("introduction.done")
        }}</v-btn></v-toolbar
      >
      <smooth-picker
        v-if="mounted"
        ref="smoothPicker"
        :data="data"
        :change="dataChange"
      /> </v-sheet
  ></v-bottom-sheet>
</template>

<script>
import findIndex from "lodash/findIndex";
import moment from "moment";
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    const nowYear = moment().get("year");
    const years = [];
    for (let i = 1900; i < nowYear - 3; i++) {
      years.push(i);
    }
    return {
      mounted: false,
      dateModal: false,

      data: [
        {
          currentIndex: this.$props.value
            ? parseInt(this.$props.value.split(".")[0]) - 1
            : 1,
          flex: 3,
          list: [...Array(31)].map((d, i) => i + 1),
          textAlign: "center",
          className: "item-group",
        },

        {
          currentIndex: moment(this.$props.value, "DD. MMMM YYYY").get("month"),
          flex: 6,
          list: this.$t("full_monthName_list").split(","),
          textAlign: "center",
          className: "row-group",
        },
        {
          currentIndex:
            findIndex(years, (year) => {
              return (
                year === moment(this.$props.value, "DD. MMMM YYYY").get("year")
              );
            }) > 0
              ? findIndex(years, (year) => {
                  return (
                    year ===
                    moment(this.$props.value, "DD. MMMM YYYY").get("year")
                  );
                })
              : parseInt((nowYear - 1900) / 2),
          flex: 3,
          list: years,
          textAlign: "center",
          className: "row-group",
        },
      ],
    };
  },
  computed: {
    birthday() {
      return this.$props.value;
    },
  },
  methods: {
    dataChange(gIndex, iIndex) {
      const ciList = this.$refs.smoothPicker.getCurrentIndexList();

      let monthCount = 31;
      let month = this.data[1].list[ciList[1]];
      switch (month) {
        case 2:
          let selectedYear = this.data[0].list[ciList[0]]; // month
          if (gIndex === 0) {
            selectedYear = this.data[0].list[iIndex];
          }
          let isLeapYear = false;
          if (this.isLeapYear(selectedYear)) {
            isLeapYear = true;
          }
          monthCount = 28;
          if (isLeapYear) {
            monthCount = 29;
          }
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          monthCount = 30;
          break;
        default:
          monthCount = 31;
      }
      const list = [...Array(monthCount)].map((d, i) => i + 1);
      const year = this.data[2].list[ciList[2]];
      const day = this.data[0].list[ciList[0]];
      this.$emit("date-changed", day + "." + month + " " + year);
    },
  },
  watch: {
    dateModal(val) {
      if (val === true) {
        this.$nextTick(() => {
          this.mounted = true;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.smooth-picker {
  background-color: transparent !important;
  .smooth-handle-layer .smooth-top,
  .smooth-bottom {
    background: transparent !important;
  }
}
.noDialog {
  border-radius: 10px !important;
  padding: 0 !important;
}
</style>
