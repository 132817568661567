export default {
  FETCH_FAVORITES: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_FAVORITES: (state, value) => {
    state.status = "success";
    state.items = value;
  },
  FAILURE_FETCH_FAVORITES: (state) => {
    state.status = "failure";
  },
  TOGGLE_FAVORITE: (state, favorite) => {
    state.items.splice(state.items.indexOf(favorite), 1);
  },
};
