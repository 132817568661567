import api from "@/api";

export default {
  async parseCSV(
    { commit, rootState },
    { file, lang = rootState.languages.currentLang.id }
  ) {
    commit("FETCH_CSV");
    let schema = file.name.slice(0, -4); //strip '.csv' from name
    const reader = new FileReader();
    try {
      reader.onload = async function (e) {
        commit(
          "SUCCESS_FETCH_CSV",
          await api.parseCSV(e.target.result, schema, lang)
        );
      };
      reader.readAsText(file);
    } catch (error) {
      commit("FAILURE_FETCH_CSV", error);
    }
  },
};
