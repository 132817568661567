export default {
  FETCH_PARTNER_CONFIG: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_PARTNER_CONFIG: (state, value) => {
    state.status = "success";
    state.config = value;
  },
  FAILURE_FETCH_PARTNER_CONFIG: (state) => {
    state.status = "failure";
  },
};
