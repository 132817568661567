export default () => ({
  taxi: {
    id: null,
    header: "Taxi2Airport",
    subHeader: "Book Your Airport Taxi Transfer",
    location: {},
    category: {
      _fl_meta_: {
        fl_id: "0FZ9vzGWwRSGQ6W34IKH",
      },
      id: "0FZ9vzGWwRSGQ6W34IKH",
      title: "Mobility",
      color: "#9766ff",
      image:
        "https://firebasestorage.googleapis.com/v0/b/wowapp-b59fa.appspot.com/o/flamelink%2Fmedia%2FTransportation_200x200.png?alt=media",
      pictogram:
        "https://firebasestorage.googleapis.com/v0/b/wowapp-b59fa.appspot.com/o/flamelink%2Fmedia%2Ftransportation_200x200.png?alt=media",
      size: "122",
      show: true,
      selected: false,
      callToActionLabel: "Book a car/bike",
      callToActionLabel2: "Buy ticket",
    },
    clicked: 0,
    subCategory: [],
    weatherConditions: "",
    priceSegment: 0,
    userRating: 0,
    conciergeRating: 0,
    conciergeReview: "",
    detailedDescription: "",
    affiliateImage: require("@/assets/img/taxi2.jpeg"),
    affiliatePreview: "",
    imageGallery: [],
    thumbnail: {},
    contactPerson: "",
    phone: "",
    whatsApp: "",
    email: "",
    openingHours: [],
    source: "static",
    ratings: 0,
    callToAction: "https://poco.transferz.com/start",
    callToAction2: "",
    new: false,
    insider: false,
    highlight: false,
    specialDeals: false,
    geoHash: "",
    addressLine1: "",
    addressLine2: "",
    streetNumber: "",
    zipCode: "",
    price: "",
    moods: "",
    wowId: "",
    countryId: "",
    openHours: "",
    distance: 28,
    promotions: [
      {
        text: "20% Discount",
      },
    ],
    _fl_meta_: "",
  },
});
