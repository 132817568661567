export default () => ({
  user: void 0,
  createStatus: "",
  signinStatus: "",
  reloadAfterSignIn: false,
  provider: void 0,
  status: "init",
  resetStatus: "",
  error: false,
  cityStatus: "",
  currentCity: void 0,
  askingDone: false,
  showIntroduction: false,
  lastLogin: void 0,
  deleteStatus: "",
  address: "",
  locationError: false,
  pageYOffset: undefined,
  rendered: 25,
  selectedSegment: -1,
  appVersion: "1.1.21",
  packageVersion: "1.1.21",
  buildVersion: "1.1.21.0",
});
