<template>
  <v-app :style="colorFilter">
    <v-window
      v-model="$store.state.currentWindow"
      style="height: 100%; padding-bottom: env(safe-area-inset-bottom)"
      :style="
        $route.name != 'detail' && $route.name != 'ticketdetail'
          ? 'padding-top: env(safe-area-inset-top);'
          : '' + $vuetify.breakpoint.lgAndUp
          ? 'width: 100%; margin-left: auto; margin-right: auto'
          : ''
      "
      touchless
    >
      <v-window-item
        :key="0"
        style="height: 100%"
        class="padding-bottom-custom"
      >
        <template v-if="iFrameError">
          <error-image
            class="mt-12"
            :text="$t('dashboard.itemUnavailable')"
            imageName="norecommendation.svg"
          ></error-image>
          <v-container class="text-center">
            <v-btn class="gradient" height="48" min-width="150" dark>{{
              $t("profile.ok")
            }}</v-btn>
          </v-container>
          <bottom-navigation-buttons
            style="margin-bottom: env(safe-area-inset-bottom)"
            v-if="!loading && showBottomNav"
          ></bottom-navigation-buttons>
        </template>
        <template v-else>
          <!--
          <iframe
            width="100%"
            height="100%"
            src="https://poco.betsnap.com/b2b/login/initialize"
          ></iframe>-->
          <v-btn
            fixed
            bottom
            right
            fab
            class="gradient"
            style="
              margin-right: -28px !important;
              margin-bottom: 64px !important;
              overflow: hidden !important;
            "
            @click="$store.commit('SET_CURRENT_WINDOW', 1)"
            ><v-img
              :src="require('@/assets/logo_white.png')"
              height="25"
              width="25"
              contain
            ></v-img
          ></v-btn>
        </template>
      </v-window-item>
      <v-window-item :key="1">
        <ad-dialog></ad-dialog>
        <ad-banner></ad-banner>
        <v-dialog fullscreen v-model="showVerifyEmail" :timeout="-1">
          <v-card flat class="pa-0"
            ><v-row dense class="ma-0">
              <v-col cols="12">
                <v-card flat style="background: transparent">
                  <v-card-title
                    class="HeadingWhiteH2-28Center mt-12 d-block"
                    style="word-break: break-word"
                    :style="
                      $store.state.settings.dark
                        ? 'color: #fff'
                        : 'color:#202224'
                    "
                  >
                    {{ $t("login.confirmEmailHeader") }}
                  </v-card-title>
                  <v-card-text
                    class="Text-Dark-Text-1---18-Center mt-12"
                    :style="
                      $store.state.settings.dark
                        ? 'color: #fff'
                        : 'color:#202224'
                    "
                  >
                    {{ $t("login.pleaseConfimEmailText") }}
                  </v-card-text>
                  <v-card-actions class="mt-6">
                    <v-btn
                      text
                      block
                      style="justify-content: flex-end"
                      class="Text-Color-Text-3---14-Right-Align"
                      @click="$store.dispatch('session/sendVerificationEmail')"
                    >
                      {{ $t("login.resendVerificationEmail") }}
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions class="mt-n2">
                    <v-btn
                      height="48"
                      block
                      depressed
                      color="primary"
                      :loading="reloadingUser"
                      class="gradient Heading-White-H4-18-Center"
                      @click="(reloadingUser = true), checkEmailVerified()"
                      >{{ $t("login.ok") }}</v-btn
                    >
                  </v-card-actions>
                  <v-card-actions class="mt-2">
                    <v-btn
                      class="Heading-Color-H4-18-Center mx-auto d-block"
                      text
                      min-height="40px"
                      @click="logout()"
                    >
                      {{ $t("profile.cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-dialog
          fullscreen
          v-if="!isMiKY"
          v-model="locationError"
          :timeout="-1"
        >
          <v-card style="border-radius: 20px !important" flat class="pa-0">
            <v-toolbar flat dense>
              <v-spacer></v-spacer>
              <v-btn
                icon
                color="onbackground"
                @click="$store.commit('session/SET_LOCATION_ERROR', false)"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-toolbar>
            <error-image
              height="calc(calc(100vh - 277px) - env(safe-area-inset-top))"
              :text="$t('dashboard.locationNotFound')"
              imageName="error.png"
            ></error-image>

            <v-container class="text-center">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    class="gradient"
                    height="48"
                    min-width="150"
                    @click="$store.commit('session/SET_LOCATION_ERROR', false)"
                    >{{ $t("profile.ok") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <introduction-dialog
          v-if="
            $store.state.profile.data &&
            !loading &&
            !showVerifyEmail &&
            !locationError
          "
        ></introduction-dialog>
        <main-app-bar v-if="!loading"></main-app-bar>
        <div
          v-if="$route.name == 'home' && isMiKY && !loading"
          class="colored-header gradient"
        ></div>
        <v-navigation-drawer
          v-if="
            $route.name == 'home' && !$store.state.dashboard.openAdresspicker
          "
          app
          left
          clipped
          :permanent="$vuetify.breakpoint.lgAndUp"
          v-model="navigation"
          :style="{
            top: isMiKY ? '80px' : '64px',
            height: isMiKY
              ? $store.state.partners.config.design_options.show_logo_title
                ? '350px'
                : '250px'
              : 'calc(calc(100vh - 120px) - env(safe-area-inset-top))',
          }"
          style="
            border-radius: 0px 30px 30px 0px;
            margin-top: env(safe-area-inset-top);
            margin-bottom: env(safe-area-inset-bottom);
          "
        >
          <v-img
            :src="require('@/assets/navigation/background.png')"
            style="position: absolute; z-index: -1"
            contain
            height="100%"
            width="100%"
          ></v-img>
          <v-toolbar dense flat style="background: transparent">
            <v-spacer></v-spacer>
            <v-btn
              v-if="!$vuetify.breakpoint.lgAndUp"
              icon
              color="onbackground"
              @click="$store.commit('SET_MENU', false)"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-toolbar>
          <div v-if="!isMiKY" style="position: sticky; top: 36px">
            <template
              v-if="$store.state.profile.data && $store.state.session.user"
            >
              <v-img
                :src="profileImage"
                width="110px"
                height="110px"
                style="border-radius: 100%"
                class="d-block mx-auto mt-n10"
                @click="$router.safePush({ name: 'profile' })"
              ></v-img>
              <div class="HeadingDarkH3-22Center mt-2 mb-0">
                {{ fullName }}
              </div>
            </template>
            <template v-else>
              <v-img
                :src="require('@/assets/avatar.png')"
                width="110px"
                height="110px"
                style="border-radius: 100%"
                class="d-block mx-auto mt-n10"
                @click="$router.safePush({ name: 'profile' })"
              ></v-img>
              <h3 class="HeadingDarkH3-22Center mt-2 mb-0">
                {{ $t("profile.yourName") }}
              </h3>
            </template>
          </div>
          <v-list v-if="!isMiKY" class="mt-6 px-4 pt-1 pb-0 nav-items">
            <template v-for="(item, index) in $store.state.menu.navigation">
              <v-list-item
                v-if="!item.userRequired || $store.state.session.user"
                :key="index"
                :to="{ name: item.title, params: item.params }"
              >
                <v-list-item-icon class="mr-2 mt-3">
                  <v-img
                    class="nav-icon"
                    :class="$vuetify.theme.dark ? 'invert' : ''"
                    :src="require('@/assets/navigation/' + `${item.icon}`)"
                  />
                </v-list-item-icon>
                <v-list-item-content class="mt-n1">
                  <v-list-item-title class="Text-Dark-Text-1---18-Left-Align">{{
                    $t(`menu.${item.title}`)
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="mt-1">
                  <v-icon large color="onnavigation">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <v-list-item
              v-if="
                $store.state.profile.data &&
                $store.state.profile.data.admin === true
              "
              :to="{ name: 'upload' }"
              ><v-list-item-icon class="mr-2">
                <v-icon color="onnavigation">mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="Text-Dark-Text-1---18-Left-Align">{{
                  $t("upload.csvUpload")
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon class="mt-1">
                <v-icon large color="onnavigation">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <v-list v-else class="px-4 mt-n4 nav-items">
            <template
              v-for="(item, index) in $store.state.menu.widgetnavigation"
            >
              <v-list-item
                :key="index"
                :to="{ name: item.name, params: item.params }"
              >
                <v-list-item-icon class="mr-2 mt-3">
                  <v-img
                    class="nav-icon"
                    :class="$vuetify.theme.dark ? 'invert' : ''"
                    :src="require('@/assets/navigation/' + `${item.icon}`)"
                  />
                </v-list-item-icon>
                <v-list-item-content class="mt-n1">
                  <v-list-item-title class="Text-Dark-Text-1---18-Left-Align">{{
                    $t(`menu.${item.name}`)
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="mt-1">
                  <v-icon large color="onnavigation">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list>
          <div v-if="!isMiKY">
            <div
              v-if="$store.state.session.user"
              class="px-8 text-right"
              style="position: sticky; bottom: 0; right: 0"
            >
              <v-spacer></v-spacer>
              <v-btn icon @click="$store.commit('profile/SET_DIALOG', true)"
                ><v-icon color="onnavigation" large>mdi-logout</v-icon>
                <span
                  color="onnavigation"
                  style="position: absolute; top: 25px"
                  >{{ $t("menu.logout") }}</span
                >
              </v-btn>
            </div>
            <v-card-actions
              v-else
              class="px-8 my-5 py-0 justify-space-between"
              style="position: sticky; bottom: 0"
              height="100%"
              background-color="background"
            >
              <v-btn
                dark
                depressed
                class="gradient Heading-White-H4-18-Center"
                @click="$router.safePush({ name: 'login' })"
                >{{ $t("login.signIn") }}</v-btn
              >
              <v-btn
                dark
                depressed
                class="gradient Heading-White-H4-18-Center"
                @click="$router.safePush({ name: 'register' })"
                >{{ $t("login.join") }}</v-btn
              >
            </v-card-actions>
          </div>
        </v-navigation-drawer>
        <leaving-dialog @confirm="logout()"></leaving-dialog>
        <v-main
          style="position: absolute; top: 0; left: 0; height: 100%; width: 100%"
          :style="$store.state.notificationOverlay ? 'overflow:hidden' : ''"
        >
          <loading
            v-if="loading || $store.state.map.status === 'fetching'"
          ></loading>
          <router-view
            v-else
            style="overflow: hidden"
            :style="
              $router.currentRoute.name != 'detail' &&
              $router.currentRoute.name != 'map' &&
              $router.currentRoute.name != 'travelType'
                ? 'padding-bottom: 74px'
                : ''
            "
          ></router-view>
          <div v-if="isMiKY && $router.currentRoute.name === 'home'">
            <v-card flat style="background: none !important">
              <v-card-title
                v-if="$vuetify.breakpoint.xs"
                class="Text-Dark-Text-4---12-Center"
              >
                These Experiences are brought to you by POCO
              </v-card-title>
              <v-card-title
                v-if="$vuetify.breakpoint.smAndUp"
                class="Text-Dark-Text-2---16-Center"
              >
                These Experiences are brought to you by POCO
              </v-card-title>
            </v-card>
          </div>
        </v-main>
        <bottom-navigation-buttons
          v-if="!loading && showBottomNav"
        ></bottom-navigation-buttons>
        <new-content-snack-bar v-if="!isMiKY"></new-content-snack-bar>
        <!--<v-btn
          fixed
          v-if="!loading && $store.state.session.user && $route.name === 'home'"
          bottom
          left
          fab
          class="gradient"
          style="margin-left: -38px !important; margin-bottom: 64px !important"
          @click="goToBetsnaps()"
          ><v-img
            class="ml-3"
            height="59"
            width="34"
            contain
            :src="require('@/assets/bottomNavigation/betsnaps.svg')"
          ></v-img
        ></v-btn>-->
        <notification
          v-if="$store.state.settings.notifications && todaysBookings.length"
          :croppedDate="croppedDate"
          :todaysBookings="todaysBookings"
        ></notification>
      </v-window-item>
    </v-window>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import find from "lodash/find";
import MainAppBar from "@/components/app/MainAppBar";
import NewContentSnackBar from "@/components/app/NewContentSnackBar";
import BottomNavigationButtons from "@/components/app/BottomNavigationButtons";
import DownloadAppSnackBar from "@/components/app/DownloadAppSnackBar";
import AdDialog from "@/components/app/AdDialog";
import AdBanner from "@/components/app/AdBanner";
import IntroductionDialog from "@/components/profile/IntroductionDialog";
import LeavingDialog from "@/components/profile/LeavingDialog";
import Notification from "@/components/app/Notification";
import filter from "lodash/filter";
import { browserName, osVersion } from "mobile-device-detect";
import "./assets/css/style.scss";
import firebase from "firebase/app";
import "firebase/firestore";
import ErrorImage from "@/components/app/common/ErrorImage";
import moment from "moment";
import Loading from "@/components/app/common/Loading";
export default {
  name: "App",
  components: {
    MainAppBar,
    NewContentSnackBar,
    BottomNavigationButtons,
    AdDialog,
    IntroductionDialog,
    AdBanner,
    LeavingDialog,
    ErrorImage,
    Notification,
    Loading,
  },
  data: () => {
    return {
      iFrameError: false, //TODO: check on how to toggle error
      searchRadius: 20,
      colorFilter: null,
      windowHeight: window.innerHeight,
      keyboardOpen: false,
      reloadingUser: false,
    };
  },
  computed: {
    ...mapState({
      locationError: (state) => state.session.locationError,
    }),
    ...mapState({
      data: (state) => state.profile.data,
    }),
    ...mapGetters("profile", ["todaysBookings"]),
    showVerifyEmail() {
      if (
        this.$store.state.session.user &&
        this.$store.state.session.user !== "signed out"
      ) {
        return !this.$store.state.session.user.emailVerified;
      } else {
        return false;
      }
    },
    isMiKY() {
      return this.$store.state.isMiKY;
    },
    isWeb() {
      return !window.cordova;
    },
    isNative() {
      return (
        document.URL.indexOf("http://") === -1 &&
        document.URL.indexOf("https://") === -1
      );
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    navigation: {
      get() {
        return this.$store.state.navigation;
      },
      set(val) {
        this.$store.commit("SET_MENU", val);
      },
    },
    categories() {
      return this.$store.state.recommendations.categoryList;
    },
    isFilterSelected() {
      var selectedFilters = this.categories.filter(function (filter) {
        return !filter.selected;
      });

      if (selectedFilters.length === this.categories.length) return false;
      return true;
    },
    loading() {
      const isInitializing =
        this.$store.state.session.status === "init" ||
        this.$store.state.session.status === "";

      // Showing the loading screen until the partners config is loaded.
      const isLoadingPartnerConfig =
        this.$store.state.isMiKY &&
        this.$store.state.partners.status !== "success" &&
        this.$store.state.partners.status !== "failure";

      // Signing out reloads poco after a little delay.
      // Showing the loading screen in the meantime.
      const isSignedOut = this.$store.state.session.user === "signed out";

      // Login is in progress.
      const isLoggingIn = this.$store.state.session.signinStatus === "fetching";

      const wasUserLoggedIn = window?.localStorage?.getItem("user") === "true";
      const isUserLoggedIn = !!this.$store.state.session.user;

      return (
        !this.showVerifyEmail &&
        (isInitializing ||
          isLoadingPartnerConfig ||
          isSignedOut ||
          isLoggingIn ||
          (wasUserLoggedIn && !isUserLoggedIn))
      );
    },
    fullName() {
      let fullname =
        this.$store.state.profile.data.alias ||
        this.$store.state.profile.data.firstName +
          " " +
          this.$store.state.profile.data.lastName;
      fullname =
        fullname.length > 16 ? fullname.slice(0, 16) + "..." : fullname;
      return fullname;
    },
    showBottomNav() {
      if (this.isMiKY || this.keyboardOpen) {
        return false;
      }
      switch (this.$route.name) {
        case "detail":
        case "profile":
        case "auth":
          return false;
        default:
          return true;
      }
    },
    profileImage() {
      if (this.$store.state.profile.data.profilePicture) {
        return this.$store.state.profile.data.profilePicture;
      } else {
        /* Default profile picture */
        return require("@/assets/avatar.png");
      }
    },
    croppedDate() {
      let date = moment().format("ll").split(",")[0];
      return date.length == 5 ? date.substring(0, 3) + " 0" + (5, 6) : date;
    },
  },
  methods: {
    ...mapActions(["calculateMediaHeight"]),
    updateRadius() {
      this.$store.commit("events/SET_RADIUS", this.searchRadius);
      this.$store.commit("recommendations/SET_RADIUS", this.searchRadius);
      this.$store.commit("activities/SET_RADIUS", this.searchRadius);
    },
    logout() {
      this.$store.dispatch("session/signout");
    },
    async goToBetsnaps() {
      await firebase
        .firestore()
        .collection("fl_redirects")
        .doc("ExlxXOzWUKFPjQsjW7lX")
        .set({ userId: this.$store.state.profile.data.id });
      this.$store.commit("SET_CURRENT_WINDOW", 0);
    },
    async checkEmailVerified() {
      await this.$store.dispatch("session/reloadUser");
      setTimeout(() => {
        this.reloadingUser = false;
      }, 1000);
    },
    hexToHsl(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      var r = parseInt(result[1], 16);
      var g = parseInt(result[2], 16);
      var b = parseInt(result[3], 16);

      (r /= 255), (g /= 255), (b /= 255);
      var max = Math.max(r, g, b),
        min = Math.min(r, g, b);
      var h,
        s,
        l = (max + min) / 2;

      if (max == min) {
        h = s = 0;
      } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }
      s = s * 100;
      s = Math.round(s);
      l = l * 100;
      l = Math.round(l);
      h = Math.round(360 * h);

      return { h: h, s: s, l: l };
    },
    hslToHex(hsl) {
      let h = hsl.h;
      let s = hsl.s;
      let l = hsl.l;
      l /= 100;
      const a = (s * Math.min(l, 1 - l)) / 100;
      const f = (n) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
          .toString(16)
          .padStart(2, "0"); // convert to Hex and prefix "0" if needed
      };
      return `#${f(0)}${f(8)}${f(4)}`;
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  mounted() {
    this.calculateMediaHeight({ breakpoint: this.$vuetify.breakpoint });
    /* The if condition is a workaround for Cordova app bug where bottom navigation would be above the bottom of the screen and not fixed to it because of min-height: 100% */
    if (browserName !== "WebKit") {
      const wrapper = document.querySelector(".v-application--wrap");
      if (wrapper) wrapper.style.minHeight = "100%";
    } else {
      /* Disable scrollbar in iOS app */
      const wrapper = document.querySelector(".v-application--wrap");
      /* Scrollbar will only be disabled on iOS 13 or higher because scrolling becomes laggy on earlier iOS versions */
      if (wrapper && osVersion >= "13") wrapper.style.overflowY = "scroll";
    }

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      if (newHeight + 150 < oldHeight) {
        this.keyboardOpen = true;
      } else {
        this.keyboardOpen = false;
      }
    },
    "$route.name"(val) {
      /* clear all error messages on route change */
      this.$store.commit("session/CLEAR_AUTH_ERROR");
    },
    /* Change background color according to dark mode */
    "$store.state.settings.dark"(val) {
      this.$vuetify.theme.dark = val;
      const wrapper = document.querySelector("html");
      if (wrapper)
        val
          ? (wrapper.style.backgroundColor = "#121212")
          : (wrapper.style.backgroundColor = "rgb(255, 255, 255)");
    },
    categories: {
      handler(newVal) {
        this.$store.commit(
          "recommendations/SET_CAT_FILTER",
          filter(newVal, { selected: true })
        );
      },
      deep: true,
    },
    "$store.state.partners.status"(val) {
      // Load partner config is it's an iFrame.
      if (this.isMiKY) {
        if (this.$store.state.partners.status === "success") {
          /**************************************************/
          /*               1. D E S I G N                   */
          /**************************************************/
          let design = this.$store.state.partners.config.design_options
            .color_scheme[0];
          if (design) {
            // ----- G R A D I E N T -----
            // This should make the gradient look similar after the color change.
            let designPrimary = this.hexToHsl(design.primary_color.hex);

            // The difference to the middle part of the gradient in the original design.
            const diffToGradientMiddle = { h: -4, s: -31, l: 15 };
            // The difference to the end of the gradient in the original design.
            const diffToGradientEnd = { h: -11, s: -46, l: 23 };

            // Adding the difference to make the new gradient look similar.
            let designGradientMiddleHsl = {
              h: designPrimary.h + diffToGradientMiddle.h,
              s: designPrimary.s + diffToGradientMiddle.s,
              l: designPrimary.l + diffToGradientMiddle.l,
            };

            // Adding the difference to make the new gradient look similar.
            let designGradientEndHsl = {
              h: designPrimary.h + diffToGradientEnd.h,
              s: designPrimary.s + diffToGradientEnd.s,
              l: designPrimary.l + diffToGradientEnd.l,
            };

            this.$vuetify.theme.themes.light.primary = design.primary_color.hex;
            this.$vuetify.theme.themes.light.primary_gradient_middle = this.hslToHex(
              designGradientMiddleHsl
            );
            this.$vuetify.theme.themes.light.primary_gradient_end = this.hslToHex(
              designGradientEndHsl
            );

            // ----- T E X T   C O L O R -----
            // Determining the overall intensity of the color
            // according to W3C guidelines to pick the right text color
            let rgb = design.primary_color.rgba;
            let r = rgb.r / 255;
            let g = rgb.g / 255;
            let b = rgb.b / 255;
            // https://www.w3.org/TR/WCAG20/#relativeluminancedef
            r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
            g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
            b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
            // https://en.wikipedia.org/wiki/Luma_(video)#Rec._601_luma_versus_Rec._709_luma_coefficients
            let L = 0.2126 * r + 0.7152 * g + 0.0722 * b;
            // https://www.w3.org/TR/WCAG20/#contrast-ratiodef
            // Determining the contrast ratios
            let contrastOfBlack = (L + 0.05) / (0.0 + 0.05);
            let contrastOfWhite = (1.0 + 0.05) / (L + 0.05);
            if (contrastOfBlack > contrastOfWhite) {
              this.$vuetify.theme.themes.light.onprimary = "#000000"; // black
            } else {
              this.$vuetify.theme.themes.light.onprimary = "#FFFFFF"; // white
            }

            // ----- C O L O R   F I L T E R   F O R   I M A G E S -----
            // Calculate the hue rotation to change the colors of the images.
            let baseHsl = { h: 198, s: 99, l: 46 };
            let targetHsl = design.primary_color.hsla;
            let h = targetHsl.h - baseHsl.h;
            let s = 100 + (targetHsl.s * 100 - baseHsl.s);
            let l = 100 + (targetHsl.l * 100 - baseHsl.l);
            this.colorFilter = `--hue-rotate: ${h}deg; --saturate: ${s}%; --brightness: ${l}%; }`;
          }
          /**************************************************/
          /*               2. L O C A T I O N               */
          /**************************************************/
          let location = {
            lat: Number(
              this.$store.state.partners.config.filter_settings.geo_location.lat
            ),
            lng: Number(
              this.$store.state.partners.config.filter_settings.geo_location.lng
            ),
          };

          if (
            this.$router.currentRoute.query.lat &&
            this.$router.currentRoute.query.lng
          ) {
            location.lat = Number(this.$router.currentRoute.query.lat);
            location.lng = Number(this.$router.currentRoute.query.lng);
          }

          this.$store.dispatch("session/setOriginalPosition", location);

          /**************************************************/
          /*                  3. E M A I L                  */
          /**************************************************/
          if (!this.$store.state.session.user) {
            let profile = this.$store.state.profile.data;
            profile.email = this.$router.currentRoute.query.email;
            profile.firstName = this.$router.currentRoute.query.firstName;
            profile.lastName = this.$router.currentRoute.query.lastName;
            profile.phone = this.$router.currentRoute.query.phone;
            this.$store.dispatch("profile/updateProfile", {
              profile: profile,
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
/* Fix inconsistent map height on different devices */
html,
body,
#app {
  width: 100%;
  height: 100%;
}

.v-bottom-navigation {
  height: auto !important;
}

.v-bottom-navigation .v-btn--contained {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: env(safe-area-inset-bottom);
}

#kommunicate-widget-iframe {
  bottom: -100px;
}

a {
  text-decoration: none;
}

.image-action {
  background-color: rgba(96, 96, 96, 0.7) !important;
}

.image-bg {
  background-color: var(--v-background-base) !important;
}
.nav-icon .v-image__image {
  width: 20px;
  height: 20px;
}
.v-navigation-drawer .nav-items a {
  max-height: 20px;
}

.v-list.nav-items {
  max-height: max(
    calc(calc(100vh - 358px) - env(safe-area-inset-top)),
    210px
  ) !important;
  overflow: auto !important;
}
</style>
<style>
.color-filter {
  filter: hue-rotate(var(--hue-rotate)) saturate(var(--saturate))
    brightness(var(--brightness));
}
.colored-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 140px;
  z-index: 0;
}
</style>
