import api from "@/api";
export default {
  async getProfile(
    { commit, dispatch },
    {
      userId = "fOeO0WQ0xza1Nw0Lpbip",
      userData,
      firstName = "",
      lastName = "",
      phone = "",
    }
  ) {
    commit("REQUEST_PROFILE");
    commit("SET_LOADING", true, { root: true });
    try {
      // This api call will create a new user in the poco database, if necessary.
      const user = await api.getProfile(
        userId,
        userData,
        firstName,
        lastName,
        phone
      );
      commit("SHOW_INTEREST_PRESELECTION", user.interestsSelected === true);
      commit("SUCCESS_REQUEST_PROFILE", user);
      dispatch("favorites/getFavorites", { userId }, { root: true });
    } catch (e) {
      commit("FAILURE_REQUEST_PROFILE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async userExists({ commit }, { email }) {
    try {
      await api.userExists(email);
      commit("SUCCESS_USER_EXISTS");
    } catch (e) {
      commit("FAILURE_USER_EXISTS");
    }
  },
  async updateProfile(
    { commit, dispatch, state },
    { userId = state.data.id, profile }
  ) {
    commit("SET_LOADING", true, { root: true });
    commit("REQUEST_UPDATE_PROFILE");
    try {
      commit(
        "SUCCESS_UPDATE_PROFILE",
        await api.updateProfile(userId, profile)
      );
      dispatch("getProfile", { userId });
    } catch (e) {
      commit("FAILURE_UPDATE_PROFILE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async addTicket({ commit, dispatch, state }, { ticket }) {
    commit("ADD_TICKET", ticket);
    dispatch("updateProfile", { profile: state.data });
  },
  async removeTicket({ commit }, { id }) {
    commit("REMOVE_TICKET", id);
  },
  async addReservation({ commit, dispatch, state }, { reserved }) {
    commit("ADD_RESERVATION", { ...reserved, notified: false });
    dispatch(
      "profile/updateProfile",
      {
        profile: state.data,
      },
      { root: true }
    );
  },
  async removeReservation({ commit }, { visitId }) {
    commit("REMOVE_RESERVATION", visitId);
  },
  async cancelReservation({ commit, dispatch, state }, { visitId }) {
    commit("CANCEL_RESERVATION", visitId);
    dispatch(
      "profile/updateProfile",
      {
        profile: state.data,
      },
      { root: true }
    );
  },
  async setPOCORating({ commit, dispatch, state }, { rating, comment }) {
    commit(
      "SET_POCO_RATING",
      { rating, comment },
      await api.sendAppRating(rating, comment)
    );
    dispatch("updateProfile", { profile: state.data });
  },
  async removeActivityHistoryItem({ commit, state }, { visitId }) {
    try {
      await api.removeActivityHistoryItem(state.data.id, visitId);
      commit("REMOVE_ACTIVITY_HISTORY_ITEM", visitId);
    } catch (error) {
      // Nothing yet.
    }
  },
};
