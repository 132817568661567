import uniqBy from "lodash/uniqBy";

export default {
  REQUEST_LOCATION: (state) => {
    state.status = "fetching";
  },
  SUCCESS_REQUEST_LOCATIONS: (state, value) => {
    state.status = "success";
    state.items = value;
  },
  FAILURE_REQUEST_LOCATIONS: (state) => {
    state.status = "failure";
    state.items = [];
  },
  REQUEST_MORE_LOCATIONS: (state) => {
    state.moreStatus = "fetching";
  },
  SUCCESS_REQUEST_MORE_LOCATIONS: (state, value) => {
    state.moreStatus = "success";
    state.items = state.items.concat(value);
  },
  FAILURE_REQUEST_MORE_LOCATIONS: (state) => {
    state.moreStatus = "failure";
  },
  SET_ITEM_CATEGORY: (state, value) => {
    state.items[value.index].category = value.category;
  },
  SET_FILTER: (state, value = "") => {
    value = value || "";
    state.filter = value.toLowerCase();
  },
  SET_CAT_FILTER: (state, value) => {
    state.catFilter = value;
  },
  SET_CATEGORY_LIST: (state, value) => {
    state.categoryList = value;
  },
  SET_RADIUS: (state, value) => {
    state.radius = value;
  },
  SET_CAPACITY_REACHED: (state, value) => {
    state.capacityReached = value;
  },
  SET_SELECTED_CATEGORY: (state, value) => {
    state.selectedCategory = value;
  },
  SET_SELECTED_MOOD: (state, value) => {
    state.selectedMood = value;
  },
  FETCH_HIGHLIGHTS: (state) => {
    state.highlightsStatus = "fetching";
  },
  SUCCESS_FETCH_HIGHLIGHTS: (state, value) => {
    state.highlightsStatus = "success";
    state.highlights = value;
  },
  FAILURE_FETCH_HIGHLIGHTS: (state) => {
    state.highlightsStatus = "failure";
  },
  FETCH_POPULAR: (state) => {
    state.popularStatus = "fetching";
  },
  SUCCESS_FETCH_POPULAR: (state, value) => {
    state.popularStatus = "success";
    state.popular = value;
  },
  FAILURE_FETCH_POPULAR: (state) => {
    state.popularStatus = "failure";
  },
  FETCH_JUSTFORYOU: (state) => {
    state.justForYouStatus = "fetching";
  },
  SUCCESS_FETCH_JUSTFORYOU: (state, value) => {
    state.justForYouStatus = "success";
    state.justForYou = value;
  },
  FAILURE_FETCH_JUSTFORYOU: (state) => {
    state.justForYouStatus = "failure";
  },
  FETCH_LOCATIONS: (state) => {
    state.locationsStatus = "fetching";
  },
  SUCCESS_FETCH_LOCATIONS: (state, value) => {
    state.locationsStatus = "success";
    state.locations = value;
  },
  FAILURE_FETCH_LOCATIONS: (state) => {
    state.locationsStatus = "failure";
  },
  FETCH_DETAILS: (state) => {
    state.detailStatus = "fetching";
  },
  SUCCESS_FETCH_DETAILS: (state, value) => {
    state.detailStatus = "success";
    state.details = value;
  },
  FAILURE_FETCH_DETAILS: (state) => {
    state.detailStatus = "failure";
  },
  FETCH_ALL: (state) => {
    state.allStatus = "fetching";
  },
  SUCCESS_FETCH_ALL: (state, value) => {
    state.allStatus = "success";
    state.allItems = value;
  },
  FAILURE_FETCH_ALL: (state) => {
    state.allStatus = "failure";
  },
  UPDATE_DETAILS: (state) => {
    state.updateStatus = "fetching";
  },
  SUCCESS_UPDATE_DETAILS: (state) => {
    state.updateStatus = "success";
  },
  FAILURE_UPDATE_DETAILS: (state) => {
    state.updateStatus = "failure";
  },
  FETCH_BYCATEGORY: (state) => {
    state.byCategoryStatus = "fetching";
  },
  SUCCESS_FETCH_BYCATEGORY: (state, value) => {
    state.byCategoryStatus = "success";
    state.byCategory = value;
    let subCats = [];
    state.byCategory.forEach((item) => {
      if (item.subCategory && item.subCategory.length > 0) {
        subCats = subCats.concat(item.subCategory);
      }
    });
    subCats = uniqBy(subCats, "title");
    state.subCategories = subCats;
  },
  FAILURE_FETCH_BYCATEGORY: (state) => {
    state.byCategoryStatus = "failure";
  },
  FETCH_BYMOOD: (state) => {
    state.byMoodStatus = "fetching";
  },
  SUCCESS_FETCH_BYMOOD: (state, value) => {
    state.byMoodStatus = "success";
    state.byMood = value;
  },
  FAILURE_FETCH_BYMOOD: (state) => {
    state.byMoodStatus = "failure";
  },
  FETCH_REVIEW: (state) => {
    state.reviewStatus = "fetching";
  },
  SUCCESS_FETCH_REVIEW: (state, value) => {
    state.reviewStatus = "success";
    state.details.ratings = value;
  },
  FAILURE_FETCH_REVIEW: (state) => {
    state.reviewStatus = "failure";
  },
  CLEAR_SUB_FILTER: (state) => {
    state.subCategories.forEach((item) => {
      item.selected = false;
    });
  },
  SET_MANUAL_LOCATION: (state, value) => {
    state.manualLocationChanged = value;
  },
};
