import Vue from "vue";
import store from "@/store";
import vuexI18n from "vuex-i18n";
import countries from "i18n-iso-countries";

const context = require.context("@/i18n/", false, /\.json$/);
var isos = [];
context.keys().map((key) => {
  const langKey = `${key.replace(".json", "").replace("./", "")}`;
  isos.push({
    obj: context(key),
    key: langKey,
  });
});

Vue.use(vuexI18n.plugin, store);
isos.forEach((item) => {
  Vue.i18n.add(item.key, item.obj);
  try {
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${item.key}.json`)
    );
  } catch (e) {
    // ignore
  }
});
Vue.use(vuexI18n.plugin, store);
Vue.i18n.set("en");
