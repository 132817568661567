import api from "@/api";
import router from "../../../router";
import intersectionWith from "lodash/intersectionWith";
import find from "lodash/find";

export default {
  async checkAvailability(
    { commit },
    { placeId, covers, date, time, header, countryId, promotionId, visitId }
  ) {
    commit("FETCH_AVAILABILITY");
    try {
      let available = await api.checkAvailability(
        placeId,
        covers,
        date,
        time,
        promotionId,
        visitId
      );
      commit("SUCCESS_FETCH_AVAILABILITY", available);
      commit("SET_PARAMS", {
        placeId,
        covers,
        date,
        time,
        header,
        countryId,
        visitId,
      });
      if (!promotionId) {
        router.safePush({
          name: "chooseArea",
        });
      }
    } catch (e) {
      console.log(e);
      commit("FAILURE_FETCH_AVAILABILITY", e.response.data);
    }
  },
  async postReservation(
    { commit, dispatch, state },
    {
      visitId,
      email,
      name,
      countryId,
      phone,
      placeId,
      recommendationId,
      areaId,
      covers,
      date,
      time,
      waitingList,
      promotionId,
      source,
      action,
      city,
    }
  ) {
    commit("RESERVE");
    try {
      let reserved = await api
        .postReservation(
          visitId,
          email,
          name,
          countryId,
          phone,
          placeId,
          areaId,
          covers,
          date,
          time,
          waitingList,
          promotionId
        )
        .then(
          null, // Promise resolved
          (error) => {
            // Promise rejected
            throw error;
          }
        )
        .catch((error) => {
          throw error;
        });
      if (visitId) {
        dispatch(
          "profile/removeReservation",
          { visitId: visitId },
          { root: true }
        );
      }
      reserved.recommendationId = recommendationId;
      if (promotionId) {
        // Add the booked promotion to booking
        await dispatch("getPromotions", { restaurantId: placeId });
        reserved.promotion = find(state.promotions, [
          "id",
          reserved.promotionId,
        ]);
        // Bugfix: Cannot save Array of Array in our database, so remove it.
        reserved.promotion.dates = null;
        reserved.source = source;
      }
      // Optimizing: Removing unnecessary data.
      reserved.restaurant = {
        countryId: reserved.restaurant.countryId,
        name: reserved.restaurant.name,
        id: reserved.restaurant.id,
        imageUrl: reserved.restaurant.imageUrl,
        jsonOperatingHours: reserved.restaurant.jsonOperatingHours,
        latitude: reserved.restaurant.latitude,
        longitude: reserved.restaurant.longitude,
        city,
      };
      if (promotionId) {
        reserved.promotion = {
          id: reserved.promotion.id,
          imageUrl: reserved.promotion.imageUrl,
          name: reserved.promotion.name,
          placeId: reserved.promotion.placeId,
          summary: reserved.promotion.summary,
        };
      }
      dispatch(
        "profile/addReservation",
        { reserved: reserved },
        { root: true }
      );

      if (action == "reserve") {
        dispatch(
          "recommendations/logReservation",
          {
            recommendationId: recommendationId,
            recommendationName: null,
            placeId: placeId,
            placeName: reserved.restaurant.name,
            persons: reserved.covers,
            bookingRef: reserved.code,
          },
          { root: true }
        );
      }
      commit("SUCCESS_RESERVE", reserved);
      commit("SHOW_BOOKING_CONFIRMATION", action === "reserve");
      if (action === "modify") {
        router.safePush({
          name: "bookings",
        });
      }
    } catch (error) {
      // console.error(error.response.data);
      commit("FAILURE_RESERVE", error.response.data);
    }
  },
  async cancelReservation({ commit, dispatch }, { visitId }) {
    commit("CANCEL");
    try {
      let cancelled = await api.cancelReservation(visitId);
      dispatch(
        "profile/cancelReservation",
        { visitId: visitId },
        { root: true }
      );
      commit("SUCCESS_CANCEL", cancelled);
    } catch (e) {
      commit("FAILURE_CANCEL", e.response.data);
    }
  },
  async getPromotions({ commit }, { restaurantId }) {
    commit("FETCH_PROMOTIONS");
    try {
      let promotions = await api.getPromotions(restaurantId);
      commit("SUCCESS_FETCH_PROMOTIONS", promotions);
    } catch (e) {
      commit("FAILURE_FETCH_PROMOTIONS", e.response.data);
    }
  },
  async getMenu({ commit }, { restaurantId }) {
    commit("FETCH_MENU");
    try {
      let menu = await api.getMenu(restaurantId);
      commit("SUCCESS_FETCH_MENU", menu.result);
    } catch (e) {
      commit("FAILURE_FETCH_MENU", e.response.data);
    }
  },
  async getRestaurantInfo({ commit }, { restaurantId }) {
    commit("FETCH_RESTAURANT_INFO");
    try {
      let restaurantInfo = await api.getRestaurantInfo(restaurantId);
      commit("SUCCESS_FETCH_RESTAURANT_INFO", restaurantInfo.result.restaurant);
    } catch (e) {
      commit("FAILURE_FETCH_RESTAURANT_INFO", e.response.data);
    }
  },
};
