<template>
  <v-overlay
    absolute
    :value="overlay"
    z-index="110"
    opacity="0.75"
    color="#09212b"
    style="position: fixed"
  >
    <div class="notification-feed">
      <v-card
        v-for="(booking, index) in todaysBookings"
        :key="index"
        flat
        class="mx-auto my-2 notification-card"
        :ripple="false"
        :style="!$store.state.settings.dark ? 'background-color: #fff;' : ''"
      >
        <v-row no-gutters>
          <div class="tail"></div>
          <v-col
            class="shrink gradient px-5 HeadingWhiteH2-28Center d-flex align-center"
            style="border-radius: 12px 12px 12px 0; z-index: 15"
          >
            <div class="py-5" style="width: 70px">{{ croppedDate }}</div>
          </v-col>
          <v-col
            class="grow px-0"
            :style="
              $store.state.settings.dark
                ? 'color: #fff !important;'
                : 'color:#000 !important;'
            "
          >
            <v-card-actions class="pa-1">
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                :color="$store.state.settings.dark ? 'white' : 'black'"
                @click="deleteNotification(booking)"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
            <v-card-title
              class="Heading-Dark-H4-18-Left-Align pt-0 pb-1"
              style="line-height: 1.3; word-break: break-word"
              >{{ booking.restaurant.name }}
            </v-card-title>
            <v-card-text class="Heading-Dark-H5-16-Center py-1">
              <div class="text-left">
                <img
                  width="24"
                  height="24"
                  :src="require('@/assets/reservation/time_active.svg')"
                  class="ml-0 mr-1"
                />
                <span style="vertical-align: super" class="mr-2"
                  >{{ booking.arrival[3] }}:{{
                    booking.arrival[4] >= 10
                      ? booking.arrival[4]
                      : "0" + booking.arrival[4]
                  }}</span
                >
                <img
                  width="24"
                  height="24"
                  :src="require('@/assets/reservation/people_active.svg')"
                  class="ml-0 mr-1"
                />

                <span style="vertical-align: super" class="mr-2"
                  >{{
                    booking.covers +
                    " " +
                    (booking.covers === 1
                      ? $t("reservation.guest")
                      : $t("reservation.guests"))
                  }}
                </span>
                <img
                  width="24"
                  height="24"
                  :src="require('@/assets/img/location.svg')"
                  class="ml-0"
                  @click="mapSelector(booking)"
                />
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-overlay>
</template>
<script>
import { isMobile, isIOS } from "mobile-device-detect";

export default {
  props: {
    todaysBookings: {
      type: Array,
      default: () => {
        return [];
      },
    },
    croppedDate: {
      type: String,
      default: "",
    },
  },
  computed: {
    overlay() {
      return this.todaysBookings && this.todaysBookings.length > 0;
    },
  },
  methods: {
    mapSelector(item) {
      /* If iOS --> open Apple Maps with set destination to the selected recommendation */
      if (isMobile && isIOS) {
        window.location.href = encodeURI(
          "maps://maps.apple.com/maps?daddr=" +
            item.restaurant.latitude +
            "," +
            item.restaurant.longitude +
            "&amp;ll="
        );
      } else if (isMobile) {
        window.open(
          "https://maps.google.com/maps?daddr=" +
            item.restaurant.latitude +
            "," +
            item.restaurant.longitude +
            "&amp;ll=",
          "_system"
        );
      } else {
        /* else use Google Maps */
        window.open(
          "https://maps.google.com/maps?daddr=" +
            item.restaurant.latitude +
            "," +
            item.restaurant.longitude +
            "&amp;ll="
        );
      }
    },
    deleteNotification(item) {
      item.notified = true;
      this.$store.dispatch(
        "profile/updateProfile",
        {
          profile: this.$store.state.profile.data,
        },
        { root: true }
      );
    },
  },
  watch: {
    todaysBookings: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.overlay = false;
        }
      },
    },
  },
};
</script>
<style scoped>
.notification-card {
  border-radius: 12px !important;
  width: 95%;
  -webkit-box-shadow: 0px 10px 25px 1px rgba(45, 255, 196, 0.52) !important;
  -moz-box-shadow: 0px 10px 25px 1px rgba(45, 255, 196, 0.52) !important;
  box-shadow: 0px 10px 25px 1px rgba(45, 255, 196, 0.52) !important;
}
.notification-feed {
  position: absolute;
  bottom: 12px;
  width: 100%;
  overflow-y: scroll;
  max-height: 95%;
}
.tail:after {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: -2px;
  left: -33px;
  border-radius: 18px;
  border-bottom: 10px solid;
  border-image-source: radial-gradient(
    circle at 0 8%,
    #01a2e9,
    #56c0df 80%,
    #86d0da 98%
  );
  transform: rotate(-60deg);
  z-index: 0;
  content: "";
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(to right, transparent, transparent);
  border-image-slice: 1;
}
</style>
