import axios from "axios";
let apiUrl = "";
import store from "@/store";
import Vue from "vue";
import findIndex from "lodash/findIndex";
import cloneDeep from "lodash/cloneDeep";
let geolib = require("geolib");
const cachedRequests = [];

switch (process.env.NODE_ENV) {
  case "production":
    apiUrl = "https://poco-api-yx7pkfvfja-ew.a.run.app/api/poco";
    break;
  case "demo":
    apiUrl = "https://us-central1-firebase-demo-wow.cloudfunctions.net";
    break;
  case "staging":
    apiUrl = "https://poco-api-yx7pkfvfja-ew.a.run.app/api/poco";
    break;
  default:
    apiUrl = "http://localhost:8080/api/poco";
    break;
}

export default {
  getLocations(
    lat,
    lng,
    lang,
    radius,
    type = "recommendation",
    all,
    userId,
    filteredCategories
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getLocations`, {
          params: {
            lat,
            lng,
            lang,
            radius,
            type,
            all,
            userId,
            filteredCategories,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getAdvertisements(lang) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getAdvertisements`, { params: { lang } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getDeals() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getDeals`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  // Returns a profile with the specified userId.
  // Creates a new database record if it does not exist yet.
  getProfile(userId, userData, firstName, lastName, phone) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getProfile`, {
          params: { userId, userData, firstName, lastName, phone },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error), reject(error);
        });
    });
  },
  userExists(email) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/userExists`, {
          params: { email },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error), reject(error);
        });
    });
  },
  updateProfile(userId, profile) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl}/updateProfile`, { userId, profile })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getAvailableLanguages() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "http://localhost:5000/wowapp-b59fa/us-central1/getAvailableLanguages"
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getNearby(
    lat,
    lng,
    lang,
    radius,
    type = "nearby",
    all,
    userId,
    tags,
    filteredCategories
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getRecommendations`, {
          params: {
            lat,
            lng,
            lang,
            radius,
            type,
            all,
            userId,
            tags,
            filteredCategories,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getRecommendationsV2(
    lat,
    lng,
    lang,
    radius,
    type = "recommendation",
    all,
    userId,
    filteredCategories,
    last_item
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getRecommendations`, {
          params: {
            lat,
            lng,
            lang,
            radius,
            type,
            all,
            userId,
            filteredCategories,
            last_item,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  searchV2(city, lat, lng, lang, lastId, requestedItemCount, search) {
    let params = {
      city,
      lat: lat.toFixed(3),
      lng: lng.toFixed(3),
      lang,
      lastId,
      requestedItemCount,
      search,
    };
    let index = findIndex(cachedRequests, ["params", params]);
    if (index >= 0) {
      return cachedRequests[index].items;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/searchV2`, { params })
        .then((response) => {
          if (findIndex(cachedRequests, ["params", params]) < 0) {
            cachedRequests.push({ params, items: response.data });
          }
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getFavorites(userId, lang) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getUserItems`, {
          params: { userId, type: "favorites", lang },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getLikes(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getUserItems`, {
          params: { userId, type: "likes" },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getDislikes(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getUserItems`, {
          params: { userId, type: "dislikes" },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getHistory(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getUserItems`, {
          params: { userId, type: "history" },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getActiveItems(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getUserItems`, {
          params: { userId, type: "activeItems" },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getTagsKeywords(lang) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getTagsKeywords`, {
          params: { lang },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getDetails(entryId, lang, collection) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getDetails`, {
          params: { entryId, lang, collection },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  parseCSV(file, schema, lang) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl}/parseCSV`, { file, schema, lang })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getAllItems(
    lat,
    lng,
    lang,
    radius,
    type,
    all,
    userId,
    filteredCategories,
    last_item
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getRecommendations`, {
          params: {
            lat,
            lng,
            lang,
            radius,
            type,
            all,
            userId,
            filteredCategories,
            last_item,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  updateClicked(id, clicked, collection) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl}/updateClicked`, { id, clicked, collection })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logView(recommendationId, userId, userName, partnerId) {
    return new Promise((resolve, reject) => {
      const params = {
        recommendationId: recommendationId,
        event: "view",
        userId: userId,
        userName: userName,
        partnerId: partnerId,
        eventDetails: {},
      };
      axios
        .post(`${apiUrl}/setLog`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logCategoryView(categoryId, categoryName, userId, userName, partnerId) {
    return new Promise((resolve, reject) => {
      const params = {
        categoryId: categoryId,
        categoryName: categoryName,
        event: "categoryView",
        userId: userId,
        userName: userName,
        partnerId: partnerId,
        eventDetails: {},
      };
      axios
        .post(`${apiUrl}/setLog`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logPartnerView(userId, userName, partnerId) {
    return new Promise((resolve, reject) => {
      const params = {
        event: "widgetView",
        userId: userId,
        userName: userName,
        partnerId: partnerId,
        eventDetails: {},
      };
      axios
        .post(`${apiUrl}/setLog`, params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logReservation(
    userId,
    userName,
    partnerId,
    recommendationId,
    recommendationName,
    placeId,
    placeName,
    persons,
    bookingref
  ) {
    return new Promise((resolve, reject) => {
      const params = {
        userId: userId,
        userName: userName,
        event: "booking",
        recommendationId: recommendationId,
        recommendationName: recommendationName,
        placeId: placeId,
        placeName: placeName,
        partnerId: partnerId,
        persons: persons,
        bookingRef: bookingref,
      };
      axios
        .post(`${apiUrl}/setLog`, params)
        .then((response) => {
          console.log("Saved log ---- " + response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getByCategory(lat, lng, lang, radius, all, category, last_item) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getRecommendations`, {
          params: { lat, lng, lang, radius, all, category, last_item },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getByMood(lat, lng, lang, radius, all, mood, last_item) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getRecommendations`, {
          params: { lat, lng, lang, radius, all, mood, last_item },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  addUserRating(id, rating, collection) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl}/addUserRating`, { id, rating, collection })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getOpeningHours(item) {
    // There are no opening times for this recommendation
    if (!item.openHours || !item.openHours.length) {
      return "";
    }

    // Sunday - Saturday : 0 - 6
    let day = new Date().getDay();
    // Mapping it to Monday - Sunday : 0 - 6
    day = day === 0 ? 7 : day;

    let openHours = cloneDeep(item.openHours);

    // Get yesterday, today and tomorrow
    let todayHours = openHours.filter((a) => a.weekDay == day);
    let tomorrowHours = openHours.filter(
      (a) => a.weekDay == (day + 1 > 6 ? 0 : day + 1)
    );
    let yesterdayHours = openHours.filter(
      (a) => a.weekDay == (day - 1 < 0 ? 6 : day - 1)
    );
    const openCloseTimes = [];

    if (!todayHours.length) {
      // console.error("Error calculating open status. Errorcode 1");
      return Vue.i18n.translate("dashboard.closedToday");
    }

    if (
      todayHours[0].open_all_day === true &&
      tomorrowHours.length &&
      tomorrowHours[0].open_all_day === true
    ) {
      // If open_all_day is true for today and tommorow then is open 24 hours
      return Vue.i18n.translate("dashboard.openAllDay");
    }

    /* Create open/close array of pairs yesterday, today and tomorrow */
    /* 
      0: {close: '2021-11-16 23:00'}
      1: {open: '2021-11-17 12:00', close: '2021-11-17 15:30'}
      2: {open: '2021-11-17 19:00', close: '2021-11-17 23:00'}
      3: {open: '2021-11-18 12:00'}
      */

    if (yesterdayHours.length) {
      try {
        let yesterdayClosesToday =
          yesterdayHours.length &&
          yesterdayHours[yesterdayHours.length - 1].close <
            yesterdayHours[yesterdayHours.length - 1].open;

        let _close =
          new Date(
            new Date().setDate(
              new Date().getDate() - (!yesterdayClosesToday ? 1 : 0)
            )
          )
            .toISOString()
            .split("T")[0] +
          "T" +
          yesterdayHours[yesterdayHours.length - 1].close +
          ":00";
        openCloseTimes.push({ close: _close });
      } catch (error) {}
    }

    if (todayHours.length) {
      for (const k in todayHours) {
        try {
          let todayClosesTomorrow = todayHours[k].close < todayHours[k].open;

          let _open =
            new Date(new Date().setDate(new Date().getDate()))
              .toISOString()
              .split("T")[0] +
            "T" +
            todayHours[k].open +
            ":00";
          let _close =
            new Date(
              new Date().setDate(
                new Date().getDate() + (todayClosesTomorrow ? 1 : 0)
              )
            )
              .toISOString()
              .split("T")[0] +
            "T" +
            todayHours[k].close +
            ":00";
          openCloseTimes.push({ open: _open, close: _close });
        } catch (error) {}
      }
    }

    if (tomorrowHours.length) {
      try {
        let _open =
          new Date(new Date().setDate(new Date().getDate() + 1))
            .toISOString()
            .split("T")[0] +
          "T" +
          tomorrowHours[0].open +
          ":00";
        openCloseTimes.push({ open: _open });
      } catch (error) {}
    }
    /* END Create open/close array of pairs yesterday, today and tomorrow */

    let currentTime = Date.now();
    for (const k in openCloseTimes) {
      if (
        openCloseTimes[k].close != undefined &&
        currentTime < Date.parse(openCloseTimes[k].close)
      ) {
        // check current time lower than close time
        // then check open time
        // first elemt contains only close time if closing next day
        if (
          openCloseTimes[k].open == undefined ||
          currentTime >= Date.parse(openCloseTimes[k].open)
        ) {
          let _closeHour = new Date(openCloseTimes[k].close).toLocaleString(
            "en-US",
            {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }
          );
          return Vue.i18n.translate("dashboard.open", {
            value: _closeHour,
          });
        } else if (
          openCloseTimes[k].open != undefined &&
          currentTime < Date.parse(openCloseTimes[k].open)
        ) {
          let _openHour = new Date(openCloseTimes[k].open).toLocaleString(
            "en-US",
            {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }
          );
          return Vue.i18n.translate("dashboard.closed", {
            value: _openHour,
          });
        }
      } else if (openCloseTimes[k].close == undefined) {
        // last item only contains open time - next day open
        if (
          openCloseTimes[k].open != undefined &&
          currentTime <= Date.parse(openCloseTimes[k].open)
        ) {
          let _openHour = new Date(openCloseTimes[k].open).toLocaleString(
            "en-US",
            {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            }
          );
          return Vue.i18n.translate("dashboard.closed", {
            value: _openHour,
          });
        }
      }
    }

    console.error("Error calculating open status. Errorcode 2");
    return Vue.i18n.translate("dashboard.closedToday");
  },
  checkAvailability(placeId, covers, date, time, promotionId, visitId) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(`${apiUrl}/reservation/availability`, {
          placeId,
          covers,
          date,
          time,
          promotionId,
          visitId,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  postReservation(
    visitId,
    email,
    name,
    countryId,
    phone,
    placeId,
    areaId,
    covers,
    date,
    time,
    waitingList,
    promotionId
  ) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(`${apiUrl}/reservation/reserve`, {
          visitId,
          email,
          name,
          countryId,
          phone,
          placeId,
          areaId,
          covers,
          date,
          time,
          waitingList,
          promotionId,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cancelReservation(visitId) {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(`${apiUrl}/reservation/cancel`, { visitId })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDistanceToLocation(item) {
    const from = {
      latitude: store.state.map.userPosition.lat,
      longitude: store.state.map.userPosition.lng,
    };
    const to = {
      latitude: item.location.lat,
      longitude: item.location.lng,
    };
    const meters = geolib.getPreciseDistance(to, from, 1);
    return geolib.convertDistance(meters, "km").toFixed(1);
  },
  getAppStoreVersion() {
    return new Promise(async (resolve, reject) => {
      await axios
        .get("https://itunes.apple.com/lookup?bundleId=com.pocoapp.poco")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  sendAppRating(rating, comment) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl}/sendAppRating`, { rating, comment })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPromotions(restaurantId) {
    return new Promise(async (resolve, reject) => {
      const axiosParams = new URLSearchParams();
      axiosParams.append("restaurantId", restaurantId);
      await axios
        .get(`${apiUrl}/getPromotions`, {
          params: axiosParams,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getMenu(restaurantId) {
    return new Promise(async (resolve, reject) => {
      const axiosParams = new URLSearchParams();
      axiosParams.append("restaurantId", restaurantId);
      await axios
        .get(`${apiUrl}/getMenu`, {
          params: axiosParams,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getRestaurantInfo(restaurantId) {
    return new Promise(async (resolve, reject) => {
      const axiosParams = new URLSearchParams();
      axiosParams.append("restaurantId", restaurantId);
      await axios
        .get(`${apiUrl}/getRestaurantInfo`, {
          params: axiosParams,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeActivityHistoryItem(userId, visitId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl}/removeActivityHistoryItem`, { userId, visitId })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTickets(entryId, lang) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getTickets`, {
          params: { entryId, lang },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getPartnerConfig(partnerId, lang) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getPartnerConfig`, {
          params: { partnerId, lang },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  savePartnerForUser(userId, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl}/savePartnerForUser`, { userId, partnerId })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  textSearch(lang, searchTerm) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/textSearch`, {
          params: { lang, searchTerm },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getItems(lang) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getItems`, {
          params: { lang },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  startPayment(
    amount,
    countryCode,
    currency,
    description,
    lastname,
    propertyID,
    reference,
    request
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${apiUrl}/startPayment`, {
          amount,
          countryCode,
          currency,
          description,
          lastname,
          propertyID,
          reference,
          request,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getTicketDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getTicketDetails`, { params: { id } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getTimeOffset(cityId) {
    // This was the quickest way of implementing timezones.
    // It's probably not 100% accurate but it'll do.
    const timeOffsets = {
      "07DZWZoQpVLl9vRQFW4f": 1, // Sarajevo
      "2hrOQZzKbWUwL6r3dEwJ": 3, // Istanbul
      "2ua5EIfAUR3e75nxKze4": 4, // Sharjah
      "2ybGaWK8q7T0nvUrSXCB": 1, // Vienna
      "6w47lwvr9mzP4RLLGkEB": 2, // Zouk Mosbeh
      "8o8xd31ffKECCSeAlaVy": 4, // Ras Al Khaimah
      BHdYyHneK2L8Iu10fxj6: 3, // Mecca
      DxTFrdnkiQ0yMl8DtbQc: 1, // Düsseldorf
      EtLqwY5J5WSPlnvbzxnl: 1, // Oberwart
      FMguXwCzbDD9i0GGzYPY: 1, // Wolfau
      GaIBP7JxnLgPVonlGb52: 1, // Kinshasa
      GyZLvXyo8F93BFSnSjg1: 3, // Riyadh
      HOOK8LbdRgFTAQPP5iw5: 4, // Umm al-Qaiwain
      IiTKQJmm1hB73i61i456: 4, // Salalah
      JGEmF1OuFP8EatwbNqXY: 2, // Sharm El Sheikh
      KGjqNWAtRHZr3g2fLhTY: 2, // Amman
      KZtgGlpdn1Uv1cDFCgKD: 1, // Potsdam
      KbiTXwWjypyUMrUzUwWu: 4, // Abu Dhabi
      LJ4LTXT0UP6vT0ik01tN: 3, // Al Khobar
      LoHdV7cLfHLSSkPBl6VO: 3, // Manama
      MdfMMUxFGaxTe0yKNSPn: 0, // Jabing
      OF2DdbYE7RXJEgN5a5Bo: 2, // Ajaltoun
      OjRzhbQMJQFvZR7O3eql: 4, // Ajman
      SDyspgFEit4dJMWtjbP2: 1, // Berlin
      UTJWFgfAXOschf9ArQQD: 1, // Munich
      UrlqXmaWBgL6Gj68OlOo: 4, // Al Ain
      WyLX1M0CQJcamjKkZb5B: 3, // Doha
      YR7gGnoFgYHDzMIeoqn6: 5.5, // Tezpur
      cZ0gO1IHK3Sqhgr08g9v: 1, // Marrakesh
      dxrxabdd8SnVWXRNQAoO: 1, // Graz
      eKWS4Ws0MV0hcC2jPv6s: 4, // Oman
      gXNrVf5ejRme5jqos0qg: 3, // Erbil
      jAISM7Vw6soZOexNwo4V: 4, // Fujairah
      kGojsepIHfNz5mc6Q95v: 3, // Baghdad
      l6KuN2guCtPC5AFHO2xA: 3, // Jeddah
      nkI169BaXcOVGEDQORz4: 3, // Muharraq
      orSHwPKtBGSBqUIsJdEG: 1, // Grünwald
      uGRxCY47dMs0X54f4tpW: 4, // Dubai
      uHP0jAbPDhgfBIm99XKr: 2, // Jbeil
      uhCe75OcFHTdWOuK7RLN: 3, // Dar es Salaam
      xm8q0QE4V2OfugMjJEGs: 1, // Salzburg
      yKX8zacyfQyRJge89Y9y: 2, // Beirut
    };
    if (timeOffsets[cityId]) {
      return timeOffsets[cityId];
    } else {
      return 0;
    }
  },
  getCategories() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/getCategories`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  // v4
  getByCategoryV4(lat, lng, radius, city, lang, category) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/v4/getByCategory`, {
          params: { lat, lng, radius, city, lang, category },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getDashboardRecommendationsV4(lat, lng, city, lang) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/v4/getDashboardRecommendations`, {
          params: { lat, lng, city, lang },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  getByTagV4(lat, lng, city, lang, tags) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/v4/getByTag`, {
          params: { lat, lng, city, lang, tags },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};
