import api from "@/api";
import map from "lodash/map";

export default {
  async nearbyMarker(
    { state, rootState, commit, rootGetters },
    {
      lat = state.userPosition.lat,
      lng = state.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = rootState.recommendations.radius,
      type = "nearby",
      all = true,
      userId = rootState.profile.data.id,
      tags,
      filteredCategories = map(
        rootGetters["categories/filteredCategories"],
        "id"
      ),
    }
  ) {
    commit("FETCH_MARKER");
    commit("SET_CENTER", state.userPosition);
    try {
      commit(
        "SUCCESS_FETCH_MARKER",
        await api.getNearby(
          lat,
          lng,
          lang,
          radius,
          type,
          all,
          userId,
          tags,
          filteredCategories
        )
      );
    } catch (error) {
      commit("FAILURE_FETCH_MARKER");
    }
  },
};
