import Vue from "vue";
import VueRouter from "vue-router";
import moment from "moment";
import find from "lodash/find";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/map",
    name: "map",
    component: () => import("../views/Map.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/detail/:id",
    name: "detail",
    component: () => import("../views/Detail.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/authenticate",
    name: "authenticate",
    component: () => import("../views/Authenticate.vue"),
  },
  {
    path: "/favorites",
    name: "favorites",
    component: () => import("../views/Favorites.vue"),
  },
  {
    path: "/activedeals",
    name: "activedeals",
    component: () => import("../views/ActiveDeals.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Settings.vue"),
  },
  {
    path: "/mypoco/bookings",
    name: "bookings",
    component: () => import("../views/MyBookings.vue"),
  },
  {
    path: "/mypoco/bookings/modify",
    name: "modifyBooking",
    component: () => import("../views/ModifyBooking.vue"),
  },
  {
    path: "/privacyterms",
    name: "privacyterms",
    component: () => import("../views/PrivacyTerms.vue"),
  },
  {
    path: "/info",
    name: "info",
    component: () => import("../views/Impressum.vue"),
  },
  {
    path: "/mypoco",
    name: "mypoco",
    component: () => import("../views/MyPoco.vue"),
  },
  {
    path: "/mypoco/preferences",
    name: "preferences",
    component: () => import("../views/Preferences.vue"),
  },
  {
    path: "/mypoco/tickets",
    name: "tickets",
    component: () => import("../views/Tickets.vue"),
  },
  {
    path: "/mypoco/activityhistory",
    name: "activityhistory",
    component: () => import("../views/ActivityHistory.vue"),
  },
  {
    path: "/upload",
    name: "upload",
    component: () => import("../views/Upload.vue"),
  },
  {
    path: "/login/reset-password",
    name: "resetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/login/create-password",
    name: "createPassword",
    component: () => import("../views/CreatePassword.vue"),
  },
  {
    path: "/login/travel-type",
    name: "travelType",
    component: () => import("../views/TravelType.vue"),
  },
  {
    path: "/all-recommendations",
    name: "allRecommendations",
    component: () => import("../views/AllRecommendations.vue"),
  },
  {
    path: "/by-category/:id/:type",
    name: "byCategory",
    component: () => import("../views/ByCategory.vue"),
  },
  {
    path: "/rating/:detailId",
    name: "rating",
    component: () => import("../views/Rating.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/Notifications.vue"),
  },
  {
    path: "/login-email",
    name: "loginEmail",
    component: () => import("../views/LoginEmail.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../components/app/Auth.vue"),
  },
  {
    path: "/all-categories",
    name: "allCategories",
    component: () => import("../views/AllCategories.vue"),
  },
  {
    path: "/confirm-email",
    name: "confirmEmail",
    component: () => import("../views/ConfirmEmail.vue"),
  },
  {
    path: "/reservation",
    name: "reservation",
    component: () => import("../views/Reservation.vue"),
  },
  {
    path: "/choose-area",
    name: "chooseArea",
    component: () => import("../views/ChooseArea.vue"),
  },
  {
    path: "/location-recommendations",
    name: "currentLocationRecommendations",
    component: () => import("../views/LocationRecommendations.vue"),
  },
  {
    path: "/menu/:id",
    name: "menu",
    component: () => import("../views/Menu.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/ticketdetail/:restaurantId/:type/:ticketId",
    name: "ticketdetail",
    component: () => import("../views/Ticketdetail.vue"),
  },
  {
    path: "/ticketstandalone/:ticketId",
    name: "ticketstandalone",
    component: () => import("../views/TicketStandalone.vue"),
  },
  {
    path: "/ticketoptions",
    name: "ticketoptions",
    component: () => import("../views/Ticketoptions.vue"),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (savedPosition && to.name !== "detail") {
        resolve(savedPosition);
      } else {
        if (from.name !== "detail" || to.name !== "byCategory") {
          setTimeout(() => {
            resolve({ x: 0, y: 0 });
          }, 500);
        }
      }
    });
  },
});

router.back = function () {
  this.action = "back";
  if (!this.past || this.past.length < 2) {
    if (this.currentRoute.name !== "home") {
      this.push({ name: "home" });
    }
    return;
  }
  this.past.pop();
  this.push({
    path: this.past[this.past.length - 1].path,
    query: this.past[this.past.length - 1].query,
    params: this.past[this.past.length - 1].params,
  });
};

router.safePush = function (pushParams) {
  if (router.app.$store.state.partners.config.id) {
    if (pushParams.query) {
      pushParams.query = {
        ...pushParams.query,
        partnerId: router.app.$store.state.partners.config.id,
      };
    } else {
      pushParams.query = {
        partnerId: router.app.$store.state.partners.config.id,
      };
    }
    this.push(pushParams);
  } else {
    this.push(pushParams);
  }
};

router.beforeEach(async (to, from, next) => {
  // Go back in the history if the next item is the same as the second to last in the history.
  if (
    router.past?.length > 1 &&
    router.past[router.past.length - 2].name === to.name
  ) {
    router.past.pop();
    router.past.pop();
  }
  let sensitiveRoutes = [
    { name: "home" },
    { name: "bookings" },
    { name: "tickets" },
    { name: "info" },
    { name: "privacyterms" },
  ];
  // Add the partnerId to the url if it got lost somewhere.
  if (
    find(sensitiveRoutes, ["name", to.name]) &&
    !to.query.partnerId &&
    !!router.app?.$store?.state?.partners?.config?.id
  ) {
    router.safePush({
      name: to.name,
      query: { partnerId: router.app.$store.state.partners.config.id },
    });
    return;
  } else if (
    to.name === "home" &&
    !to.query.partnerId &&
    !router.app?.$store?.state?.partners?.config?.id
  ) {
    window.sessionStorage.clear();
  }

  await router.app?.$nextTick();
  await router.app.$store.commit("SET_MENU", false);
  if (to.name && !from.name) {
    if (window.cordova) {
      document.addEventListener("deviceready", async () => {
        await router.app?.$store.dispatch("session/initApp", to.query);
      });
    } else {
      await router.app?.$store.dispatch("session/initApp", to.query);
    }
  }

  // Add history to router
  // -- Renamed router.history to router.past, since .history was already in use.
  !router.past && (router.past = []);

  // Reset the history when going to home (There is no back button in home)
  to.name === "home" && (router.past = []);
  // Skip adding the element when going back.
  if (router.past[router.past.length - 1]?.path !== to?.path) {
    router.past.push(to);
  }

  // Saving previous route.
  // TODO: Remove this, since there is a proper history now.
  router.from = from;

  next();
});

router.afterEach(async (to, from) => {
  // TODO: Turn this into a switch case, or something readable.
  if (to.name === "byCategory") {
    if (from.name === "search") {
      await router.app.$store.dispatch("recommendations/getByCategoryV4", {
        category: to.params.id,
        lat: to.params.lat,
        lng: to.params.lng,
      });
    } else if (from.name === "detail") {
      // Don't reload byCategory.
      if (
        router.app.$store._modules.root.state.recommendations.details.category
          .id !== to.params.id ||
        router.app.$store._modules.root.state.recommendations.byCategoryV4
          ?.length === 0
      ) {
        await router.app.$store.dispatch("recommendations/getByCategoryV4", {
          category: to.params.id,
        });
      }
    } else if (from.name) {
      // if (to.params.type === "category") {
      await router.app.$store.dispatch("recommendations/getByCategoryV4", {
        category: to.params.id,
      });
      // } else {
      //   await router.app.$store.dispatch("recommendations/getByMood", {
      //     mood: to.params.id,
      //   });
      // }
    }
  } else if (to.name === "menu") {
    await router.app.$store.dispatch("reserveout/getRestaurantInfo", {
      restaurantId: to.params.id,
    });
    await router.app.$store.dispatch("reserveout/getMenu", {
      restaurantId: to.params.id,
    });
  } else if (
    (from.name === "chooseArea" && to.name === "reservation") ||
    (from.name === "modifyBooking" &&
      to.name === "chooseArea" &&
      router.action === "back") ||
    (from.name === "bookings" && to.name === "chooseArea") ||
    (from.name === "bookings" && to.name === "ticketoptions") ||
    (from.name === "bookings" &&
      to.name === "modifyBooking" &&
      router.action === "back")
  ) {
    router.safePush({ name: "home" });
  } else if (to.name === "detail") {
    await router.app.$store.commit("reserveout/REMOVE_PROMOTIONS");
    await router.app.$store.dispatch("recommendations/getDetails", {
      entryId: to.params.id,
    });
    await router.app.$store.dispatch("recommendations/getTickets", {
      entryId: to.params.id,
    });
    if (router.app.$store._modules.root.state.recommendations.details.wowId) {
      await router.app.$store.dispatch("reserveout/getMenu", {
        restaurantId:
          router.app.$store._modules.root.state.recommendations.details.wowId,
      });
      await router.app.$store.dispatch("reserveout/getPromotions", {
        restaurantId:
          router.app.$store._modules.root.state.recommendations.details.wowId,
      });
    }
    const rootState = router.app.$store._modules.root.state;
    if (rootState.isMiKY) {
      router.app.$store.dispatch("recommendations/logView", {
        recommendationId: rootState.recommendations.details.id,
      });
      router.app.$store.dispatch("recommendations/logCategoryView", {
        categoryId: rootState.recommendations.details.category.id,
        categoryName: rootState.recommendations.details.category.title,
      });
    }
  } else if (from.name === "detail" && to.name !== "ticketdetail") {
    await router.app.$store.commit("reserveout/REMOVE_PROMOTIONS");
  } else if (to.name === "ticketdetail") {
    if (from.name !== "detail") {
      await router.app.$store.dispatch("recommendations/getDetails", {
        entryId: to.params.restaurantId,
      });
      await router.app.$store.dispatch("recommendations/getTickets", {
        entryId: to.params.restaurantId,
      });
      if (router.app.$store._modules.root.state.recommendations.details.wowId) {
        await router.app.$store.dispatch("reserveout/getMenu", {
          restaurantId:
            router.app.$store._modules.root.state.recommendations.details.wowId,
        });
        await router.app.$store.dispatch("reserveout/getPromotions", {
          restaurantId:
            router.app.$store._modules.root.state.recommendations.details.wowId,
        });
      }
    }
    await router.app.$store.dispatch("tickets/getDetails", {
      id: to.params.ticketId,
    });
  } else if (to.name === "ticketoptions") {
    try {
      if (
        router.app.$store._modules.root.state.tickets.options.tickettype ===
        "reserve-out"
      ) {
        await router.app.$store.dispatch("reserveout/checkAvailability", {
          placeId:
            router.app.$store._modules.root.state.tickets.options.restaurantId,
          covers:
            router.app.$store._modules.root.state.tickets.options
              .numberoftickets,
          date: moment(
            router.app.$store._modules.root.state.tickets.options.date
          ).format("YYYY-MM-DD"),
          time: router.app.$store._modules.root.state.tickets.options.time,
          promotionId:
            router.app.$store._modules.root.state.tickets.options.ticketId,
          header: null,
          countryId:
            router.app.$store._modules.root.state.tickets.options.countryId,
        });
      } else {
        // TODO!
      }
    } catch (err) {
      console.log("error:", err);
    }
  } else if (to.name === "reservation" && from.name === "activityhistory") {
    await router.app.$store.dispatch("recommendations/getDetails", {
      entryId: to.params.id,
    });
  }
  // resetting the action
  router.action = "";
});

export default router;
