import each from "lodash/each";

export default {
  FETCH_ADVERTISEMENTS: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_ADVERTISEMENTS: (state, value) => {
    state.items = value;
    each(state.items, (ad) => {
      ad.alreadyShown = false;
    });
    state.status = "success";
  },
  FAILURE_FETCH_ADVERTISEMENTS: (state) => {
    state.status = "failure";
  },
  SET_CURRENT_AD: (state, value) => {
    state.currentAd = value;
  },
  SET_DIALOG: (state, value) => {
    state.showDialog = value;
  },
  SET_BANNER: (state, value) => {
    state.showBanner = value;
  },
  SET_ALREADY_SHOWN: (state) => {
    state.items = state.items.map((item) => {
      if (item.id === state.currentAd.id) {
        item.alreadyShown = true;
      }

      return item;
    });
  },
};
