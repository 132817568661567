import differenceBy from "lodash/differenceBy";
import moment from "moment";
export default {
  todaysBookings: (state) => {
    const currentDate = new Date().toISOString().slice(0, 10);
    return state.data && state.data.bookings
      ? state.data.bookings.filter(
          (el) =>
            el.arrival[0] == currentDate.substring(0, 4) &&
            (el.arrival[1] < 10 ? "0" + el.arrival[1] : el.arrival[1]) ==
              currentDate.substring(5, 7) &&
            (el.arrival[2] < 10 ? "0" + el.arrival[2] : el.arrival[2]) ==
              currentDate.substring(8, 10) &&
            el.notified == false
        )
      : [];
  },
  futureBookings: (state, getters) => {
    if (!state.data.bookings) {
      return null;
    }
    const allBookings = state.data.bookings.map((el) =>
      Object.assign({}, el.arrival, {
        visitId: el.visitId,
        area: el.area,
        arrival: el.arrival,
        code: el.code,
        comments: el.comments,
        covers: el.covers,
        guestMail: el.guestMail,
        guestName: el.guestName,
        guestPhone: el.guestPhone,
        guestPhoneCountryId: el.guestPhoneCountryId,
        promotion: el.promotion,
        promotionId: el.promotionId,
        restaurant: el.restaurant,
        status: el.status,
      })
    );
    const futureBookings = allBookings.map((el) => {
      const date = {
        date:
          el[0].toString() +
          (el[1] < 10 ? "0" + el[1].toString() : el[1].toString()) +
          (el[2].toString() < 10 ? "0" + el[2].toString() : el[2].toString()),
        visitId: el.visitId,
        area: el.area,
        arrival: el.arrival,
        code: el.code,
        comments: el.comments,
        covers: el.covers,
        guestMail: el.guestMail,
        guestName: el.guestName,
        guestPhone: el.guestPhone,
        guestPhoneCountryId: el.guestPhoneCountryId,
        promotion: el.promotion,
        promotionId: el.promotionId,
        restaurant: el.restaurant,
        status: el.status,
      };
      return date;
    });
    return futureBookings.filter((el) => {
      let bookingTime =
        (el.arrival[3] < 10 ? "0" + el.arrival[3] : el.arrival[3]) +
        ":" +
        (el.arrival[4] < 10 ? "0" + el.arrival[4] : el.arrival[4]);
      // when date is the same check if time is in the future
      if (parseInt(el.date) == getters.currentDate) {
        return (
          parseInt(el.date) == getters.currentDate &&
          bookingTime >= getters.currentTime
        );
      } else {
        return parseInt(el.date) > getters.currentDate;
      }
    });
  },
  currentDate() {
    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("");
    return currentDate;
  },
  currentTime() {
    return moment().format("HH:mm");
  },
};
