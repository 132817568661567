<template>
  <v-container>
    <v-dialog
      v-model="snackbar"
      max-width="325"
      :timeout="-1"
      overlay-color="backgroundoverlay"
      overlay-opacity="1"
    >
      <v-card style="border-radius: 20px !important" flat class="pa-0">
        <v-toolbar flat dense>
          <v-spacer></v-spacer>
          <v-btn icon @click="snackbar = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-img
          height="220"
          contain
          :src="require('@/assets/img/update.svg')"
        ></v-img>
        <v-card-text class="Text-Dark-Text-1---18-Center mt-4 px-0">{{
          $t("navigation.newContent")
        }}</v-card-text>
        <v-container class="text-center">
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="reload()"
                min-height="48"
                min-width="150"
                class="gradient white--text Text-Dark-Text-1---18-Center"
                >{{ $t("navigation.updateApp") }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                text
                class="Text-Dark-Text-1---18-Center"
                color="primary"
                min-height="40px"
                min-width="120px"
                @click="snackbar = false"
                >{{ $t("login.notNow") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { isIOS, isAndroid } from "mobile-device-detect";
export default {
  name: "NewContentSnackBar",
  data() {
    return {};
  },
  props: ["model"],
  computed: {
    isNative() {
      return (
        document.URL.indexOf("http://") === -1 &&
        document.URL.indexOf("https://") === -1
      );
    },
    ...mapState({
      refreshBar: (state) => state.refreshBar,
    }),
    snackbar: {
      get() {
        return this.refreshBar;
      },
      set(val) {
        this.setRefreshBar(val);
      },
    },
  },
  methods: {
    ...mapMutations({
      setRefreshBar: "SET_REFRESH_BAR",
    }),
    commitRefresh() {
      this.$store.commit("SET_REFRESH_BAR", false);
    },
    reload() {
      if (this.isNative && isIOS) {
        window.open(
          "https://apps.apple.com/us/app/pocket-concierge-app/id1537564162",
          "_system"
        );
      } else if (this.isNative && isAndroid) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.pocoapp.poco";
      } else {
        if (this.$store.state.registration) {
          this.$store.state.registration.waiting.postMessage("skipWaiting");
        }
      }
      this.$store.commit("SET_REFRESH_BAR", false);
    },
  },
};
</script>
