export default {
  FETCH_TAGS: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_TAGS: (state, value) => {
    state.status = "success";
    state.items = value;
  },
  FAILURE_FETCH_TAGS: (state) => {
    state.status = "failure";
  },
};
