var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-navigation',{staticStyle:{"z-index":"106"},attrs:{"fixed":"","app":"","grow":""},model:{value:(_vm.bottomNav),callback:function ($$v) {_vm.bottomNav=$$v},expression:"bottomNav"}},[_c('v-btn',{staticStyle:{"min-width":"20%"},attrs:{"active-class":"primary--text","to":"/"}},[_c('span',{staticClass:"Text-Dark-Text-5---10-Center"},[_vm._v(_vm._s(_vm.$t("navigation.home")))]),_c('v-img',{staticClass:"color-filter",attrs:{"src":_vm.$route.name == 'home'
          ? require('@/assets/bottomNavigation/home_active.svg')
          : require('@/assets/bottomNavigation/home.svg')}})],1),_c('v-btn',{staticStyle:{"min-width":"20%"},attrs:{"active-class":"primary--text","to":"/notifications"}},[_c('span',{staticClass:"Text-Dark-Text-5---10-Center"},[_vm._v(_vm._s(_vm.$t("navigation.notifications"))+" ")]),_c('v-img',{staticClass:"color-filter",attrs:{"src":_vm.$route.name == 'notifications'
          ? require('@/assets/bottomNavigation/notifications_active.svg')
          : require('@/assets/bottomNavigation/notifications.svg')}})],1),_c('v-btn',{staticStyle:{"min-width":"20%"},attrs:{"active-class":"primary--text"},on:{"click":function($event){return _vm.goToMap($event)}}},[_c('span',{staticClass:"Text-Dark-Text-5---10-Center"},[_vm._v(_vm._s(_vm.$t("navigation.map")))]),_c('v-img',{staticClass:"color-filter",attrs:{"src":_vm.$route.name == 'map'
          ? require('@/assets/bottomNavigation/location_active.svg')
          : require('@/assets/bottomNavigation/location.svg')}})],1),_c('v-btn',{staticStyle:{"min-width":"20%"},attrs:{"active-class":"primary--text"},on:{"click":function($event){return _vm.goToSearch($event)}}},[_c('span',{staticClass:"Text-Dark-Text-5---10-Center"},[_vm._v(_vm._s(_vm.$t("navigation.search"))+" ")]),_c('v-img',{staticClass:"color-filter",attrs:{"src":_vm.$route.name == 'search'
          ? require('@/assets/bottomNavigation/search_active.svg')
          : require('@/assets/bottomNavigation/search.svg')}})],1),(!_vm.isMiKY)?_c('v-btn',{staticStyle:{"min-width":"20%"},attrs:{"active-class":"primary--text"},on:{"click":function($event){return _vm.goToMyPoco($event)}}},[_c('span',{staticClass:"Text-Dark-Text-5---10-Center"},[_vm._v(_vm._s(_vm.$t("navigation.myPoco")))]),_c('v-img',{staticClass:"color-filter",attrs:{"src":_vm.$route.name == 'mypoco'
          ? require('@/assets/bottomNavigation/mypoco_active.svg')
          : require('@/assets/bottomNavigation/mypoco.svg')}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }