<template>
  <v-footer
    app
    class="elevation-12"
    style="border: 2px solid; border-radius: 15px; z-index: 6"
    v-if="showAd"
  >
    <v-toolbar class="elevation-0 transparent">
      <v-toolbar-title>{{ currentAd.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="dismissAd()">
        <v-icon v-if="dismissable">mdi-close</v-icon>
        <v-avatar size="36" v-else> {{ countdown }}</v-avatar>
      </v-btn>
    </v-toolbar>

    <v-card class="pa-0 ma-0 elevation-0 transparent">
      <v-row no-gutters>
        <v-col class="shrink pa-2">
          <v-img
            class="image-bg"
            width="120"
            style="border-radius: 15px"
            height="140"
            :src="currentAd.imagePathw200"
          ></v-img>
        </v-col>
        <v-col class="grow">
          <div
            style="word-break: break-word !important"
            class="font-weight-regular text-left pa-2"
          >
            {{ currentAd.text }}
          </div>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn height="56" block color="primary" @click="goToPage()">{{
          currentAd.buttonText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import { mapState } from "vuex";
export default {
  data: () => {
    return {
      dismissable: false,
      countInterval: void 0,
      countdown: 5,
    };
  },
  computed: {
    ...mapState("advertisements", {
      currentAd: (state) => state.currentAd,
    }),
    showAd: {
      get() {
        return this.$store.state.advertisements.showBanner;
      },
      set(val) {
        this.$store.commit("advertisements/SET_BANNER", val);
      },
    },
  },
  methods: {
    goToPage() {
      this.countdown = 5;
      this.showAd = false;
      clearInterval(this.countInterval);
      this.$store.commit("advertisements/SET_ALREADY_SHOWN", true);
      firebase.analytics().logEvent("ad_clicked", {
        ad_id: this.currentAd.id,
      });
      let link = this.currentAd.webpage;
      if (
        this.$store.state.isMiKY &&
        (navigator.platform === "iPhone" || navigator.platform === "MacIntel")
      ) {
        window.location.assign(link);
      } else {
        window.open(link, "_system");
      }
    },
    dismissAd() {
      this.countdown = 5;
      clearInterval(this.countInterval);
      this.showAd = false;
      this.$store.commit("advertisements/SET_ALREADY_SHOWN", true);
      firebase.analytics().logEvent("ad_dismissed", {
        ad_id: this.currentAd.id,
      });
    },
  },
  watch: {
    "$store.state.advertisements.showBanner"(val) {
      if (val === true) {
        this.countInterval = setInterval(() => {
          this.countdown--;
          if (this.countdown < 1) {
            this.dismissable = true;
            clearInterval(this.countInterval);
          }
        }, 1000);
      } else {
        this.dismissable = false;
      }
    },
  },
};
</script>

<style lang="scss">
.v-footer {
  margin-top: 8px !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
  margin-bottom: constant(
    safe-area-inset-bottom
  ); /* move footer up on iPhone X - iOS 11.0 */
  margin-bottom: env(
    safe-area-inset-bottom
  ); /* move footer up on iPhone X - iOS 11.2 */
}
</style>
