<template>
  <v-avatar
    @click="toProfile()"
    style="z-index: 999; border-radius: 15px"
    size="50"
  >
    <v-img style="background-color: grey" :src="getProfilePicture"></v-img>
  </v-avatar>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProfilePicture",
  data() {
    return {};
  },
  methods: {
    toProfile() {
      if (this.$store.state.session.user) {
        this.$router.safePush({ name: "profile" });
      } else {
        this.$router.safePush({ name: "login" });
      }
    },
  },
  computed: {
    ...mapState({
      profilePicture: (state) => state.profile.data.profilePicture,
    }),
    getProfilePicture: {
      get() {
        if (this.$store.state.profile.data && this.profilePicture) {
          return this.profilePicture;
        } else {
          /* Default profile picture */
          return require("@/assets/avatar.png");
        }
      },
    },
  },
};
</script>
