export default () => ({
  status: "",
  currentLang: void 0,
  items: [
    {
      _fl_meta_: {
        docId: "de",
      },
      id: "de",
      name: "Deutsch",
    },
    {
      _fl_meta_: {
        docId: "en-US",
      },
      id: "en-US",
      name: "English",
    },
  ],
});
