<template>
  <v-dialog v-model="dialog" transition="dialog-bottom-transition" fullscreen>
    <v-card tile>
      <v-toolbar flat style="background: transparent">
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" right icon x-large color="onbackground"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <!-- TODO Change Picture to original sized one -->
      <v-img
        :src="require('@/assets/logout_bg.png')"
        max-height="50vh"
        contain
      ></v-img>
      <v-card-text
        class="v-html mt-8 Text-Dark-Text-1---18-Center"
        v-html="$t(`logout.${leavingText}`)"
      >
      </v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="gradient Heading-White-H4-18-Center d-block mx-auto"
              height="48px"
              min-width="300"
              depressed
              color="primary"
              @click="dialog = false"
              >{{ $t("logout.cancelLogout") }}</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn
              class="Heading-Color-H4-18-Center mx-auto d-block"
              text
              min-height="40px"
              @click="$emit('confirm'), (dialog = false)"
              >{{ $t(`logout.${confirmText}`) }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.state.profile.dialog;
      },
      set(val) {
        this.$store.commit("profile/SET_DIALOG", val);
      },
    },
  },
  props: {
    confirmText: {
      type: String,
      default: "confirmLogout",
    },
    leavingText: {
      type: String,
      default: "leaving",
    },
  },
};
</script>
