<template>
  <v-bottom-navigation fixed app grow v-model="bottomNav" style="z-index: 106">
    <v-btn style="min-width: 20%" active-class="primary--text" to="/">
      <span class="Text-Dark-Text-5---10-Center">{{
        $t("navigation.home")
      }}</span>
      <v-img
        class="color-filter"
        :src="
          $route.name == 'home'
            ? require('@/assets/bottomNavigation/home_active.svg')
            : require('@/assets/bottomNavigation/home.svg')
        "
      />
    </v-btn>
    <v-btn
      style="min-width: 20%"
      active-class="primary--text"
      to="/notifications"
    >
      <span class="Text-Dark-Text-5---10-Center"
        >{{ $t("navigation.notifications") }}
      </span>
      <v-img
        class="color-filter"
        :src="
          $route.name == 'notifications'
            ? require('@/assets/bottomNavigation/notifications_active.svg')
            : require('@/assets/bottomNavigation/notifications.svg')
        "
      ></v-img>
    </v-btn>
    <v-btn
      style="min-width: 20%"
      active-class="primary--text"
      @click="goToMap($event)"
    >
      <span class="Text-Dark-Text-5---10-Center">{{
        $t("navigation.map")
      }}</span>
      <v-img
        class="color-filter"
        :src="
          $route.name == 'map'
            ? require('@/assets/bottomNavigation/location_active.svg')
            : require('@/assets/bottomNavigation/location.svg')
        "
      ></v-img>
    </v-btn>
    <v-btn
      style="min-width: 20%"
      active-class="primary--text"
      @click="goToSearch($event)"
    >
      <span class="Text-Dark-Text-5---10-Center"
        >{{ $t("navigation.search") }}
      </span>
      <v-img
        class="color-filter"
        :src="
          $route.name == 'search'
            ? require('@/assets/bottomNavigation/search_active.svg')
            : require('@/assets/bottomNavigation/search.svg')
        "
      ></v-img>
    </v-btn>
    <v-btn
      style="min-width: 20%"
      v-if="!isMiKY"
      active-class="primary--text"
      @click="goToMyPoco($event)"
    >
      <span class="Text-Dark-Text-5---10-Center">{{
        $t("navigation.myPoco")
      }}</span>
      <v-img
        class="color-filter"
        :src="
          $route.name == 'mypoco'
            ? require('@/assets/bottomNavigation/mypoco_active.svg')
            : require('@/assets/bottomNavigation/mypoco.svg')
        "
      />
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
// import firebase from "firebase/app";
// import "firebase/firestore";
export default {
  name: "BottomNavigationButtons",
  data() {
    return {
      botLoading: false,
      bottomNav: "Navigation",
      loading: false,
    };
  },
  props: ["model"],
  methods: {
    // async goToBetsnaps() {
    //   if (!this.$store.state.session.user) {
    //     this.$router.safePush({ name: "login" });
    //   } else {
    //     await firebase
    //       .firestore()
    //       .collection("fl_redirects")
    //       .doc("ExlxXOzWUKFPjQsjW7lX")
    //       .set({ userId: this.$store.state.profile.data.id });
    //     this.$store.commit("SET_CURRENT_WINDOW", 0);
    //   }
    // },
    goToMap(event) {
      this.$router.safePush({ name: "map" });
      this.$store.dispatch("map/nearbyMarker", {});
      event.preventDefault();
    },
    goToMyPoco(event) {
      if (!this.$store.state.session.user) {
        this.$router.safePush({ name: "login" });
      } else {
        this.$router.safePush({ name: "mypoco" });
      }
      event.preventDefault();
    },
    goToSearch(event) {
      this.$router.safePush({ name: "search" });
      event.preventDefault();
    },
  },
  computed: {
    isMiKY() {
      return this.$store.state.isMiKY;
    },
  },
};
</script>
