<template>
  <v-dialog
    width="315"
    persistent
    v-model="showIntroduction"
    overlay-color="backgroundoverlay"
    overlay-opacity="0.7"
  >
    <v-card rounded height="568">
      <v-img
        height="150"
        fixed
        :src="require('@/assets/welcome-question-of-the-day.png')"
      >
        <v-toolbar color="transparent" flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="showIntroduction = false" small color="#09212b"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar></v-img
      >

      <v-window
        v-model="currentWindow"
        style="margin-top: -56px"
        class="text-center"
      >
        <v-window-item :key="0">
          <v-avatar style="z-index: 4" size="98">
            <v-img :src="profileImage"></v-img>
            <v-btn
              class="ma-n2"
              @click="
                (dialogLabel = $t('profile.profileImage')), (imageDialog = true)
              "
              icon
              absolute
              bottom
              right
              :ripple="false"
            >
            </v-btn>
          </v-avatar>
          <v-dialog scrollable :persistent="isPersistent" v-model="imageDialog">
            <v-card text>
              <v-card-title style="word-break: break-word">{{
                $t("profile.uploadImage", { image: dialogLabel })
              }}</v-card-title>
              <v-card-text>
                <v-form
                  v-if="!showProgress"
                  ref="validFileRef"
                  v-model="validFile"
                >
                  <v-file-input
                    style="overflow-x: hidden"
                    :clearable="false"
                    :rules="pictureRule"
                    v-model="file"
                    accept="image/png, image/jpeg, image/bmp, image/jpg"
                    :placeholder="$t('profile.choosePicture')"
                    prepend-icon="mdi-camera"
                    show-size
                    :hint="$t('profile.supportedTypes')"
                    :persistent-hint="true"
                  ></v-file-input>
                </v-form>
                <v-row v-if="showProgress">
                  <v-col class="grow" align-self="center">
                    <v-progress-linear :value="uploadValue"></v-progress-linear>
                  </v-col>
                  <v-col class="shrink">{{ uploadValue.toFixed() }}%</v-col>
                </v-row>
              </v-card-text>
              <v-card-actions v-if="!showProgress" class="mb-0">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="cancel()">{{
                  $t("profile.back")
                }}</v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    validFile ? uploadImage() : $refs.validFileRef.validate()
                  "
                  >{{ $t("profile.ok") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <greeting :showCity="false"></greeting>
          <v-card-text class="Heading-Dark-H4-18-Center px-5">{{
            $t("introduction.mayAsk")
          }}</v-card-text>
          <v-card-actions class="mt-12">
            <v-spacer></v-spacer>
            <v-btn
              class="gradient text-uppercase Heading-White-H4-18-Center mr-5"
              large
              depressed
              dark
              @click="currentWindow++"
              >{{ $t("introduction.yes") }}</v-btn
            >
            <v-btn
              class="gradient text-uppercase Heading-White-H4-18-Center"
              large
              depressed
              dark
              @click="showIntroduction = false"
              >{{ $t("introduction.no") }}</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-window-item>
        <v-window-item :key="1">
          <v-card-text class="Heading-Dark-H4-18-Center">{{
            $t("introduction.chooseBirthdate")
          }}</v-card-text>
          <date-picker
            @date-changed="
              (date) => {
                this.profile.birthday = date;
              }
            "
          ></date-picker>
          <v-card-text class="Heading-Dark-H4-18-Left-Align mt-4">{{
            $t("introduction.gender")
          }}</v-card-text>
          <v-radio-group
            v-model="profile.gender"
            class="mt-0 px-3 genderselect"
            row
          >
            <v-radio
              class="mb-5"
              v-for="gender in genders"
              :key="gender"
              :label="gender"
            >
            </v-radio>
          </v-radio-group>
          <v-card-actions>
            <v-row justify="space-around">
              <v-btn
                height="48"
                min-width="150"
                class="gradient Heading-White-H4-18-Left-Align"
                dark
                depressed
                @click="currentWindow++"
                >{{ $t("introduction.done") }}</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-window-item>
        <v-window-item :key="2">
          <v-card-text class="Heading-Dark-H4-18-Center mb-5">{{
            $t("introduction.from")
          }}</v-card-text>
          <v-toolbar
            height="48"
            style="border-radius: 4px"
            class="d-inline-block select-on-map mx-4 mb-4"
          >
            <v-menu offset-y content-class="location-menu">
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  solo
                  flat
                  hide-details
                  v-model="city"
                  :placeholder="$t('introduction.city')"
                  v-on="on"
                  :menu-props="{ contentClass: 'introduction-menu' }"
                >
                </v-text-field>
              </template>
            </v-menu>
          </v-toolbar>
          <v-card-actions>
            <v-row justify="space-around">
              <v-btn
                height="48"
                min-width="150"
                class="gradient Heading-White-H4-18-Left-Align"
                depressed
                dark
                @click="currentWindow++"
                >{{ $t("introduction.done") }}</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-window-item>
        <v-window-item :key="3">
          <v-card-text class="Heading-Dark-H4-18-Center">{{
            $t("introduction.interestedIn")
          }}</v-card-text>
          <v-container class="pa-0">
            <v-item-group multiple v-model="profile.likes"
              ><v-container
                ><v-row dense>
                  <v-col
                    v-for="(item, index) in getCategories"
                    :key="'interest-' + index"
                  >
                    <v-item v-slot:default="{ active, toggle }">
                      <v-chip
                        :color="
                          active
                            ? item.color
                            : $store.state.settings.dark
                            ? 'grey darken-3'
                            : 'white'
                        "
                        :class="active ? 'white--text' : ''"
                        class="text-center Heading-Dark-H6-14-Center"
                        height="30"
                        @click="toggle"
                      >
                        {{ $t(`categories.${item.title}`) }}
                      </v-chip>
                    </v-item></v-col
                  ></v-row
                ></v-container
              ></v-item-group
            ></v-container
          >
          <v-card-actions>
            <v-row justify="space-around">
              <v-btn
                class="gradient Heading-White-H4-18-Left-Align"
                height="48"
                min-width="150"
                depressed
                dark
                @click="(showIntroduction = false), updateProfile()"
                >{{ $t("introduction.done") }}</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-window-item>
      </v-window>
      <v-app-bar
        flat
        bottom
        absolute
        color="transparent"
        v-if="currentWindow > 0"
      >
        <v-row justify="space-around">
          <v-item-group v-model="window" mandatory>
            <v-item
              v-for="n in 3"
              :key="`btn-${n}`"
              v-slot:default="{ active, toggle }"
            >
              <v-btn
                v-if="currentWindow > 0"
                small
                :input-value="active"
                :color="active ? 'primary' : ''"
                icon
                @click="toggle"
              >
                <v-icon>mdi-record</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-row>
      </v-app-bar>
    </v-card>
  </v-dialog>
</template>

<script>
import Greeting from "@/components/dashboard/Greeting";
import DatePicker from "@/components/app/DatePicker";
import firebase from "firebase/app";
import "firebase/storage";
import { GetSuggestions } from "@/utils/PlaceUtils";
export default {
  components: {
    Greeting,
    DatePicker,
  },
  data() {
    return {
      city: "",
      currentWindow: 0,
      validFile: false,
      showProgress: false,
      isPersistent: false,
      imageDialog: false,
      file: [],
      uploadValue: 0,
      dialogLabel: "Avatar",
      /* 'value.size > 0' query needed otherwise error would not disappear even after choosing a picture */
      pictureRule: [
        (value) =>
          !value || value.size > 0 || this.$t("profile.chooseUploadPicture"),
      ],
      genders: [
        this.$t("profile.genders.male"),
        this.$t("profile.genders.female"),
        this.$t("profile.genders.notSay"),
      ],
      locationSearchText: null,
      locationEntries: [],
    };
  },
  computed: {
    getCategories: function () {
      return this.$store.state.categories.categories.filter(
        (c) => c.showInPreferences === true
      );
    },
    window: {
      get() {
        return this.currentWindow - 1;
      },
      set(val) {
        this.currentWindow = val + 1;
      },
    },
    profile() {
      return this.$store.state.profile.data;
    },
    showIntroduction: {
      get() {
        return this.$store.state.session.showIntroduction;
      },
      set(val) {
        this.$store.commit("session/SET_SHOW_INTRODUCTION", false);
      },
    },
    profileImage: {
      get() {
        if (this.profile.profilePicture) {
          return this.profile.profilePicture;
        } else {
          /* Default profile picture */
          return require("@/assets/avatar.png");
        }
      },
      set(val) {
        this.$store.commit("profile/SET_PROFILE_PICTURE", val);
      },
    },
  },
  methods: {
    cancel() {
      this.showProgress = false;
      this.isPersistent = false;
      this.imageDialog = false;
      this.$refs.validFileRef.reset();
    },
    uploadImage() {
      this.showProgress = true;
      this.isPersistent = true;
      const storageRef = firebase
        .storage()
        .ref(`${this.profile.id}/${this.file.name}`)
        .put(this.file);
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            if (this.dialogLabel === this.$t("profile.profileImage")) {
              this.profileImage = url;
            } else {
              this.backgroundImage = url;
            }
            /* reset image upload dialog after finishing upload */
            this.showProgress = false;
            this.isPersistent = false;
            this.imageDialog = false;
            this.uploadValue = 0;
            this.file = [];
            this.$nextTick(() => {
              this.updateProfile();
            });
          });
        }
      );
    },

    updateProfile() {
      let likes = this.profile.likes.map((like) => {
        let category = this.$store.state.categories.categories[like];
        return category ? category : void 0;
      });
      this.$store.dispatch("profile/updateProfile", {
        profile: {
          ...this.profile,
          likes,
          city: this.city,
          introduced: true,
        },
      });
    },
  },
};
</script>
<style>
.v-item-group .v-btn i.mdi-record {
  font-size: 18px;
}
.v-item-group .v-item--active::before {
  opacity: 0 !important;
}
.genderselect .v-input--radio-group__input {
  justify-content: space-between;
}
.v-chip {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.v-chip .v-chip__content {
  margin-top: 2px;
}
.v-menu__content.v-autocomplete__content.introduction-menu
  .v-list.v-select-list {
  display: block !important;
}
</style>
