<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12" class="px-0 py-0">
        <v-card class="transparent" elevation="0">
          <v-card-title
            class="d-block Heading-Dark-H4-18-No-Align"
            style="word-break: break-word"
            :style="isMiKY ? 'color: var(--v-onprimary-base) !important;' : ''"
          >
            <template v-if="$store.state.profile.data && (alias || firstName)">
              {{ greeting }} {{ alias || firstName }}!
              <template v-if="city && showCity">
                <span
                  class="d-inline-block Text-Dark-Text-3---14-Left-Align"
                  :style="
                    isMiKY ? 'color: var(--v-onprimary-base) !important;' : ''
                  "
                >
                  {{ $t("greeting.welcomeTo") }} {{ city }}.
                </span>
              </template>
            </template>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    openDropdown: false,
  }),
  computed: {
    ...mapState({
      firstName: (state) => state.profile.data.firstName,
      alias: (state) => state.profile.data.alias,
      city: (state) => state.session.currentCity,
      lastLogin: (state) => state.session.lastLogin,
    }),
    greeting() {
      const sinceLastTime = moment().diff(this.lastLogin, "days");
      const hours = moment().format("HH");

      if (sinceLastTime >= 5) {
        return this.$t("greeting.welcomeBack");
      }
      if (hours < 12 && hours > 5) {
        return this.$t("greeting.morning");
      } else if (hours > 12 && hours < 18) {
        return this.$t("greeting.afternoon");
      } else if (hours > 18 && hours < 24) {
        return this.$t("greeting.evening");
      } else if (hours < 5) {
        return this.$t("greeting.night");
      }

      return this.$t("greeting.hello");
    },
    isMiKY() {
      return this.$store.state.isMiKY;
    },
  },

  /*  methods: {
    toggle() {
      this.openDropdown = !this.openDropdown;
    },
    setLocation(city) {
      this.$emit("set-location", city);
    }
  }, */
  props: {
    showCity: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
