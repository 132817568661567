import api from "@/api";
import find from "lodash/find";

export default {
  async getFavorites(
    { commit, rootState },
    {
      userId = rootState.profile.data.id,
      lang = rootState.languages.currentLang.id,
    }
  ) {
    commit("FETCH_FAVORITES");
    try {
      commit("SUCCESS_FETCH_FAVORITES", await api.getFavorites(userId, lang));
    } catch (e) {
      commit("FAILURE_FETCH_FAVORITES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  toggleFavorite({ commit, dispatch, rootState }, favorite) {
    if (
      !find(rootState.profile.data.favorites, (fav) => {
        return fav === favorite || fav.ticketid === favorite.ticketid;
      })
    ) {
      if (!rootState.profile.data.favorites) {
        rootState.profile.data.favorites = [];
      }
      rootState.profile.data.favorites.push(favorite);
    } else {
      commit("TOGGLE_FAVORITE", favorite);
      rootState.profile.data.favorites.splice(
        rootState.profile.data.favorites.indexOf(favorite),
        1
      );
    }
    dispatch(
      "profile/updateProfile",
      {
        profile: rootState.profile.data,
      },
      { root: true }
    );
  },
};
