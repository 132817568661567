export default {
  SET_DARK: (state, value) => {
    state.dark = value === "true" || value === true;
    window.localStorage?.setItem("dark", value === "true" || value === true);
  },
  SET_HOTDEAL: (state, value) => {
    state.hotdeal = value === "true" || value === true;
    window.localStorage?.setItem("hotdeal", value === "true" || value === true);
  },
  SET_INCOGNITO: (state, value) => {
    state.incognito = value === "true" || value === true;
    window.localStorage?.setItem(
      "incognito",
      value === "true" || value === true
    );
  },
  SET_NOTIFICATIONS: (state, value) => {
    state.notifications = value === "true" || value === true;
    window.localStorage?.setItem(
      "notifications",
      value === "true" || value === true
    );
  },
};
