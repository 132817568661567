export default {
  SET_CAROUSEL: (state, value) => {
    state.carousel = value;
  },
  SET_CENTER(state, value) {
    if (value) {
      state.center = value;
    } else {
      state.center = state.userPosition;
    }
  },
  SET_MARKER(state, value) {
    state.marker = value;
  },
  SET_ORIGINAL_POSITION: (state, value) => {
    state.originalPosition = value;
  },
  SET_USER_POSITION(state, value) {
    state.userPosition = value;
  },
  FETCH_MARKER(state) {
    state.status = "fetching";
  },
  FAILURE_FETCH_MARKER(state) {
    state.status = "failure";
  },
  SUCCESS_FETCH_MARKER(state, value) {
    state.status = "success";
    state.marker = value;
  },
  SET_ZOOM(state, value) {
    state.zoom = value;
  },
};
