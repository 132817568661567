export default () => ({
  carousel: 0,
  center: void 0,
  //startpoint is vienna, if no location data given be there
  userPosition: {},
  marker: [],
  zoom: 14,
  status: "",
  originalPosition: {
    city: "",
    lat: void 0,
    lng: void 0,
  },
});
