import api from "@/api";

export default {
  async getCategories({ commit }) {
    commit("FETCH_CATEGORIES");
    try {
      commit("SUCCESS_FETCH_CATEGORIES", await api.getCategories());
    } catch (error) {
      commit("FAILURE_FETCH_CATEGORIES");
    }
  },
};
