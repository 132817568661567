import Vue from "vue";
import find from "lodash/find";

export default {
  FETCH_LANGUAGES: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_LANGUAGES: (state, value) => {
    state.status = "success";
    state.items = value;
  },
  FAILURE_FETCH_LANGUAGES: (state) => {
    state.status = "failure";
  },
  SET_CURRENT_LANG: (state, value) => {
    let language;
    if (state.items && state.items.length) {
      language = find(state.items, { id: value });
      if (!language) {
        language = find(state.items, { id: "en-US" });
        if (!language) {
          language = find(state.items, { id: "de" });
        }
      }
    } else {
      //in case we do not have any languages loaded, we go with english
      language = { name: "English", id: "en-US" };
    }
    state.currentLang = language;
    window.localStorage?.setItem("lang-id", language.id);
    Vue.i18n.set(language.id);
  },
};
