import api from "@/api";
import each from "lodash/each";
import find from "lodash/find";
import moment from "moment";
import map from "lodash/map";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import intersectionWith from "lodash/intersectionWith";
import findLast from "lodash/findLast";

const debug = window.location.hostname === "localhost";

export default {
  // filter functions
  async applyCategoryFilters({ rootGetters }, { items }) {
    return filter(items, (o) => {
      return find(rootGetters["categories/filteredCategories"], (category) => {
        return category.id === o.category?.id;
      });
    });
  },
  async applyPartnerFilters({ rootState }, { items }) {
    const filters = rootState.partners?.config?.filter_settings?.categories;
    for (let i = 0; i < filters?.length; i++) {
      let category = filters[i];
      items = filter(items, (item) => {
        // This category filter does not apply to this item, keep it.
        if (item.category.id !== category.id) {
          return true;
        }
        // Keep the item if it is part of override exclusion.
        if (
          find(category.recommendations, (recommendation) => {
            return recommendation === item.id;
          })
        ) {
          return true;
        }

        if (category.selected_all_categories) {
          return false;
        }

        // Otherwise remove the item if any of it's subcategories are to be excluded.
        let intersection = intersectionWith(
          item.subCategory,
          category.sub_categories,
          (itemSubCategory, filterSubCategory) => {
            return (
              itemSubCategory === filterSubCategory ||
              itemSubCategory.title === filterSubCategory ||
              itemSubCategory.id === filterSubCategory
            );
          }
        );
        return !intersection || intersection?.length === 0;
      });
    }

    return items;
  },

  // v4
  async getByCategoryV4(
    { state, rootState, dispatch, commit },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      radius = state.radius,
      city = rootState.session.currentCity,
      lang = rootState.languages.currentLang.id,
      category,
    }
  ) {
    debug && console.time("getByCategoryV4 total");
    commit("FETCH_BY_CATEGORY_V4");

    try {
      debug && console.time("getByCategoryV4 api");
      let items = await api.getByCategoryV4(
        lat,
        lng,
        radius,
        city,
        lang.toLowerCase(),
        category
      );
      debug && console.timeEnd("getByCategoryV4 api");

      if (rootState.isMiKY) {
        items = await dispatch("applyPartnerFilters", { items });
      }
      items = orderBy(items, ["distance"], ["asc"]);
      debug && console.time("calculating opening times");
      items.forEach((item) => {
        item.openingTimes = api.getOpeningHours(item);
      });
      debug && console.timeEnd("calculating opening times");

      debug &&
        console.log(
          `recommendations/getByCategoryV4 - Received ${items.length} items`
        );
      commit("SUCCESS_FETCH_BY_CATEGORY_V4", items);
    } catch (error) {
      debug &&
        console.error(
          `recommendations/getByCategoryV4 - Failed to get items. ${error}`
        );
      commit("FAILURE_FETCH_BY_CATEGORY_V4");
    }
    debug && console.timeEnd("getByCategoryV4 total");
  },

  async getDashboardRecommendationsV4(
    { rootState, dispatch, commit },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      city = rootState.session.currentCity,
      lang = rootState.languages.currentLang.id,
    }
  ) {
    debug && console.time("getDashboardRecommendationsV4 total");
    commit("FETCH_DASHBOARD_RECOMMENDATIONS_V4");

    try {
      debug && console.time("getDashboardRecommendationsV4 api");
      let items = await api.getDashboardRecommendationsV4(
        lat,
        lng,
        city,
        lang.toLowerCase()
      );
      debug && console.timeEnd("getDashboardRecommendationsV4 api");

      if (rootState.isMiKY) {
        items = await dispatch("applyPartnerFilters", { items });
      }
      items = orderBy(items, ["distance"], ["asc"]);
      debug &&
        console.time(
          "recommendations/getDashboardRecommendationsV4 - calculating opening times"
        );
      items.forEach((item) => {
        item.openingTimes = api.getOpeningHours(item);
      });
      debug &&
        console.timeEnd(
          "recommendations/getDashboardRecommendationsV4 - calculating opening times"
        );

      debug &&
        console.log(
          `recommendations/getDashboardRecommendationsV4 - Received ${items.length} items`
        );
      commit("SUCCESS_FETCH_DASHBOARD_RECOMMENDATIONS_V4", items);
    } catch (error) {
      debug &&
        console.error(
          `recommendations/getDashboardRecommendationsV4 - Failed to get items. ${error}`
        );
      commit("FAILURE_FETCH_DASHBOARD_RECOMMENDATIONS_V4");
    }
    debug && console.timeEnd("getDashboardRecommendationsV4 total");
  },

  async getByTagV4(
    { state, rootState, dispatch, commit },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      city = rootState.session.currentCity,
      lang = rootState.languages.currentLang.id,
      tags,
    }
  ) {
    debug && console.time("getByTagV4 total");
    commit("FETCH_BY_TAG_V4", { tags });
    try {
      debug && console.time("getByTagV4 api");
      let items = await api.getByTagV4(lat, lng, city, lang, tags.join());
      debug && console.timeEnd("getByTagV4 api");

      if (rootState.isMiKY) {
        items = await dispatch("applyCategoryFilters", { items });
        items = await dispatch("applyPartnerFilters", { items });
      }
      items = orderBy(items, ["distance"], ["asc"]);
      debug && console.time("calculating opening times");
      items.forEach((item) => {
        item.openingTimes = api.getOpeningHours(item);
      });
      debug && console.timeEnd("calculating opening times");
      commit("SUCCESS_FETCH_BY_TAG_V4", { tags, items });
    } catch (error) {
      debug && console.error(error);
      commit("FAILURE_FETCH_BY_TAG_V4", { tags });
    }
    debug && console.timeEnd("getByTagV4 total");
  },

  // v1-v3

  async getRecommendationsV2(
    { commit, state, rootState, rootGetters, dispatch },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      userId = rootState.profile.data.id,
      all = false,
      type = "recommendation",
      filteredCategories = map(
        rootGetters["categories/filteredCategories"],
        "_fl_meta_.fl_id"
      ),
      last_item = null,
    }
  ) {
    commit("FETCH_RECOMMENDATIONS_V2");
    try {
      let items = await api.getRecommendationsV2(
        lat,
        lng,
        lang,
        radius,
        type,
        all,
        userId,
        filteredCategories,
        last_item
      );
      items = filter(items, (item) => {
        return item.distance <= radius * 1000;
      });
      items = orderBy(items, ["distance"], ["asc"]);
      items = uniqBy(items, "id");
      if (rootState.isMiKY) {
        items = await dispatch("applyPartnerFilters", { items });
      }
      // This will set the filteredRecommendations
      commit("SUCCESS_FETCH_RECOMMENDATIONS_V2", items);
      dispatch("locations/getLocations", { all: true }, { root: true });
    } catch (error) {
      commit("FAILURE_FETCH_RECOMMENDATIONS_V2");
    }
  },

  //   S e a r c h r e s u l t s
  async getSearchResults(
    { state, rootState, dispatch, commit },
    {
      city = rootState.session.currentCity,
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      search = undefined,
    }
  ) {
    let timestamp = new Date().getTime();
    commit("FETCH_SEARCH_RESULTS", timestamp);
    try {
      let items = await api.searchV2(
        city,
        lat,
        lng,
        lang,
        undefined, // lastId
        rootState.isMiKY ? 100 : undefined, // requestedItemCount (default = 50)
        search ? search.join() : undefined
      );
      if (rootState.isMiKY) {
        items = await dispatch("applyCategoryFilters", { items });
        items = await dispatch("applyPartnerFilters", { items });
      }
      items = orderBy(items, ["distance"], ["asc"]);
      if (state.searchTimestamp !== timestamp) {
        // A new search has been started before this search finished.
        // Disregarding the search results.
        return;
      }
      commit("SUCCESS_FETCH_SEARCH_RESULTS", items);
    } catch (error) {
      console.error(error);
      commit("FAILURE_FETCH_SEARCH_RESULTS");
    }
  },
  async getMoreSearchResults(
    { state, rootState, dispatch, commit },
    {
      city = rootState.session.currentCity,
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      search = undefined,
    }
  ) {
    // Get the last item
    let lastId =
      state.searchResults?.length > 0
        ? state.searchResults[state.searchResults.length - 1].id
        : undefined;
    let items = [];
    commit("FETCH_MORE_SEARCH_RESULTS");
    try {
      let fetchedAll = false;
      do {
        items = await api.searchV2(
          city,
          lat,
          lng,
          lang,
          lastId, // lastId
          rootState.isMiKY ? 100 : undefined, // requestedItemCount (default = 50)
          search ? search.join() : undefined
        );
        // The partner filter might filter out all new items.
        // If the user tries to load more he will always load the same items
        // and they will all be removed again.
        // To ensure that there are new items loaded, the last item is saved
        // and if there are no items left after the filters this "getMoreSearchResults"
        // call is repeated with the new last item in the parameters.
        // This loop stops once the database has no more items.
        if (items.length > 0) {
          lastId = items[items.length - 1].id;
          if (rootState.isMiKY) {
            items = await dispatch("applyCategoryFilters", { items });
            items = await dispatch("applyPartnerFilters", { items });
          }
          items = orderBy(items, ["distance"], ["asc"]);
        } else {
          fetchedAll = true;
        }
      } while (items.length == 0 && !fetchedAll);
      if (!fetchedAll) {
        commit("SUCCESS_FETCH_MORE_SEARCH_RESULTS", items);
      } else {
        commit("FAILURE_FETCH_MORE_SEARCH_RESULTS");
      }
    } catch (error) {
      console.error(error);
      commit("FAILURE_FETCH_MORE_SEARCH_RESULTS");
    }
  },

  // -------- ACTIONS ---------

  async getRecommendations(
    { commit, dispatch, state, rootState },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
    }
  ) {
    commit("REQUEST_RECOMMENDATIONS");
    try {
      commit("SET_CAPACITY_REACHED", false);
      commit(
        "SUCCESS_REQUEST_RECOMMENDATIONS",
        await api.getRecommendations(lat, lng, lang, radius)
      );
      dispatch("setCategoryList");
    } catch (e) {
      commit("FAILURE_REQUEST_RECOMMENDATIONS");
    }
  },
  setCategoryList({ commit, state }) {
    let categories = [];
    each(state.items, (recommendation) => {
      if (!find(categories, { name: recommendation.category.name })) {
        categories.push({
          selected: false,
          name: recommendation.category.name,
        });
      }
    });
    commit("SET_CATEGORY_LIST", categories);
  },
  async getDetails(
    { commit, rootState, dispatch },
    { entryId, lang = rootState.languages.currentLang.id }
  ) {
    commit("FETCH_DETAILS");
    try {
      let details = await api.getDetails(entryId, lang, "recommendations");
      commit("SUCCESS_FETCH_DETAILS", details);
      dispatch("updateClicked", {
        id: details.id,
        clicked: details.clicked + 1,
      });
    } catch (error) {
      commit("FAILURE_FETCH_DETAILS");
    }
  },
  async getTickets(
    { commit, rootState, dispatch },
    { entryId, lang = rootState.languages.currentLang.id }
  ) {
    commit("FETCH_TICKETS");
    try {
      let tickets = await api.getTickets(entryId, lang);
      commit("SUCCESS_FETCH_TICKETS", tickets);
    } catch (error) {
      commit("FAILURE_FETCH_TICKETS");
    }
  },
  async getAllRecommendations(
    { commit, dispatch, state, rootState, rootGetters },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      type,
      userId = rootState.profile.data.id,
      all = true,
      filteredCategories = map(
        rootGetters["categories/filteredCategories"],
        "_fl_meta_.fl_id"
      ),
    }
  ) {
    const last_item = state.allItems[state.allItems.length - 1];

    commit("FETCH_ALL");
    try {
      let recommendations = await api.getAllItems(
        lat,
        lng,
        lang,
        radius,
        type,
        all,
        userId,
        filteredCategories,
        last_item
      );

      recommendations = filter(recommendations, (item) => {
        return item.distance <= radius * 1000;
      });

      switch (type) {
        case "recommendation":
          recommendations = orderBy(recommendations, ["distance"], ["asc"]);
          break;
        case "popular":
          recommendations = orderBy(
            filter(recommendations, (o) => {
              return o.clicked > 0;
            }),
            ["clicked"],
            ["desc"]
          );
          break;
        case "justForYou":
          recommendations = orderBy(recommendations, ["distance"], ["asc"]);
          break;
        case "highlights":
          recommendations = orderBy(
            filter(recommendations, { highlight: true }),
            ["distance"],
            ["asc"]
          );
          break;
      }
      if (rootState.isMiKY) {
        recommendations = await dispatch("applyPartnerFilters", {
          items: recommendations,
        });
      }
      commit("SUCCESS_FETCH_ALL", recommendations);
      dispatch("setCategoryList");
    } catch (e) {
      commit("FAILURE_FETCH_ALL");
    }
  },
  async updateClicked({ commit }, { id, clicked }) {
    commit("UPDATE_DETAILS");
    try {
      commit(
        "SUCCESS_UPDATE_DETAILS",
        await api.updateClicked(id, clicked, "recommendations")
      );
    } catch (error) {
      console.error(error);
      commit("FAILURE_UPDATE_DETAILS");
    }
  },
  async logView({ commit, rootState }, { recommendationId }) {
    try {
      const userId = rootState.profile.data.id || null;
      const userName = userId
        ? rootState.profile.data.alias ||
          rootState.profile.data.firstName +
            " " +
            rootState.profile.data.lastName
        : null;
      const partnerId = window.sessionStorage?.getItem("partnerId");

      commit(
        "SUCCESS_LOG_VIEW",
        await api.logView(recommendationId, userId, userName, partnerId)
      );
    } catch (error) {
      console.error(error);
      commit("FAILURE_LOG_VIEW");
    }
  },
  async logCategoryView({ commit, rootState }, { categoryId, categoryName }) {
    try {
      const userId = rootState.profile.data.id || null;
      const userName = userId
        ? rootState.profile.data.alias ||
          rootState.profile.data.firstName +
            " " +
            rootState.profile.data.lastName
        : null;
      const partnerId = window.sessionStorage?.getItem("partnerId");

      commit(
        "SUCCESS_LOG_VIEW",
        await api.logCategoryView(
          categoryId,
          categoryName,
          userId,
          userName,
          partnerId
        )
      );
    } catch (error) {
      console.error(error);
      commit("FAILURE_LOG_VIEW");
    }
  },
  async logPartnerView({ commit, rootState }, {}) {
    try {
      const userId = rootState.profile.data.id || null;
      const userName = userId
        ? rootState.profile.data.alias ||
          rootState.profile.data.firstName +
            " " +
            rootState.profile.data.lastName
        : null;
      const partnerId = window.sessionStorage?.getItem("partnerId");
      commit(
        "SUCCESS_LOG_PARTNER_VIEW",
        await api.logPartnerView(userId, userName, partnerId)
      );
    } catch (error) {
      console.error(error);
      commit("FAILURE_LOG_PARTNER_VIEW");
    }
  },
  async logReservation(
    { commit, rootState },
    {
      recommendationId,
      recommendationName,
      placeId,
      placeName,
      persons,
      bookingRef,
    }
  ) {
    try {
      if (
        rootState.profile.data.id == undefined ||
        rootState.profile.data.partnerIdExpiry?._seconds == undefined ||
        new Date(rootState.profile.data.partnerIdExpiry?._seconds * 1000) <
          new Date()
      ) {
        return;
      }

      const userId = rootState.profile.data.id;
      const userName =
        rootState.profile.data.alias ||
        rootState.profile.data.firstName +
          " " +
          rootState.profile.data.lastName;
      const partnerId = rootState.profile.data.partnerId;

      commit(
        "SUCCESS_LOG_RESERVATION",
        await api.logReservation(
          userId,
          userName,
          partnerId,
          recommendationId,
          recommendationName,
          placeId,
          placeName,
          persons,
          bookingRef
        )
      );
    } catch (error) {
      console.error(error);
      commit("FAILURE_LOG_RESERVATION");
    }
  },
  async getByCategory(
    { commit, rootState, state, dispatch },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      all = true,
      category = "",
    }
  ) {
    if (lat == undefined && lng == undefined) {
      return;
    }
    commit("FETCH_BYCATEGORY");
    try {
      let items = await api.getByCategory(
        lat,
        lng,
        lang,
        radius,
        all,
        category
      );
      if (rootState.isMiKY) {
        items = await dispatch("applyPartnerFilters", { items });
      }
      commit("SUCCESS_FETCH_BYCATEGORY", orderBy(items, ["distance"], ["asc"]));
    } catch (error) {
      console.error(error);
      commit("FAILURE_FETCH_BYCATEGORY");
    }
  },
  async getMoreByCategory(
    { commit, rootState, state, dispatch },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      all = true,
      category = "",
      last_item = null,
    }
  ) {
    commit("FETCH_MORE_BYCATEGORY");
    if (state.byCategory && !last_item) {
      let byCategory = orderBy(state.byCategory, ["distance"], ["asc"]);
      last_item = {
        searchRadius: byCategory[byCategory.length - 1].searchRadius,
        distance: byCategory[byCategory.length - 1].distance,
        id: byCategory[byCategory.length - 1].id,
        paginate: byCategory[byCategory.length - 1].paginate,
      };
    }

    try {
      let items = await api.getByCategory(
        lat,
        lng,
        lang,
        radius,
        all,
        category,
        last_item
      );
      if (rootState.isMiKY) {
        last_item = {
          searchRadius: items[items.length - 1].searchRadius,
          distance: items[items.length - 1].distance,
          id: items[items.length - 1].id,
          paginate: items[items.length - 1].paginate,
        };
        items = await dispatch("applyPartnerFilters", { items });
        if (items.length === 0) {
          dispatch("getMoreByCategory", {
            lat,
            lng,
            lang,
            radius,
            all,
            category,
            last_item,
          });
          return;
        }
      }
      commit(
        "SUCCESS_FETCH_MORE_BYCATEGORY",
        orderBy(items, ["distance"], ["asc"])
      );
    } catch (error) {
      console.error(error);
      commit("FAILURE_FETCH_MORE_BYCATEGORY");
    }
  },
  async getByMood(
    { commit, rootState, state },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      all = true,
      mood = "",
    }
  ) {
    commit("FETCH_BYMOOD");
    try {
      commit(
        "SUCCESS_FETCH_BYMOOD",
        await api.getByMood(lat, lng, lang, radius, all, mood)
      );
    } catch (error) {
      commit("FAILURE_FETCH_BYMOOD");
    }
  },
  async getMoreByMood(
    { commit, rootState, state },
    {
      lat = rootState.map.userPosition.lat,
      lng = rootState.map.userPosition.lng,
      lang = rootState.languages.currentLang.id,
      radius = state.radius,
      all = true,
      mood = "",
    }
  ) {
    commit("FETCH_MORE_BYMOOD");
    let last_item = null;
    if (state.byMood) {
      last_item = {
        searchRadius: state.byMood[state.byMood.length - 1].searchRadius,
        distance: state.byMood[state.byMood.length - 1].distance,
        id: state.byMood[state.byMood.length - 1].id,
        paginate: state.byMood[state.byMood.length - 1].paginate,
      };
    }

    try {
      let items = await api.getByMood(
        lat,
        lng,
        lang,
        radius,
        all,
        mood,
        last_item
      );
      commit(
        "SUCCESS_FETCH_MORE_BYMOOD",
        orderBy(items, ["distance"], ["asc"])
      );
    } catch (error) {
      console.error(error);
      commit("FAILURE_FETCH_MORE_BYMOOD");
    }
  },
  async addUserRating(
    { commit, dispatch, state, rootState },
    { id = state.details.id, rating, comment }
  ) {
    commit("FETCH_REVIEW");
    const newRating = {
      rating,
      comment,
      alias:
        rootState.profile.data.alias ||
        rootState.profile.data.firstName +
          " " +
          rootState.profile.data.lastName,
      img: rootState.profile.data.profilePicture || "",
      postDate: moment(new Date(), "DD-MM-YYYY").format("DD-MM-YYYY"),
    };
    try {
      await api.addUserRating(id, newRating, "recommendations");
      commit("SUCCESS_FETCH_REVIEW");
      await dispatch("getDetails", { entryId: id });
    } catch (error) {
      commit("FAILURE_FETCH_REVIEW");
    }
  },
  async textSearch(
    { commit, rootState },
    { lang = rootState.languages.currentLang.id, searchTerm = "" }
  ) {
    commit("TEXT_SEARCH");
    try {
      let results = await api.textSearch(lang, searchTerm);
      commit("SUCCESS_TEXT_SEARCH", results);
      commit("search/SET_SEARCH_RESULTS", results, { root: true });
    } catch (error) {
      commit("FAILURE_TEXT_SEARCH");
    }
  },
};
