export default {
  FETCH_AVAILABILITY: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_AVAILABILITY: (state, value) => {
    state.status = "success";
    state.available = value;
  },
  FAILURE_FETCH_AVAILABILITY: (state, value) => {
    state.status = "failure";
    state.error = value;
  },
  REMOVE_AVAILABILITY: (state) => {
    state.status = "";
    state.available = undefined;
  },
  CANCEL: (state) => {
    state.status = "fetching";
  },
  SUCCESS_CANCEL: (state) => {
    state.status = "success";
  },
  FAILURE_CANCEL: (state, value) => {
    state.status = "failure";
    state.error = value;
  },
  RESERVE: (state) => {
    state.status = "fetching";
  },
  SUCCESS_RESERVE: (state, value) => {
    state.status = "success";
    state.reserved = value;
  },
  FAILURE_RESERVE: (state, value) => {
    if (value === "Short guest name [shorter than 4 characters]") {
      value = "Your name should be more than 4 characters";
    }
    state.status = "failure";
    state.error = value ?? "Reservation error. Please try later.";
  },
  SHOW_BOOKING_CONFIRMATION: (state, value) => {
    state.bookingConfirmation = value;
  },
  SET_PARAMS: (state, value) => {
    state.date = value.date;
    state.covers = value.covers;
    state.time = value.time;
    state.placeId = value.placeId;
    state.areaId = value.areaId || undefined;
    state.header = value.header;
    state.countryId = value.countryId;
    state.promotionId = value.promotionId;
    state.visitId = value.visitId;
  },
  RESET_ERROR: (state) => {
    state.status = "";
    state.error = undefined;
  },
  FETCH_PROMOTIONS: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_PROMOTIONS: (state, value) => {
    state.status = "success";
    state.promotions = value;
  },
  FAILURE_FETCH_PROMOTIONS: (state, value) => {
    state.status = "failure";
    state.error = value;
  },
  REMOVE_PROMOTIONS: (state) => {
    state.promotions = [];
  },
  FETCH_MENU: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_MENU: (state, value) => {
    state.status = "success";
    state.menu = value;
  },
  FAILURE_FETCH_MENU: (state, value) => {
    state.status = "failure";
    state.error = value;
  },
  REMOVE_MENU: (state) => {
    state.menu = [];
  },
  FETCH_RESTAURANT_INFO: (state) => {
    state.status = "fetching";
  },
  SUCCESS_FETCH_RESTAURANT_INFO: (state, value) => {
    state.status = "success";
    state.restaurant = value;
  },
  FAILURE_FETCH_RESTAURANT_INFO: (state, value) => {
    state.status = "failure";
    state.error = value;
  },
};
