export default () => ({
  status: "",
  available: undefined,
  reserved: undefined,
  bookingConfirmation: false,
  placeId: undefined,
  time: undefined,
  covers: undefined,
  date: undefined,
  header: undefined,
  countryId: undefined,
  promotions: undefined,
  menu: undefined,
  restaurant: undefined,
});
